import { Modal } from "react-bootstrap";
import axios from "axios";
import { deleteCarrierUrl} from "../library/URLs";
import {useState} from "react";
import {UserProfileData} from "../library/constants";
import styled from "styled-components";
import {BiX} from "react-icons/bi";
import { BsTrash, BsX } from "react-icons/bs";
import { toast } from "react-toastify";

const SubmitButton = styled.button`
`;

const DeleteCarrierDialog = ({ isModalVisible, handleCloseDialog, carrierID }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const deleteCarrier = (e) => {
        const formValues = {};
        formValues['carrier_id'] = `${carrierID}`;

        const newData = { ...formValues, ...UserProfileData() };

        setIsSubmitting(true);

        console.log(newData);

        axios
            .post(deleteCarrierUrl, newData)
            .then(function (response) {
                console.log(response);
                setIsSubmitting(false);
                if (response["data"]["success"] === true) {
                    toast.success(response["data"]["data"]);

                    setTimeout(() => {
                        handleCloseDialog();
                        window.location.reload();
                    }, 3000);
                } else {
                    toast.error(response["data"]["message"])
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">DELETE CARRIER</h5>
          <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
      </Modal.Header>
      <Modal.Body>

          <p>You are about to delete this carrier</p>
          <p>This means you won't be able to use this carrier for any activity.</p>
          <p>Would you like to continue?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary btn-error mx-3" onClick={(e) => handleCloseDialog(false)}>
          <BsX /> Cancel
        </button>
          <SubmitButton onClick={deleteCarrier} type="submit" className="confirm-button btn-primary">
              {isSubmitting === false ? (
                  <span className="button-text d-flex align-items-center gap-2"><BsTrash /> Delete Carrier</span>
              ) : (
                  <span className="spinner-border spinner-grow-sm"/>
              )}
          </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteCarrierDialog;
