import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getStationEmployeesUrl,
  getStationShipmentsUrl,
} from "../../library/URLs";
import { UserProfileData } from "../../library/constants";
import AdminIcon from "../../assets/admins/group.svg";
import styled from "styled-components";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { SessionManagement } from "../../library/SessionManagement";
import SuperAdminIcon from "../../assets/super-admin.png";
import AdminTypeIcon from "../../assets/admin.png";
import Tooltip from "@mui/material/Tooltip";

const LoadingContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const EmptyContainer = styled.div`
  height: 250px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  cursor: pointer;
`;

const StationStaffs = () => {
  const params = useParams();
  const stationID = params["stationID"];
  const navigate = useNavigate();

  const [staffs, setStaffs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const data = {
    processing_station: stationID,
  };

  const goToSingleAdmin = (adminID) => {
    navigate("/admin/" + adminID);
  };

  const newData = { ...data, ...UserProfileData() };

  const fetchEmployees = () => {
    setIsLoading(true);
    axios
      .post(getStationEmployeesUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);
        if (response["data"]["success"] === false) return;

        setStaffs(response["data"]["data"]);
      });
  };

  useEffect(() => {
    fetchEmployees();
    // eslint-disable-next-line
  }, []);

  const shortenTexts = (text) => {
    if (text) {
      if (text?.length > 16) {
        return `${text?.slice(0, 16)}...`;
      } else {
        return text;
      }
    }
  };

  return (
    <div className="card bg-light border-0">
      <div className="card-body">
        <div className="d-flex flex-row justify-content-between">
          <div className="tab-header-container">
            <img src={AdminIcon} alt="shipments" />
            Staffs
          </div>
          <div></div>
        </div>
        {staffs.length > 0 && !isLoading ? null : (
          <EmptyContainer>
            <p>Oops... Sorry, station has no recorded staff</p>
          </EmptyContainer>
        )}
        {staffs.length > 0 && !isLoading && (
          <div className="shipments-table-container">
            <div
              className=""
              style={{ width: "100%", overflowX: "auto", overflowY: "hidden" }}
            >
              <table className="shipments-table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email Address</th>
                    <th scope="col">Type</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {staffs.map((staff) => {
                    const status =
                      staff.status === "Active" ? "Delivered" : "Cancelled";

                    const statusClass = status.toString().replaceAll(" ", "");

                    return (
                      <tr
                        style={{ fontSize: "14.4px" }}
                        key={staff.id}
                        onClick={() => goToSingleAdmin(staff.id)}
                      >
                        <td>
                          <span className="d-flex flex-nowrap gap-2 align-items-center px-3">
                            <ProfileImage
                              onClick={(e) => goToSingleAdmin(staff.id)}
                              src={staff.profile_image_url}
                            />
                            <Tooltip title={`${staff.name}`} placement="top">
                              <span>{shortenTexts(staff.name)}</span>
                            </Tooltip>
                          </span>
                        </td>
                        <td>
                          <Tooltip title={`${staff.email}`} placement="top">
                            <span>{shortenTexts(staff.email)}</span>
                          </Tooltip>
                        </td>
                        <td>
                          <div
                            className={`admin-type 
                                                                      ${
                                                                        staff.employee_type ===
                                                                        "Admin"
                                                                          ? "admin-type-admin"
                                                                          : "admin-type-super-admin"
                                                                      }`}
                          >
                            {staff.employee_type === "Admin" ? (
                              <img src={AdminTypeIcon} alt="icon" />
                            ) : (
                              <img src={SuperAdminIcon} alt="icon" />
                            )}
                            {staff.employee_type}
                          </div>
                        </td>
                        <td>{staff.phone}</td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          <span className={`status ${statusClass}`}>
                            {status}
                          </span>{" "}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {isLoading === false ? null : (
          <LoadingContainer>
            <LoadingSpinner className="spinner-border spinner-grow-sm" />
          </LoadingContainer>
        )}
      </div>
    </div>
  );
};

export default StationStaffs;
