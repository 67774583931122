import { Col, Row } from "react-bootstrap";
import * as React from "react";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { SessionManagement } from "../../../library/SessionManagement";
import PriceSettingsDialog from "../../../modals/MotorbikePriceSettingsDialog";
import ChangePasswordModal from "../../../modals/ChangePasswordModal";
import { generateTransactionID } from "../../../library/utilities";
import { UserProfileData } from "../../../library/constants";
import axios from "axios";
import {
  createInternationalShipmentURL,
  updateProfileURL,
  changePasswordURL,
  uploadMediaUrl,
} from "../../../library/URLs";
import CompanyDetails from "./CompanyDetails";
import User from "../../../assets/settings/account_details/user.svg";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { BsCheck } from "react-icons/bs";
import {
  PasswordBox,
  PhoneFieldBox,
  TextBox,
} from "../../../components/InputFields";
import ProfileConfirmationDialog from "../../../modals/ProfileConfirmationDialog";
import PasswordConfirmationDialog from "../../../modals/PasswordConfirmationDialog";

const SubmitButton = styled.button``;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #fcd2d2;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #c2eddd;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
`;

const PersonalDetails = () => {
  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [editProfile, setEditProfile] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [uploadLoading, setUploadLoading] = useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);

  const [passwordForm, setPasswordForm] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    profile_image_url: "",
  });

  const handlePassword = (e) => {
    const { name, value } = e.target;

    setPasswordForm({
      ...passwordForm,
      [name]: value,
    });
  };

  const [changePasswordDialogVisibility, setChangePasswordDialogVisibility] =
    useState(false);

  const firstName = SessionManagement.getFirstName();
  const lastName = SessionManagement.getLastName();
  const phoneNumber = SessionManagement.getPhone();
  const emailAddress = SessionManagement.getEmail();
  const profileImageURL = SessionManagement.getProfileImageURL();

  const fileInputRef = useRef(null);

  useEffect(() => {
    setUserData({
      ...userData,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      email: emailAddress,
      profile_image_url: profileImageURL,
    });
    // eslint-disable-next-line
  }, [phoneNumber]);

  const handleCloseModal = () => {
    setChangePasswordDialogVisibility(false);
  };

  const changePassword = () => {

    const newData = { ...passwordForm, ...UserProfileData() };

    setErrorVisibility(false);
    setIsSubmitting(true);
    axios
      .post(changePasswordURL, newData, {
        method: "POST",
      })
      .then(function (response) {
        console.log(response);
        setIsSubmitting(false);

        if (response["data"]["success"] === false) {
          window.scrollTo(0, 0);
          setErrorVisibility(true);
          setErrorMessage(response["data"]["message"]);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        } else if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          setSuccessMessage(
            "User has successfully been set to a contract user"
          );

          setTimeout(() => {
            setSuccessVisibility(false);
          }, 3000);
        }
      })
      .catch(function (error) {
        setIsSubmitting(false);
        return error;
      });
  };

  const showChangePasswordDialog = (e) => {
    e.preventDefault();

    setChangePasswordDialogVisibility(true);
  };

  const form = useRef(null);

  const updateProfile = (e) => {
    e.preventDefault();

    setErrorVisibility(false);

    const newData = { ...userData, ...UserProfileData() };

    console.log(newData);

    setIsUpdating(true);

    axios
      .post(updateProfileURL, newData)
      .then(function (response) {
        console.log(response);
        setIsUpdating(false);

        if (response["data"]["success"] === false) {
          window.scrollTo(0, 0);
          setErrorVisibility(true);
          setErrorMessage(response["data"]["message"]);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        } else if (response["data"]["success"] === true) {
          window.scrollTo(0, 0);
          setEditProfile(true);

          const firstName = response["data"]["data"]["first_name"];
          const lastName = response["data"]["data"]["last_name"];
          const email = response["data"]["data"]["email"];
          const phoneNumber = response["data"]["data"]["phone"];
          const profile_image_url =
            response["data"]["data"]["profile_image_url"];

          console.log(phoneNumber);

          SessionManagement.setFirstName(firstName);
          SessionManagement.setLastName(lastName);
          SessionManagement.setEmail(email);
          SessionManagement.setPhone(phoneNumber);
          SessionManagement.setProfileImageURL(profile_image_url);

          setSuccessVisibility(true);
          setSuccessMessage("Profile has been updated successfully.");

          setTimeout(() => {
            setSuccessVisibility(false);
          }, 3000);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const uploadImage = (file) => {
    setErrorVisibility(false);

    const formData = new FormData();
    formData.append("document", file);

    setUploadLoading(true);

    axios
      .post(uploadMediaUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        console.log(response);
        setUploadLoading(false);
        setUserData({
          ...userData,
          profile_image_url: response.data,
        });
      })
      .catch(function (error) {
        return error;
      });
  };

  const handleFileInput = (e) => {
    const { files } = e.target;
    uploadImage(files[0]);
  };

  const passwordValidation = () => {
    if (passwordForm.confirm_password === "") {
      return true;
    } else if (passwordForm.new_password === "") {
      return true;
    } else if (passwordForm.new_password !== passwordForm.confirm_password) {
      return true;
    }

    return false;
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handlePhoneInput = (code) => {
    setUserData({
      ...userData,
      phone_number: code,
    });
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const openUpdateProfileConfirmation = (e) => {
    e.preventDefault();

    setShowUpdateDialog(true);
  };

  const closeUpdateProfileConfirmation = (e) => {
    e.preventDefault();

    setShowUpdateDialog(false);
  };

  const openPasswordConfirmation = (e) => {
    e.preventDefault();

    setShowPasswordDialog(true);
  };

  const closePasswordConfirmation = (e) => {
    e.preventDefault();

    setShowPasswordDialog(false);
  };

  return (
    <>
      {isError === false ? null : (
        <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
      )}

      {isSuccess === false ? null : (
        <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
      )}

      <ProfileConfirmationDialog
        isModalVisible={showUpdateDialog}
        isLoading={isUpdating}
        handleCloseDialog={closeUpdateProfileConfirmation}
        updateProfile={updateProfile}
      />
      <ChangePasswordModal
        isModalVisible={changePasswordDialogVisibility}
        handleCloseDialog={handleCloseModal}
      />

      <PasswordConfirmationDialog 
        isModalVisible={showPasswordDialog}
        handleCloseDialog={closePasswordConfirmation}
        isLoading={isSubmitting}
        updatePassword={changePassword}
      />

      <div className="settings_section">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex gap-3 align-items-start">
            <img src={User} alt="user" className="mt-2" />
            <div>
              <p style={{ fontSize: 22, marginBottom: 8, fontWeight: "bold" }}>
                Personal Details
              </p>
              <span>Edit your profile details below</span>
            </div>
          </div>
          {editProfile ? (
            <div
              style={{ marginTop: 20 }}
              onClick={() => setEditProfile(false)}
              className=" settings_btn"
            >
              {" "}
              Cancel
            </div>
          ) : (
            <div
              style={{ marginTop: 20 }}
              onClick={() => setEditProfile(true)}
              className=" settings_btn"
            >
              {" "}
              <TbEdit /> Edit Details
            </div>
          )}
        </div>
        <form ref={form}>
          <div className="row mt-4">
            <div className="col-xl-4 col-md-6 mt-4 mt-md-0 ps-md-5 col-12 col">
              <div className="d-flex flex-column align-items-start">
                <div
                  style={{
                    width: "fit-content",
                    height: "fit-content",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <img
                    style={{ width: 110, height: 110, borderRadius: "50%" }}
                    src={
                      userData.profile_image_url === ""
                        ? profileImageURL
                        : userData.profile_image_url
                    }
                    alt="ProfileImage"
                  />
                  {uploadLoading && (
                    <div
                      className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
                      style={{ backgroundColor: "#FFFFFF66", zIndex: 4 }}
                    >
                      <span className="spinner-border spinner-grow-sm" />
                    </div>
                  )}
                </div>
                <span style={{ marginTop: 15, fontSize: 14 }}>
                  Change Profile Image
                </span>
                <input
                  style={{
                    visibility: "hidden",
                    position: "fixed",
                    bottom: 0,
                    zIndex: -1,
                  }}
                  type="file"
                  onChange={handleFileInput}
                  disabled={!editProfile}
                  ref={fileInputRef}
                  accept="image/*"
                  name=""
                  id=""
                />
                <div
                  style={{ marginTop: 20 }}
                  onClick={triggerFileInput}
                  className=" settings_btn"
                >
                  <MdOutlineAddPhotoAlternate /> Choose Image
                </div>
              </div>
            </div>

            <div className="col-xl-8 col-md-6 col-12 col">
              <Row>
                <Col className="form-group col-12 col-xl-6 col-md-6">
                  <label htmlFor="Full Name">First Name</label>
                  <TextBox
                    name="first_name"
                    disabled={!editProfile}
                    type="text"
                    value={userData.first_name}
                    onChange={handleInput}
                  />
                </Col>

                <Col className="form-group col-12 col-xl-6 col-md-6">
                  <label htmlFor="Email">Last Name</label>
                  <TextBox
                    name="last_name"
                    disabled={!editProfile}
                    type="text"
                    value={userData.last_name}
                    onChange={handleInput}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="form-group col-12 mb-3 mb-md-0 col-xl-6 col-md-6">
                  <label htmlFor="Email Address">Email Address</label>
                  <TextBox
                    name="email"
                    disabled={!editProfile}
                    type="email"
                    value={userData.email}
                    onChange={handleInput}
                  />
                </Col>

                <Col className="form-group col-12 col-xl-6 col-md-6">
                  <label htmlFor="Email">Phone Number</label>
                  <PhoneFieldBox
                    onChange={handlePhoneInput}
                    value={userData.phone_number}
                    disabled={!editProfile}
                  />
                </Col>
              </Row>
            </div>

            <div
              className="d-flex flex-row my-5 justify-content-end"
              style={{
                borderBottom: "1px dashed #BFC9CA",
                paddingBottom: "2rem",
              }}
            >
              <SubmitButton
                disabled={!editProfile}
                onClick={openUpdateProfileConfirmation}
                type="submit"
                className=" btn-primary"
              >
                Update Profile
              </SubmitButton>
            </div>

            <div className="col-xl-4 col-md-6 mt-4 mt-md-0 ps-md-5 col-12 col">
              <div className="d-flex flex-column align-items-start ">
                <div>
                  <p
                    style={{
                      fontSize: 22,
                      marginBottom: 8,
                      fontWeight: "bold",
                    }}
                  >
                    Passwords
                  </p>
                  <span className="pe-md-4">
                    Edit your account password in three steps.
                  </span>
                </div>
                <button
                  style={{ marginTop: 20 }}
                  className=" settings_btn"
                  disabled={passwordValidation()}
                  onClick={openPasswordConfirmation}
                >
                  <span className="d-flex gap-2 align-items-center" >
                      <BsCheck /> Save Changes
                    </span>
                </button>
              </div>
            </div>

            <div className="col-xl-8 col-md-6 col-12 col">
              <Row>
                <Col className="form-group col-12 col-xl-12 col-md-12">
                  <label htmlFor="Full Name">Current Password</label>
                  <PasswordBox
                    name="current_password"
                    onChange={handlePassword}
                    value={passwordForm.current_password}
                    placeholder="Enter Current Password"
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="form-group col-12 mb-3 mb-md-0 col-xl-6 col-md-6">
                  <label htmlFor="Email Address">New Password</label>
                  <PasswordBox
                    name="new_password"
                    onChange={handlePassword}
                    value={passwordForm.new_password}
                    placeholder="Enter New Password"
                  />
                </Col>

                <Col className="form-group col-12 col-xl-6 col-md-6">
                  <label htmlFor="Email">Confirm Password</label>
                  <PasswordBox
                    name="confirm_password"
                    onChange={handlePassword}
                    value={passwordForm.confirm_password}
                    placeholder="Confirm New Password"
                  />
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </div>
      {SessionManagement.getEmployeeType() === "Super Admin" && (
        <div>
          <CompanyDetails />
        </div>
      )}
    </>
  );
};

export default PersonalDetails;
