import "../styles/home.scss";
import "../styles/shipments.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserProfileData } from "../library/constants";
import { getAllCustomerGroupsUrl } from "../library/URLs";
import axios from "axios";
import styled from "styled-components";
import * as React from "react";
import SubscriptionInactive from "../components/SubscriptionInactive";
import Group from "../assets/customers/group.svg";
import { shortenTexts } from "../components/reusables";
import Tooltip from "@mui/material/Tooltip";
import CreateCustomerGroupDialog from "../modals/CreateCustomerGroupDialog";
import EmptyScreen from "../components/EmptyScreen";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const GroupBadge = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 100%;
  border: 1px solid #CDCDCD;
`

const CustomerGroups = () => {
  const navigator = useNavigate();


  const [isLoading, setIsLoading] = useState(true);

  const [groups, setGroups] = useState([]);
  const [showCreate, setShowCreate] = useState(false);

  const newData = { ...UserProfileData() };

  const getAllGroups = async () => {
    await axios
    .post(getAllCustomerGroupsUrl, newData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {

      setIsLoading(false);

      if (response["data"]["success"] === false) return;

      const groups = response["data"]["data"];

      setGroups(groups);
    });
  };

  useEffect(() => {
    getAllGroups();
    // eslint-disable-next-line
  }, []);

  const handleClick = (groupID) => {
    navigator("/customer-groups/" + groupID);
  };

  return (
    <div className="home">
      <CreateCustomerGroupDialog 
        isModalVisible={showCreate}
        handleCloseDialog={() => setShowCreate(false)}
        onSubmit={getAllGroups}
      />
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />
          <div className="container-fluid shipments-container">
            <div className="card border-0 p-4 min-vh-100" style={{backgroundColor: "#FFF"}}>
              <div className="d-flex flex-column flex-md-row gap-3 align-items-center justify-content-between mb-4">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={Group} alt="batches" />
                  <h5 className="pt-1">Customer Groups</h5>
                </div>
                <div
                  className="d-flex flex align-items-center"
                >
                  <button onClick={() => setShowCreate(true)} className="btn-primary" >
                    Create Group
                  </button>
                </div>
              </div>

              <div className="table-contain w-100">
                <table className="shipments-table w-100">
                  <thead>
                    <tr>
                      <th scope="col">Group Name</th>
                      <th scope="col">Description</th>
                    </tr>
                  </thead>
                  {isLoading === true ? null : (
                    <tbody>
                      {groups.map((group) => {
                        
                        return (
                          <tr key={group.id}>
                            <td
                              onClick={() => handleClick(group.id)}
                            >
                              <span className="d-flex gap-3 align-items-center">
                                <GroupBadge src={group.image_url} alt={group.name} />
                                {group.name}
                              </span>
                            </td>
                            <td
                              onClick={() => handleClick(group.id)}
                            >
                              <Tooltip
                                title={`${
                                  group.description
                                }`}
                                placement="top"
                              >
                                <span>{shortenTexts(group.description, 90)}</span>
                              </Tooltip>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>

              {
                !isLoading && groups.length < 1 && (
                  <EmptyScreen 
                    message={"No group created yet!"}
                  />
                )
              }

              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerGroups;
