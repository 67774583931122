import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import {
  getAPICarriersUrl,
  getMarginByCarrierUrl,
  updatePriceMarginsUrl,
} from "../library/URLs";
import { useEffect, useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import { SelectBox, TextBox } from "../components/InputFields";
import { BsX } from "react-icons/bs";

const SubmitButton = styled.button``;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const ContentFieldsActionButton = styled.div`
  border-radius: 50%;
  height: 40px;
  min-width: 40px;
  color: #ffffff;
  display: flex;
  font-size: 30px;
  margin-right: 13px;
  align-items: center;
  justify-content: center;
  background-color: #008561;
  cursor: pointer;

  svg {
    font-size: 18px;
  }
`;

const UpdatePriceMarginDialog = ({
  isModalVisible,
  handleCloseDialog,
  carrier,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiCarriers, setApiCarriers] = useState([]);
  const initState = {
    shipment_type: "",
    carrier: "",
    margin_type: "",
    flat_value: "",
    undefined_value: "",
    weight_range: [
      {
        from_weight: "",
        to_weight: "",
        value: "",
      },
    ],
  };

  const [carrierForm, setCarrierForm] = useState(initState);

  useEffect(() => {
    if (carrier) {
      fetchApiCarriers();
      fetchCarrierMargin();
    }
    // eslint-disable-next-line
  }, [carrier?.shipment_type]);

  const fetchApiCarriers = async () => {
    await axios
      .post(getAPICarriersUrl, UserProfileData())
      .then((res) => {
        if (res?.data?.success) {
          if (carrier) {
            let api_carriers = res?.data?.data
              ?.filter(
                (carrier) =>
                  carrier[carrier?.shipment_type?.toLowerCase()] === "Yes"
              )
              ?.map((carrier) => {
                return {
                  label: carrier?.name,
                  value: carrier?.id,
                };
              });
            setApiCarriers(api_carriers);
          }
        }
      })
      .catch((err) => {});
  };

  const [loading, setLoading] = useState(false);
  const fetchCarrierMargin = async () => {
    let data = {
      ...UserProfileData(),
      carrier_id: carrier?.carrier_id,
      shipment_type: carrier?.shipment_type,
    };
    setLoading(true);
    await axios
      .post(getMarginByCarrierUrl, data)
      .then((res) => {
        setLoading(false);
        if (res?.data?.success) {
          let response = res?.data?.data;
          if (response?.data === false) return;

          let margin_details = {
            carrier: response?.carrier,
            shipment_type: response?.shipment_type,
            margin_type: response?.margin_type,
            flat_value: response?.flat_value,
            undefined_value: response?.undefined_value,
          };

          if (response?.weight_range) {
            margin_details["weight_range"] = response?.weight_range?.map((weight) => {
              return {
                to_weight: weight?.weight_to,
                from_weight: weight?.weight_from,
                value: weight?.value,
              };
            })
          }

          setCarrierForm({
            ...carrierForm,
            ...margin_details,
          });
        }
      })
      .catch((err) => {});
  };

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleInput = (e) => {
    const { name, value } = e.target;

    setCarrierForm({
      ...carrierForm,
      [name]: value,
    });
  };

  const margin_type_options = [
    { label: "Flat", value: "Flat" },
    { label: "Range", value: "Range" },
  ];

  const addWeightRate = () => {
    const per_kg_copy = [...carrierForm.weight_range];

    per_kg_copy.push({
      to_weight: "",
      from_weight: "",
      value: "",
    });

    setCarrierForm({
      ...carrierForm,
      weight_range: per_kg_copy,
    });
  };

  const removeWeightRate = () => {
    if (carrierForm.weight_range.length < 1) {
      return;
    }

    const per_kg_copy = [...carrierForm.weight_range];
    per_kg_copy.pop();
    setCarrierForm({
      ...carrierForm,
      weight_range: per_kg_copy,
    });
  };

  const handleWeightRateInput = (e, id) => {
    const { name, value } = e.target;

    const per_kg_copy = [...carrierForm.weight_range];

    per_kg_copy[id] = {
      ...per_kg_copy[id],
      [name]: value,
    };

    setCarrierForm({
      ...carrierForm,
      weight_range: per_kg_copy,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const formdata = {
      ...UserProfileData(),
      ...carrierForm,
      shipment_type: carrier?.shipment_type,
      carrier_id: carrier?.carrier_id,
    };
    setIsSubmitting(true);

    axios
      .post(updatePriceMarginsUrl, formdata)
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.success) {
          window.scrollTo(0, 0);

          setSuccessVisibility(true);
          setSuccessMessage("Carrier margin updated successfully!");

          setTimeout(() => {
            setSuccessVisibility(false);
          }, 3000);
          setTimeout(() => {
            setCarrierForm(initState);
            window.location.reload();
            handleCloseDialog();
          }, 4000);
        } else {
          window.scrollTo(0, 0);
          setErrorVisibility(true);
          setErrorMessage(res?.data?.message);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  return (
    <Modal
      size="lg"
      show={isModalVisible}
      onHide={() => {
        handleCloseDialog();
        setCarrierForm(initState);
      }}
    >
      <Modal.Header>
        <h5 className="header">UPDATE CARRIER PRICING</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={() => {
            handleCloseDialog();
            setCarrierForm(initState);
          }}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <p>Fill the details below to add a container</p>
        <div className="query-result-container">
          {loading ? (
            <div className="d-flex my-4 justify-content-center">
              <span className="spinner-border spinner-grow-sm" />
            </div>
          ) : (
            <div>
              <Row className="mb-2">
                <Col md={6}>
                  <div className="w-100">
                    <label htmlFor="Description" className="mb-2">
                      Shipment Type
                    </label>
                    <TextBox
                      type="text"
                      name="shipment_type"
                      disabled
                      value={carrier?.shipment_type}
                      onChange={handleInput}
                      placeholder="Enter Shipment Type"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div>
                    <label htmlFor="Description" className="mb-2">
                      Carrier
                    </label>
                    <TextBox
                      name="carrier"
                      value={carrier?.carrier_name}
                      disabled
                      onChange={handleInput}
                      placeholder="Enter Carrier"
                    />
                  </div>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col md={carrierForm?.margin_type === "Flat" ? 12 : 6}>
                  <div className="mb-2">
                    <label htmlFor="Description" className="mb-2">
                      Margin Type
                    </label>
                    <SelectBox
                      name="margin_type"
                      value={carrierForm?.margin_type}
                      options={margin_type_options}
                      onChange={handleInput}
                      placeholder="Enter Margin Type"
                    />
                  </div>
                </Col>

                {carrierForm?.margin_type === "Range" && (
                  <Col sm={12} md={6}>
                    <div className="">
                      <label htmlFor="Description" className="mb-2">
                        Undefined Value
                      </label>
                      <TextBox
                        type="text"
                        name="undefined_value"
                        value={carrierForm.undefined_value}
                        onChange={handleInput}
                        placeholder="Enter Undefined Value for range type"
                      />
                    </div>
                  </Col>
                )}
              </Row>

              {carrierForm?.margin_type === "Flat" && (
                <div className="mb-2">
                  <label htmlFor="Description" className="mb-2">
                    Flat Value
                  </label>
                  <TextBox
                    type="text"
                    name="flat_value"
                    value={carrierForm.flat_value}
                    onChange={handleInput}
                    placeholder="Enter Flat Value"
                  />
                </div>
              )}

              {carrierForm?.margin_type === "Range" && (
                <div className="mb-2">
                  {carrierForm.weight_range?.map((w_range, id) => (
                    <div key={id} className="mb-3">
                      <Row className="mb-2">
                        <Col sm={12} md={4}>
                          <div className="">
                            <label className="mb-2" htmlFor="Description">
                              From Weight
                            </label>
                            <TextBox
                              name="from_weight"
                              type="text"
                              value={w_range?.from_weight}
                              onChange={(e) => handleWeightRateInput(e, id)}
                              placeholder="KG"
                              prefix="KG"
                            />
                          </div>
                        </Col>
                        <Col sm={12} md={4}>
                          <div className="">
                            <label className="mb-2" htmlFor="Description">
                              To Weight
                            </label>
                            <TextBox
                              name="to_weight"
                              type="text"
                              value={w_range?.to_weight}
                              onChange={(e) => handleWeightRateInput(e, id)}
                              placeholder="KG"
                              prefix="KG"
                            />
                          </div>
                        </Col>
                        <Col sm={12} md={4}>
                          <div className="">
                            <label className="mb-2" htmlFor="Description">
                              Margin Value
                            </label>
                            <TextBox
                              name="value"
                              type="text"
                              value={w_range?.value}
                              onChange={(e) => handleWeightRateInput(e, id)}
                              prefix={"%"}
                              placeholder="Enter Fee"
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}

                  <div className="d-flex gap-3 align-items-center mb-2">
                    <div className="">
                      <ContentFieldsActionButton onClick={addWeightRate}>
                        +
                      </ContentFieldsActionButton>
                    </div>
                    <div className="">
                      <ContentFieldsActionButton onClick={removeWeightRate}>
                        -
                      </ContentFieldsActionButton>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn-secondary mx-3"
          onClick={() => {
            handleCloseDialog();
            setCarrierForm(initState);
          }}
        >
          <BsX />
          Cancel
        </button>
        <SubmitButton onClick={onSubmit} type="submit" className="btn-primary">
          {isSubmitting === false ? (
            <span className="button-text d-flex align-items-center gap-2">
              Proceed
            </span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdatePriceMarginDialog;
