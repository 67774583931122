import { Col, Modal, Row } from "react-bootstrap";
import { createRef, useRef, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { editTransporterURL, topupUserWalletURL } from "../library/URLs";
import { UserProfileData } from "../library/constants";
import { BiX } from "react-icons/bi";
import * as React from "react";
import nigeriaStates from "../files/nigeriaStates.json";
import { SelectBox } from "../components/InputFields";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const LoadingContainer = styled.div`
  height: 365px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

const EdiTransporterDialog = ({
  isModalVisible,
  handleCloseDialog,
  transporterID,
  transporterName,
  transporterUsername,
  transporterPhoneNumber,
  transporterState
}) => {
  const form = useRef(null);
  const urlPromoRef = createRef();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const editTransporter = () => {
    const formValues = {};
    formValues["rider_id"] = transporterID;

    const data = new FormData(form.current);

    for (const [key, value] of data.entries()) {
      formValues[key] = value;
    }

    const newData = { ...formValues, ...UserProfileData() };

    setErrorVisibility(false);
    setIsSubmitting(true);

    console.log(formValues);

    axios
      .post(editTransporterURL, newData)
      .then(function (response) {
        console.log(response);
        setIsSubmitting(false);

        if (response["data"]["success"] === false) {
          window.scrollTo(0, 0);
          setErrorVisibility(true);
          setErrorMessage(response["data"]["message"]);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        } else if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          setSuccessMessage("Transporter details updated!");

          setTimeout(() => {
            setSuccessVisibility(false);
            handleCloseDialog("Refresh");
          }, 3000);
        }
      })
      .catch(function (error) {
        setIsSubmitting(false);
        return error;
      });
  };

  const state_options = nigeriaStates?.data?.map((state) => {
    return {
      label: state?.name,
      value: state?.name,
    };
  });

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 style={{ marginTop: 10 }} className="header">
          EDIT TRANSPORTER
        </h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <form ref={form}>
          <div>
            <div className="mt-3">
              <label htmlFor="Amount">Username</label>
              <input
                name="amount"
                className="form-control"
                type="text"
                defaultValue={transporterUsername}
                disabled={true}
              />
            </div>
            <Row className="mt-2">
              <Col>
                <label htmlFor="User Name">Name</label>
                <input
                  name="name"
                  className="form-control"
                  type="text"
                  defaultValue={transporterName}
                />
              </Col>
            </Row>
            <div className="mt-2">
              <label htmlFor="Description">Phone Number</label>
              <input
                name="phone"
                className="form-control"
                defaultValue={transporterPhoneNumber}
              />
            </div>

            <div className="mt-2">
              <label htmlFor="Description">State</label>
              <SelectBox
                name="state"
                defaultValue={transporterState}
                placeholder="Select state"
                options={state_options}
              />
            </div>

            {/* <div className="mt-2">
              <label htmlFor="Amount">Screenshot of Payment (Optional)</label>
              <input
                type="file"
                ref={urlPromoRef}
                className="form-control-file"
                name="screenshot"
                onChange={onChangeImage}
              />
            </div> */}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
        <SubmitButton
          onClick={(e) => editTransporter()}
          type="button"
          className="confirm-button"
        >
          {isSubmitting === false ? (
            <span className="button-text">EDIT TRANSPORTER</span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default EdiTransporterDialog;
