import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { UserProfileData } from "../../../library/constants";
import axios from "axios";
import { getShipmentsByUserURL } from "../../../library/URLs";
import styled from "styled-components";
import moment from "moment";
import ShipmentIcon from "../../../assets/single-customer/inventory_2.svg";
import Tooltip from "@mui/material/Tooltip";
import { customerAddress } from "../../../library/utilities";
import Pagination from "../../../components/Pagination";

const LoadingContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const EmptyContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomerProfileShipments = ({ customerID }) => {
  const navigator = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [shipments, setShipments] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const [searchParam] = useSearchParams();

  const page = searchParam.get("shipment-page");

  useEffect(() => {
    if (page) {
      setCurrentPage(parseInt(page));
      window.scrollTo(0, 0);
    }
  }, [page]);

  const data = {
    customer_id: customerID,
    limit: 20,
    offset_date: "",
    offset_id: 0,
    status: "All",
    paginated: "Yes",
    page: page ?? "1",
  };

  const newData = { ...data, ...UserProfileData() };

  useEffect(() => {
    axios
      .post(getShipmentsByUserURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        const shipments = response["data"]["data"]["shipments"];
        const total_pages = response["data"]["data"]["total_pages"];

        if (shipments.length === 0) setIsEmpty(true);

        setShipments(shipments);
        setTotalPages(parseInt(total_pages));
      });
    // eslint-disable-next-line
  }, [page]);

  const handleClick = (shipmentID) => {
    navigator("/shipment/" + shipmentID);
  };

  const shortenTexts = (text) => {
    if (text) {
      if (text?.length > 16) {
        return `${text?.slice(0, 16)}...`;
      } else {
        return text;
      }
    }
  };

  const paginate = (num) => {
    setCurrentPage(num);
    // fetchShipments(num);
    navigator(`/customer/${customerID}?shipment-page=${num}`);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
    // fetchShipments(currentPage + 1);
    navigator(`/customer/${customerID}?shipment-page=${currentPage + 1}`);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
    // fetchShipments(currentPage - 1);
    navigator(`/customer/${customerID}?shipment-page=${currentPage - 1}`);
  };

  return (
    <div className="card bg-light border-0 shadow">
      <div className="card-body">
        <div className="d-flex flex-row justify-content-between">
          <div className="tab-header-container">
            <img src={ShipmentIcon} alt="shipments" />
            Customer Shipments
          </div>
          <div></div>
        </div>

        <div className="shipments-table-container">
          <div
            className=""
            style={{ width: "100%", overflowX: "auto", overflowY: "hidden" }}
          >
            <table className="shipments-table">
              <thead>
                <tr>
                  <th scope="col">Receiver</th>
                  <th scope="col">Pickup Location</th>
                  <th scope="col">Dropoff Location</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Transporter</th>
                  <th scope="col">Shipment ID</th>
                  <th scope="col">Status</th>
                  <th scope="col">Date</th>
                </tr>
              </thead>
              {isLoading === true ? null : (
                <tbody>
                  {shipments.map((shipment) => {
                    const dateTime = moment(shipment.date_time);
                    const timestamp = dateTime.format("ddd MMM Do, h:mm A");
                    const status =
                      shipment.status !== "" ? shipment.status : "Pending";
                    const statusClass = status.toString().replaceAll(" ", "");

                    const dropoffLocation = customerAddress(
                      shipment.receiver_address_1,
                      shipment.receiver_address_2,
                      shipment.receiver_city,
                      shipment.receiver_state,
                      shipment.receiver_postcode
                    );

                    const pickupLocation = customerAddress(
                      shipment.sender_address_1,
                      shipment.sender_address_2,
                      shipment.sender_city,
                      shipment.sender_state,
                      shipment.sender_postcode
                    );

                    return (
                      <tr
                        style={{ fontSize: "14.4px" }}
                        key={shipment.id}
                        onClick={() => handleClick(shipment.id)}
                      >
                        <td>
                          <Tooltip
                            title={`${shipment?.receiver_name}`}
                            placement="top"
                          >
                            <span>{shortenTexts(shipment.receiver_name)}</span>
                          </Tooltip>
                        </td>
                        <td>
                          <Tooltip title={`${pickupLocation}`} placement="top">
                            <span>{shortenTexts(pickupLocation)}</span>
                          </Tooltip>
                        </td>
                        <td>
                          <Tooltip title={`${dropoffLocation}`} placement="top">
                            <span>{shortenTexts(dropoffLocation)}</span>
                          </Tooltip>
                        </td>
                        <td>
                          {shipment?.currency === "USD"
                            ? "$"
                            : shipment?.currency === "NGN"
                            ? "₦"
                            : shipment?.currency === "GBP"
                            ? "£"
                            : shipment?.currency === "EUR"
                            ? "€"
                            : "₦"}
                          {parseFloat(shipment.total_amount).toLocaleString(
                            "en"
                          )}
                        </td>
                        <td>
                          <Tooltip
                            title={`${
                              shipment.processor === "Self" ||
                              shipment.processor === ""
                                ? shipment.rider_name
                                  ? shipment.rider_name
                                  : "No Rider"
                                : shipment.processor
                            }`}
                            placement="top"
                          >
                            <span>
                              {shipment.processor === "Self"
                                ? shortenTexts(shipment.rider_name)
                                  ? shortenTexts(shipment.rider_name)
                                  : "No Rider"
                                : shortenTexts(shipment.processor)}
                            </span>
                          </Tooltip>
                        </td>
                        <td>{shipment.shipment_no}</td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          <span className={`status ${statusClass}`}>
                            {status}
                          </span>{" "}
                        </td>
                        <td>{timestamp}</td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
        </div>
        {!isLoading && shipments.length > 0 && (
          <Pagination
            total_pages={totalPages}
            paginate={paginate}
            nextPage={nextPage}
            prevPage={prevPage}
            current_page={currentPage}
          />
        )}

        {isLoading === false ? null : (
          <LoadingContainer>
            <LoadingSpinner className="spinner-border spinner-grow-sm" />
          </LoadingContainer>
        )}
        {isEmpty === false ? null : (
          <EmptyContainer>
            <p>Oops... Sorry, customer has no recorded shipments</p>
          </EmptyContainer>
        )}
      </div>
    </div>
  );
};

export default CustomerProfileShipments;
