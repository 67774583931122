import { Modal } from "react-bootstrap";
import axios from "axios";
import { addCustomerToGroupUrl, searchForCustomersURL } from "../library/URLs";
import { useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import { BsCheckCircleFill, BsDot, BsX } from "react-icons/bs";
import { SearchBox } from "../components/InputFields";

const SubmitButton = styled.button``;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const ResultContainer = styled.div`
  min-height: 200px;
`;

const ResultList = styled.ul`
  list-style: none;
  padding: 0 0 0 0;
`;

const ResultLi = styled.li`
  padding-bottom: 10px;
  padding-left: 16px;
  cursor: pointer;

  &:last-child {
    border: none;
  }
`;

const Pill = styled.div`
  width: fit-content;
  padding: 4px 12px;
  border-radius: 24px;
  font-size: 12px;
  color: #139C5F;
  background-color: #E7F6EC;
  white-space: nowrap;
  display: flex;
  gap: 4px;
  align-items: center;
`

const AddCustomerGroupDialog = ({
  isModalVisible,
  handleCloseDialog,
  groupID,
  onSubmit = () => {},
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [query, setQuery] = useState("");
  const [isQuerySearching, setIsQuerySearching] = useState(false);
  const [customerQueryResults, setCustomerQueryResults] = useState([]);

  const [members, setMembers] = useState([]);

  const addMember = (e) => {
    const formValues = {};
    formValues["group_id"] = `${groupID}`;
    let selected_customers = members?.map(member => member.id).join("|");
    formValues["customer_id"] = selected_customers;

    const newData = { ...formValues, ...UserProfileData() };

    setErrorVisibility(false);
    setIsSubmitting(true);

    console.log(newData);

    axios
      .post(addCustomerToGroupUrl, newData)
      .then(function (response) {
        console.log(response);
        setIsSubmitting(false);
        if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          setSuccessMessage("Group member(s) added.");
          setQuery("");
          setMembers([]);
          onSubmit();

          setTimeout(() => {
            setSuccessVisibility(false);
            handleCloseDialog(true);
          }, 3000);
        } else {
          setErrorMessage(response["data"]["message"]);
          setErrorVisibility(true);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  const handleQueryChange = (e) => {
    const { value } = e.target;
    setQuery(value);

    if (value.toString().trim() === "") {
      setIsQuerySearching(false);

      return null;
    }

    const formValues = {};
    formValues["query"] = value;
    formValues["limit"] = "5";

    const newData = { ...formValues, ...UserProfileData() };

    setIsQuerySearching(true);

    axios.post(searchForCustomersURL, newData).then(function (response) {
      console.log(response);

      setIsQuerySearching(false);
      if (response["data"]["success"] === false) return;

      const customers = response["data"]["data"];

      setCustomerQueryResults(customers);
    });
  };

  const onClick = (customer) => {
    if(members.some(member => customer.id === member.id)) {
      let updated_members = members.filter(member => member.id !== customer.id);

      setMembers(updated_members);
    } else {
      let updated_members = [...members];

      updated_members.push(customer);

      setMembers(updated_members);
    }
  };

  const removeMember = (customer) => {
    let updated_members = members.filter(member => member.id !== customer.id);

    setMembers(updated_members);
  }

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">Add {members?.length} MEMBER(S)</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <div>
          <div className="w-100 border-bottom pb-4">
            <div className="w-100 mb-3">
              <form className="query-result-container ">
                <SearchBox
                  onChange={handleQueryChange}
                  name="query"
                  value={query}
                  placeholder="Search using customer's name or number"
                />
              </form>
            </div>
            <div className="d-flex flex-wrap align-items-center gap-2">
              {
                members.map((member) => (
                  <Pill>
                    {member.fullname}
                    <BsX onClick={() => removeMember(member)} style={{color: "#D42620", cursor: "pointer"}} />
                  </Pill>
                ))
              }
            </div>
          </div>
          <div>
            <ResultContainer className={``}>
              {isQuerySearching === true ? (
                <span className="spinner-border spinner-grow-sm" />
              ) : (
                <ResultList>
                  {customerQueryResults.map((customer, id) => {
                    return (
                      <ResultLi
                        key={customer.id}
                        onClick={() => onClick(customer)}
                        className={`d-flex gap-2 align-items-center ${id === 0 && "pt-3"} ${
                          id + 1 !== customerQueryResults.length &&
                          "border-bottom"
                        }`}
                      >
                        {
                          members?.find(cust => cust?.id === customer.id) && (
                            <BsCheckCircleFill style={{color: "#139C5F"}} />
                          )
                        }
                        <div className="d-flex flex-column" >
                          <span style={{ fontSize: 15 }}>
                            {customer.fullname}
                          </span>
                          <span className="d-flex align-items-center">
                            <span style={{ fontSize: 13 }}>{customer.phone}</span>
                            <BsDot />
                            <span style={{ fontSize: 13 }}>{customer.email}</span>
                          </span>
                        </div>
                      </ResultLi>
                    );
                  })}
                </ResultList>
              )}
            </ResultContainer>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn-secondary mx-3"
          onClick={(e) => handleCloseDialog(false)}
        >
          <BsX /> CANCEL
        </button>
        <SubmitButton
          onClick={addMember}
          type="submit"
          className="btn-primary"
        >
          {isSubmitting === false ? (
            <span className="button-text d-flex align-items-center gap-2">
              Proceed
            </span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCustomerGroupDialog;
