import React, { useEffect, useState } from "react";
import { getCargoAirlinesUrl } from "../../../../library/URLs";
import axios from "axios";
import { UserProfileData } from "../../../../library/constants";
// import { Tooltip } from "@mui/material";
import styled from "styled-components";

const LoadingContainer = styled.div`
  height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const Cargo = ({ opened }) => {
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAirlines = async () => {
    setIsLoading(true);
    await axios
      .post(getCargoAirlinesUrl, UserProfileData())
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.success) {
          let anka_accounts = res?.data?.data;

          setAccounts(anka_accounts);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (opened) {
      fetchAirlines();
    }
  }, [opened]);

  return (
    <div>
      {isLoading ? (
        <LoadingContainer>
          <LoadingSpinner className="spinner-border spinner-grow-sm" />
        </LoadingContainer>
      ) : (
        <ul className="p-0" style={{listStyle: "none"}} >
          {accounts?.length < 1 ? (
            <li className="mb-4" >
              No airline available
            </li>
          ) : accounts?.map((airline) => (
            <li
              key={airline?.id}
              className="d-flex justify-content-between mb-4 align-items-center"
            >
              <label className="form-check-label d-flex gap-4 align-items-center" htmlFor="">
                {airline?.name}
              </label>
              <div className="d-flex gap-4 align-items-center">
                <input type="checkbox" name="" id="" />
               
              </div>
            </li>
          ))}
          {/* <button
            className="mt-2 add-carrier"
            type="button"
            onClick={openAddAnkaAccount}
          >
            <BsPlusCircleFill /> Add Account
          </button> */}
        </ul>
      )}
    </div>
  );
};

export default Cargo;
