import React, { useEffect, useState } from "react";
import { getAnkaAccountsUrl } from "../../../../library/URLs";
import axios from "axios";
import { UserProfileData } from "../../../../library/constants";
import { TbPencil, TbTrash } from "react-icons/tb";
import { Tooltip } from "@mui/material";
import styled from "styled-components";
import { BsCheckCircleFill, BsPlusCircleFill } from "react-icons/bs";
import AddAnkaAccountDialog from "../../../../modals/AddAnkaAccountDialog";
import EditAnkaAccountDialog from "../../../../modals/EditAnkaAccountDialog";
import DeleteAnkaAccountDialog from "../../../../modals/DeleteAnkaAccountDialog";

const LoadingContainer = styled.div`
  height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const Anka = ({ opened }) => {
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addAnkaAccount, setAddAnkaAccount] = useState(false);
  const [editAnkaAccount, setEditAnkaAccount] = useState(null);
  const [deleteAnkaAccount, setDeleteAnkaAccount] = useState(null);

  const openAddAnkaAccount = () => {
    setAddAnkaAccount(true);
  };

  const closeAddAnkaAccount = () => {
    setAddAnkaAccount(false);
  };

  const openEditAnkaAccount = (account) => {
    setEditAnkaAccount(account);
  };

  const closeEditAnkaAccount = () => {
    setEditAnkaAccount(null);
  };

  const openDeleteAnkaAccount = (id) => {
    setDeleteAnkaAccount(id);
  };

  const closeDeleteAnkaAccount = () => {
    setDeleteAnkaAccount(null);
  };

  const fetchAccounts = async () => {
    setIsLoading(true);
    await axios
      .post(getAnkaAccountsUrl, UserProfileData())
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.success) {
          let anka_accounts = res?.data?.data;

          setAccounts(anka_accounts);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (opened) {
      fetchAccounts();
    }
  }, [opened]);

  return (
    <div>
      <AddAnkaAccountDialog 
        isModalVisible={addAnkaAccount}
        handleCloseDialog={closeAddAnkaAccount}
        onSubmit={fetchAccounts}
      />
      <EditAnkaAccountDialog 
        isModalVisible={editAnkaAccount !== null}
        handleCloseDialog={closeEditAnkaAccount}
        onSubmit={fetchAccounts}
        editAccount={editAnkaAccount}
      />
      <DeleteAnkaAccountDialog 
        isModalVisible={deleteAnkaAccount !== null}
        handleCloseDialog={closeDeleteAnkaAccount}
        onSubmit={fetchAccounts}
        accountID={deleteAnkaAccount}
      />
      {isLoading ? (
        <LoadingContainer>
          <LoadingSpinner className="spinner-border spinner-grow-sm" />
        </LoadingContainer>
      ) : (
        <ul className="p-0" style={{listStyle: "none"}} >
          {accounts?.length < 1 ? (
            <li className="mb-4" >
              No account available
            </li>
          ) : accounts?.map((anka) => (
            <li
              key={anka?.id}
              className="d-flex justify-content-between mb-4 align-items-center"
            >
              <label className="form-check-label d-flex gap-4 align-items-center" htmlFor="">
                {anka?.name}
                {
                  anka?.is_primary_account === "Yes" && (
                    <BsCheckCircleFill style={{color: "#0F973D", fontSize: 12}} />
                  )
                }
              </label>
              <div className="d-flex gap-4 align-items-center">
                <Tooltip title="Edit Account" placement="top">
                  <span className="d-flex align-items-start">
                    <TbPencil
                      style={{ fontSize: 18, cursor: "pointer" }}
                      onClick={() => openEditAnkaAccount(anka)}
                    />
                  </span>
                </Tooltip>
                <Tooltip title="Delete Account" placement="top">
                  <span className="d-flex align-items-start">
                    <TbTrash
                      style={{ fontSize: 18, cursor: "pointer" }}
                      onClick={() => openDeleteAnkaAccount(anka?.id)}
                    />
                  </span>
                </Tooltip>
              </div>
            </li>
          ))}
          <button
            className="mt-2 add-carrier"
            type="button"
            onClick={openAddAnkaAccount}
          >
            <BsPlusCircleFill /> Add Account
          </button>
        </ul>
      )}
    </div>
  );
};

export default Anka;
