import React from "react";
import { Col, Row } from "react-bootstrap";
import { BsChevronLeft, BsChevronRight, BsQuestionCircleFill } from "react-icons/bs";
import styled from "styled-components";
import { CheckBox, RadioCheck } from "../../../components/InputFields";
import { FaClipboardList } from "react-icons/fa";

const EcommerceWeb = ({ prevPage, answers, setAnswers, handleSubmit, isSubmitting }) => {
  const PageHeader = styled.h4`
    font-family: Prompt, sans-serif;
    font-size: 32px;
    line-height: 28.8px;
    margin-bottom: 8px;
    color: #000;

    @media (max-width: 556px) {
      font-size: 2.4rem;
    }
  `;

  const PageSubheader = styled.h4`
    font-size: 14px;
    color: #667185;
    margin-bottom: 48px;
    font-weight: normal;
  `;

  const SubLight = styled.span`
    color: #667185;
  `

  const que1Answer = (answer) => {
    setAnswers({
      ...answers,
      e_web: {
        ...answers?.e_web,
        have_website: answer
      }
    })
  }

  const que2Answer = (answer) => {
    setAnswers({
      ...answers,
      e_web: {
        ...answers?.e_web,
        web_service: answer
      }
    })
  }

  const que3Answer = (e) => {
    const { value } = e.target;
    setAnswers({
      ...answers,
      e_web: {
        ...answers?.e_web,
        sales_product: value
      }
    })
  }

  const que2OthersAnswer = (e) => {
    const { value } = e.target;
    setAnswers({
      ...answers,
      e_web: {
        ...answers?.e_web,
        web_service_others: value
      }
    })
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log("ecommerce web answers ", answers);
  // };

  return (
    <>
      <PageHeader className="page-header feature-header">Operations - <SubLight>eCommerce Website</SubLight></PageHeader>
      <PageSubheader>
        This information will be used to assist you in setting up your account and would never be shared
      </PageSubheader>
      <div className="progress-bar">
        <div className={`progress-bar-inactive progress-bar-active`}></div>
        <div className={`progress-bar-active`}></div>
      </div>
      <form>
        <div className="question-wrap mb-3" >
          <div className="question-text" >
            <BsQuestionCircleFill />
            Do you currently have a website?
          </div>
          <ul className="multi-answer" >
            <li>
              <RadioCheck 
                checked={answers?.e_web?.have_website === "Yes"}
                onChange={()=>que1Answer("Yes")}
              />
              Yes
            </li>
            <li>
              <RadioCheck 
                checked={answers?.e_web?.have_website === "No"}
                onChange={()=>que1Answer("No")}
              />
              No
            </li>
          </ul>
        </div>
        <div className={`question-wrap mb-3`} 
        >
          <div className="question-text" >
            <BsQuestionCircleFill />
            If yes, what service powers your website?
          </div>
          <ul className="multi-answer" >
            <li>
              <RadioCheck 
                checked={answers?.e_web?.web_service === "Woo-commerce"}
                onChange={()=>que2Answer("Woo-commerce")}
              />
              WooCommerce
            </li>
            <li>
              <RadioCheck 
                checked={answers?.e_web?.web_service === "Shopify"}
                onChange={()=>que2Answer("Shopify")}
              />
              Shopify
            </li>
            <li>
              <RadioCheck 
                checked={answers?.e_web?.web_service === "Bumpa"}
                onChange={()=>que2Answer("Bumpa")}
              />
              Bumpa
            </li>
            <li>
              <RadioCheck 
                checked={answers?.e_web?.web_service === "Others"}
                onChange={()=>que2Answer("Others")}
              />
              Others
            </li>
          </ul>
        </div>
        {
          answers?.e_web?.web_service === "Others" && (
            <div className="question-wrap mb-3" >
              <div className="question-text" >
                <BsQuestionCircleFill />
                Enter the service name
              </div>
              <input 
                type="text"
                name="web_url"
                value={answers?.e_web?.web_service_others}
                onChange={que2OthersAnswer}
                placeholder="Enter your answer"
                className="question-textbox"
              />
            </div>
          )
        }
        <div className="question-wrap mb-3" >
          <div className="question-text" >
            <FaClipboardList />
            What do you want to sell on your website?
          </div>
          <div>
            <textarea 
              type="text" 
              name="sales_product" 
              value={answers?.e_web?.sales_product} 
              onChange={que3Answer}
              className="question-textarea"
              placeholder="Enter your answer"
              rows={3}
            ></textarea>
          </div>
        </div>
        <div className="d-flex flex-row my-5 gap-4 justify-content-end">
          <button type="button" className="btn-secondary" onClick={prevPage} >
            <BsChevronLeft />
          </button>
          <button type="submit" onClick={handleSubmit} className="login-button btn-primary rounded">
            {isSubmitting === false ? (
              <span className="button-text d-flex align-items-center justify-content-center">
                Proceed <BsChevronRight />
              </span>
            ) : (
              <span className="spinner-border spinner-grow-sm" />
            )}
          </button>
        </div>

        {/* <PageSubheader>
                      Already have an account? <a href="/login">Login</a>
                    </PageSubheader> */}
      </form>
    </>
  );
};

export default EcommerceWeb;
