import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import {
  dispatchBatchShipmentsUrl,
  getApiCarrierAccountsUrl,
  getAPICarriersUrl,
  getTransporterByStateUrl,
} from "../library/URLs";
import { useEffect, useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import {
  SelectBox
} from "../components/InputFields";
import { BsSdCard, BsX } from "react-icons/bs";
import nigeriaStates from "../files/nigeriaStates.json";
import { toast } from "react-toastify";

const SubmitButton = styled.button``;

const DispatchShipmentsDialog = ({
  isModalVisible,
  handleCloseDialog,
  onSubmit,
  shipments,
  state,
  batch_number
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    dispatch_type: "API",
    transporter: "",
    api_type_id: "",
    api_account_id: "",
  });
  const [apiCarriers, setApiCarriers] = useState([]);
  const [apiAccounts, setApiAccounts] = useState([]);
  const [transporters, setTransporters] = useState([]);

  const dispatchShipments = async () => {
    const formValues = {
      ...formData,
      shipment_numbers: shipments,
      batch_number
    };

    const newData = { ...formValues, ...UserProfileData() };
    setIsSubmitting(true);
    await axios
      .post(dispatchBatchShipmentsUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsSubmitting(false);
        if (response["data"]["success"] === false) {
          toast.error(response["data"]["message"]);
          return;
        }

        toast.success(response["data"]["data"]);
        setTimeout(() => {
          handleCloseDialog();
          onSubmit();
        }, 2000);
      });
  };

  const getAPICarriers = async () => {
    await axios
      .post(getAPICarriersUrl, { ...UserProfileData() })
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data?.message);
          return;
        } else {
          let api_carriers = response.data?.data
            ?.filter((carrier) => carrier.id !== 12)
            ?.map((carrier) => {
              return {
                label: carrier?.name,
                value: carrier?.id,
              };
            });

          setApiCarriers(api_carriers);
        }
      });
  };

  const getApiAccounts = async (carrier_id) => {
    await axios
      .post(getApiCarrierAccountsUrl, {
        ...UserProfileData(),
        carrier: carrier_id,
      })
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data?.message);
          return;
        } else {
          let accounts = response.data?.data?.map((carrier) => {
            return {
              label: carrier?.name,
              value: carrier?.id,
            };
          });

          setApiAccounts(accounts);
        }
      });
  };

  const getTransporters = async () => {
    await axios
      .post(getTransporterByStateUrl, { ...UserProfileData(), state })
      .then((response) => {
        if (!response.data.success) {
          toast.error(response.data?.message);
          return;
        } else {
          let transporters = response.data?.data?.map((transporter) => {
            return {
              label: transporter?.name,
              value: transporter?.id,
            };
          });
          setTransporters(transporters);
        }
      });
  };

  useEffect(() => {
    if (isModalVisible) {
      getAPICarriers();
      getTransporters();
    }

    // eslint-disable-next-line
  }, [isModalVisible]);

  const handleInput = (e) => {
    const { value, name } = e.target;

    if(name === "api_type_id" && value !== "Custom") {
      getApiAccounts(value);
    };

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const toggleType = () => {
    if (formData.dispatch_type === "API") {
      setFormData({
        ...formData,
        dispatch_type: "Transporter",
      });
    } else {
      setFormData({
        ...formData,
        dispatch_type: "API",
      });
    }
  };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">DISPATCH SHIPMENT(S)</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        <p>Dispatch selected shipment(s).</p>
        <div className="query-result-container">
          <div className="mb-5">
            <div className="rates-toggle-bar" style={{width:"100%"}} onClick={toggleType}>
              <div
                className={`rate-toggle-switch ${
                  formData.dispatch_type !== "API" && "rate-toggle-switch-state"
                }`}
              ></div>
              <div
                className={`toggle-option ${
                  formData.dispatch_type === "API" && "toggle-option-active"
                }`}
              >
                API
              </div>
              <div
                className={`toggle-option ${
                  formData.dispatch_type === "Transporter" &&
                  "toggle-option-active"
                }`}
              >
                Transporter
              </div>
            </div>
          </div>

          <Row className="create-shipment-info-form-row w-100 gy-3">
            {
              formData.dispatch_type === "Transporter" && (
                <Col
                  sm={12}
                  lg={12}
                  className="create-shipment-info-form-row-input"
                >
                  <label htmlFor="first_name">Transporter</label>
                  <SelectBox
                    name="transporter"
                    value={formData.transporter}
                    onChange={handleInput}
                    options={transporters}
                    placeholder="Select transporter"
                  />
                </Col>
              )
            }

            {
              formData.dispatch_type === "API" && (
                <Col
                  sm={12}
                  lg={formData.api_type_id !== "Custom" ? 6 : 12}
                  className="create-shipment-info-form-row-input"
                >
                  <label htmlFor="first_name">Who are you dispatching to?</label>
                  <SelectBox
                    name="api_type_id"
                    value={formData.api_type_id}
                    onChange={handleInput}
                    options={apiCarriers}
                    placeholder="Select carrier"
                  />
                </Col>
              )
            }

            {
              formData.dispatch_type === "API" && formData.api_type_id !== "Custom" && (
                <Col
                  sm={12}
                  lg={6}
                  className="create-shipment-info-form-row-input"
                >
                  <label htmlFor="first_name">Choose Account</label>
                  <SelectBox
                    name="api_account_id"
                    value={formData.api_account_id}
                    onChange={handleInput}
                    options={apiAccounts}
                    placeholder="Select carrier account"
                  />
                </Col>
              )
            }
            
          </Row>
        </div>
        <p className="mt-3">Would you like to continue?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary mx-3" onClick={handleCloseDialog}>
          <BsX />
          Cancel
        </button>
        <SubmitButton
          onClick={dispatchShipments}
          type="submit"
          className="confirm-button btn-primary"
        >
          {isSubmitting === false ? (
            <span className="button-text d-flex align-items-center gap-2">
              <BsSdCard /> Proceed
            </span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default DispatchShipmentsDialog;
