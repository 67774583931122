import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import axios from "axios";
import "../../styles/create-local-shipment.scss";
import {
  createStationUrl,
  editStationUrl,
  getAllCarrierCountriesUrl,
  getCitiesByStateNameUrl,
  getStates2Url,
  getStationsURL,
} from "../../library/URLs";
import { useRef, useState, useEffect } from "react";
import { generateTransactionID } from "../../library/utilities";
import { UserProfileData } from "../../library/constants";
import { useNavigate, useParams } from "react-router-dom";
import {
  PhoneFieldBox,
  SelectBox,
  SelectTextBox,
  TextBox,
} from "../../components/InputFields";
import nigeriaStates from "../../files/nigeriaStates.json";

const SteppedNumber = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #008561;
`;

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const SteppedTitle = styled.span`
  font-size: 20px;
`;

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #fcd2d2;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #c2eddd;
  margin-bottom: 20px;
  text-align: center;
`;

const CreateStationForm = ({ action = "create" }) => {
  const navigator = useNavigate();
  const params = useParams();
  const stationID = params["stationID"];
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    station_name: "",
    contact_name: "",
    contact_phone: "",
    contact_email: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    postcode: "",
    country: "Nigeria",
  });
  const [countries, setCountries] = useState([]);
  const [statesPending, setStatesPending] = useState(false);
  const [citiesPending, setCitiesPending] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);

  const data = {
    limit: 40,
    offset: 0,
    status: "All",
  };

  const getCountries = async () => {
    const data = {
      country: formData.country,
      state: formData?.state,
    };

    const newData = { ...data, ...UserProfileData() };
    await axios
      .post(getAllCarrierCountriesUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response["data"]["success"] === false) return;

        let countries = response?.data?.data?.map((cntry) => {
          return {
            label: cntry?.country,
            value: cntry?.country,
            code: cntry?.iso_code,
          };
        });

        setCountries(countries);
      });
  };

  const getStates = async () => {
    const data = {
      country: formData.country,
    };

    let country = formData.country;

    const newData = { ...data, ...UserProfileData() };
    setStatesPending(true);
    await axios
      .post(getStates2Url, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setStatesPending(false);

        if (response["data"]["success"] === false) return;

        let states = [];
        if (country === "Nigeria") {
          states = nigeriaStates?.data?.map((state) => {
            return {
              label: state?.name,
              value: state?.name,
            };
          });
        } else {
          states = response?.data?.data?.map((state) => {
            return {
              label: state?.name,
              value: state?.name,
            };
          });
        }
        setStateOptions(states);
      });
  };

  const getCities = async () => {
    const data = {
      country: formData.country,
      state: formData?.state,
    };

    const newData = { ...data, ...UserProfileData() };
    setCitiesPending(true);
    await axios
      .post(getCitiesByStateNameUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setCitiesPending(false);

        if (response["data"]["success"] === false) return;

        let cities = response?.data?.data?.map((city) => {
          return {
            label: city,
            value: city,
          };
        });
        setCitiesOptions(cities);
      });
  };

  useEffect(() => {
    getStates();
    // eslint-disable-next-line
  }, [formData.country]);

  useEffect(() => {
    getCities();
    //eslint-disable-next-line
  }, [formData.state]);

  const newData = { ...data, ...UserProfileData() };

  const fetchStation = async () => {
    await axios
      .post(getStationsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);

        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        if (action === "edit") {
          const newCustomers = response["data"]["data"]["data"]?.find(
            (item) => item.id === parseInt(stationID)
          );

          setFormData({
            station_name: newCustomers?.station_name,
            contact_name: newCustomers?.fullname,
            contact_phone: newCustomers?.phone,
            contact_email: newCustomers?.email,
            address_1: newCustomers?.address,
            address_2: newCustomers?.address_2,
            city: newCustomers?.city_name,
            state: newCustomers?.state,
            postcode: newCustomers?.postal_code,
            country: newCustomers?.country,
          });
        }
      });
  };

  useEffect(() => {
    fetchStation();
    getCountries();
    // eslint-disable-next-line
  }, []);

  const form = useRef(null);

  const [isCreatingStation, setIsCreatingStation] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  // let country_options = countries?.map((cntry) => {
  //   return {
  //     label: cntry.name,
  //     value: cntry.name,
  //   };
  // });

  const handleSubmit = (e) => {
    e.preventDefault();

    const formValues = { ...formData };

    formValues["transaction_id"] = generateTransactionID(15);
    formValues["station_id"] = stationID;

    const newData = { ...formValues, ...UserProfileData() };

    setIsCreatingStation(true);

    let urlAction = action === "create" ? createStationUrl : editStationUrl;

    axios
      .post(urlAction, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        setIsCreatingStation(false);
        window.scrollTo(0, 0);

        if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          setSuccessMessage(
            `Successfully ${
              action === "edit" ? "updated" : "created"
            } shipment. Redirecting now...`
          );

          const shipmentID = response["data"]["data"]["shipment-id"];
          console.log(shipmentID);

          setTimeout(() => {
            setSuccessVisibility(false);

            navigator("/stations");
          }, 3000);
        } else {
          const message = response["data"]["message"];

          setErrorVisibility(true);
          setErrorMessage(message);
          setTimeout(() => {
            setErrorVisibility(false);
          }, 3000);
        }
      });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectTextbox = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneInput = (code) => {
    setFormData({
      ...formData,
      contact_phone: code,
    });
  };

  return (
    <div>
      {isError === false ? null : (
        <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
      )}

      {isSuccess === false ? null : (
        <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
      )}

      {!isLoading ? (
        <form ref={form} onSubmit={handleSubmit}>
          <section>
            <div className="d-flex align-items-center mb-2">
              <SteppedNumber>1</SteppedNumber>
              <SteppedTitle className="mx-2">Contact Details</SteppedTitle>
            </div>
            <Row className="form-row">
              <Col className="form-group col-xl-5 col-md-6 col-12 mb-3 mb-xl-0">
                <label htmlFor="Full name">Contact Name</label>
                <TextBox
                  name="contact_name"
                  onChange={handleInput}
                  value={formData.contact_name}
                  type="text"
                  placeholder="Enter a contact name"
                />
              </Col>

              <Col style={{ position: "relative" }} className="mb-3 mb-xl-0">
                <label htmlFor="Phone number">Phone Number</label>
                <PhoneFieldBox
                  onChange={handlePhoneInput}
                  value={formData.contact_phone}
                />
              </Col>

              <Col className="form-group col-12 col-xl-3 col-md-6 mb-3 mb-xl-0">
                <label htmlFor="Email Address">Email Address</label>
                <TextBox
                  name="contact_email"
                  onChange={handleInput}
                  value={formData.contact_email}
                  type="email"
                  placeholder="Enter contact email address"
                />
              </Col>
            </Row>
          </section>

          <div>
            <section className="mt-5">
              <div className="d-flex align-items-center mb-2">
                <SteppedNumber>2</SteppedNumber>
                <SteppedTitle className="mx-2">Location Details</SteppedTitle>
              </div>
              <Row className="form-row mt-3">
                <Col className="form-group col-xl-4 col-md-4 col-12 mb-3 mb-xl-0">
                  <label htmlFor="Address">Station Name</label>
                  <TextBox
                    onChange={handleInput}
                    value={formData.station_name}
                    name="station_name"
                    maxLength={45}
                    placeholder="E.g Lekki Branch / Main Headquarters"
                  />
                </Col>

                <Col className="form-group col-xl-4 col-md-4 col-12 mb-3 mb-xl-0">
                  <label htmlFor="Address">Address</label>
                  <TextBox
                    onChange={handleInput}
                    value={formData.address_1}
                    name="address_1"
                    placeholder="E.g No. 5 plaza along Ibeju Lekki"
                    maxLength={45}
                  />
                </Col>

                <Col className="form-group col-xl-4 col-md-4 col-12 mb-3 mb-xl-0">
                  <label htmlFor="Address">Address 2 (Optional)</label>
                  <TextBox
                    name="address_2"
                    type="text"
                    placeholder="Add additional address info"
                    onChange={handleInput}
                    value={formData.address_2}
                    maxLength={45}
                  />
                </Col>
              </Row>
              <Row className="form-row mt-3">
                <Col className="form-group col-xl-3 col-md-6 col-12 mb-3 mb-xl-0">
                  <label htmlFor="Sender Country">Country</label>
                  <SelectBox
                    value={formData.country}
                    name="country"
                    options={countries}
                    placeholder="Select country"
                    onChange={handleInput}
                  />
                </Col>

                <Col className="form-group col-xl-3 col-md-6 col-12 mb-3 mb-xl-0">
                  <label htmlFor="State">State</label>
                  <div className="d-flex gap-1">
                    {statesPending && (
                      <span className="spinner-border spinner-grow-sm" />
                    )}
                    <SelectTextBox
                      name="state"
                      value={formData.state}
                      onChange={handleSelectTextbox}
                      placeholder="Select state"
                      options={stateOptions}
                    />
                  </div>
                </Col>

                <Col className="form-group col-xl-3 col-md-6 col-12 mb-3 mb-xl-0">
                  <label htmlFor="Sender City">City</label>
                  <div className="d-flex gap-1">
                    {citiesPending && (
                      <span className="spinner-border spinner-grow-sm" />
                    )}
                    <SelectTextBox
                      name="city"
                      value={formData.city}
                      onChange={handleSelectTextbox}
                      placeholder="Select city"
                      options={citiesOptions}
                    />
                  </div>
                </Col>

                <Col className="form-group col-xl-3 col-md-6 col-12 mb-3 mb-xl-0">
                  <label htmlFor="Sender Postal">Postal Code</label>
                  <TextBox
                    name="postcode"
                    value={formData.postcode}
                    onChange={handleInput}
                    placeholder="E.g 10000"
                  />
                </Col>
              </Row>
              
            </section>

            <div className="d-flex flex-row my-5 justify-content-end">
              <SubmitButton
                type="submit"
                className="confirm-button btn-primary"
              >
                {isCreatingStation === false ? (
                  <span className="button-text">
                    {action === "edit" ? "EDIT" : "CREATE"} STATION
                  </span>
                ) : (
                  <span className="spinner-border spinner-grow-sm" />
                )}
              </SubmitButton>
            </div>
          </div>
        </form>
      ) : (
        <LoadingContainer>
          <LoadingSpinner className="spinner-border spinner-grow-sm" />
        </LoadingContainer>
      )}
    </div>
  );
};

export default CreateStationForm;
