import React from "react";
import ReceiverIcon from "../../../assets/create-shipment/receiver-page.svg";
import { Col, Row } from "react-bootstrap";
import {
  PhoneFieldBox,
  SelectBox,
  SelectContactTextBox,
  SelectTextBox,
  TextBox,
} from "../../../components/InputFields";
// import countries from "../../../files/countries.json";
import { useState } from "react";
import { useEffect } from "react";
import { getAddressBookUrl, getCitiesByStateNameUrl, getStates2Url, saveAddressUrl } from "../../../library/URLs";
import axios from "axios";
import { UserProfileData } from "../../../library/constants";
import nigeriaStates from "../../../files/nigeriaStates.json";
import { toast } from "react-toastify";

const Receiver = ({ nextPage, previousPage, formData, countries, setFormData }) => {
  const [statesOptions, setStateOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [statesPending, setStatesPending] = useState(false);
  const [citiesPending, setCitiesPending] = useState(false);
  const [saveAddress, setSaveAddress] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [savedAddresses, setSavedAddresses] = useState([]);
  const [addressPending, setAddressPending] = useState(false);

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      receiver_details: {
        ...formData.receiver_details,
        [name]: value,
      },
    });
  };

  const saveCustomerAddress = async () => {
    const data = {
      full_name: formData.receiver_details.fullname,
      address_1: formData.receiver_details.address_1,
      address_2: formData.receiver_details.address_2,
      country: formData.receiver_details.country,
      state: formData.receiver_details.state,
      city: formData.receiver_details.city,
      postcode: formData.receiver_details.postal_code,
      email_address: formData.receiver_details.email,
      phone_number: formData.receiver_details.phone,
    };

    const newData = { ...data, ...UserProfileData() };
    setSaveLoading(true);
    await axios
      .post(saveAddressUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSaveLoading(false);

        if (response["data"]["success"] === false) {
          toast.error(response["data"]["message"]);
          return;
        };

        toast.success(response["data"]["data"]);
        setSaveAddress(false);
        nextPage();
      });
  };

  const validatePage = () => {
    if (formData.receiver_details.fullname === "") {
      return true;
    } else if (formData.receiver_details.email === "") {
      return true;
    } else if (formData.receiver_details.phone === "") {
      return true;
    } else if (formData.receiver_details.address === "") {
      return true;
    } else if (formData.receiver_details.country === "") {
      return true;
    } else if (formData.receiver_details.state === "") {
      return true;
    } else if (formData.receiver_details.city === "") {
      return true;
    } else if (formData.receiver_details.postal_code === "") {
      return true;
    }

    return;
  };

  const shortenText = (text) => {
    if(text) {
      if(text.length > 12) {
        return `${text.slice(0, 12)}...`
      } else return text;
    } else {
      return text;
    }
  };

  const getAddressBook = async () => {
    const newData = { ...UserProfileData() };
    setAddressPending(true);

    await axios
      .post(getAddressBookUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setAddressPending(false);

        if (response["data"]["success"] === false) return;

        const addresses = response.data.data.map((address) => {
          return {
            ...address,
            label: `${address.full_name} - ${shortenText(address.address_1)}`,
            value: `${address.full_name} - ${shortenText(address.address_1)}`
          }
        });

        setSavedAddresses(addresses);
      });
  };

  const getStates = async () => {
    const data = {
      country: formData.receiver_details?.country,
    };

    const newData = { ...data, ...UserProfileData() };
    let country = formData.receiver_details?.country;
    setStatesPending(true);
    await axios
      .post(getStates2Url, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setStatesPending(false);

        if (response["data"]["success"] === false) return;

        let states = [];
        if(country === "Nigeria") {
          states = nigeriaStates?.data?.map((state) => {
            return {
              label: state?.name,
              value: state?.name
            }
          })
        } else {
          states = response?.data?.data?.map((state) => {
            return {
              label: state?.name,
              value: state?.name
            }
          });
        }
        setStateOptions(states);
      });
  };

  const getCities = async () => {
    const data = {
      country: formData.receiver_details?.country,
      state: formData?.receiver_details?.state,
    };

    const newData = { ...data, ...UserProfileData() };
    setCitiesPending(true);
    await axios
      .post(getCitiesByStateNameUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setCitiesPending(false);

        if (response["data"]["success"] === false) return;

        let cities = response?.data?.data?.map((city) => {
          return {
            label: city,
            value: city,
          };
        });
        setCitiesOptions(cities);
      });
  };

  useEffect(() => {
    getAddressBook();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getStates();
    // eslint-disable-next-line
  }, [formData?.receiver_details?.country]);

  useEffect(() => {
    getCities();
    //eslint-disable-next-line
  }, [formData?.receiver_details?.state]);

  const handleSelectTextbox = (name, value) => {
    setFormData({
      ...formData,
      receiver_details: {
        ...formData.receiver_details,
        [name]: value,
      },
    });
  };

  const handlePhoneInput = (code) => {
    setFormData({
      ...formData,
      receiver_details: {
        ...formData.receiver_details,
        phone: code
      }
    });
  };

  const handleSavedAddress = (e) => {
    const { name, value } = e.target;

    let selected_address = savedAddresses.find((address) => address.value === value);

    if(selected_address) {
      setFormData({
        ...formData,
        receiver_details: {
          ...formData.receiver_details,
          fullname: selected_address.full_name,
          email: selected_address.email_address,
          phone: selected_address.phone_number,
          address_1: selected_address.address_1,
          address_2: selected_address.address_2,
          country: selected_address.country,
          state: selected_address.state,
          city: selected_address.city,
          postal_code: selected_address.postcode,
          [name]: value,
        },
      });
    }
  };

  const handleReceiverText = (customer) => {
    let fullname = customer.full_name;
    let email = customer.email_address;
    let phone = customer.phone_number;
    let address_1 = customer.address_1;
    let address_2 = customer.address_2;
    let state = customer.state;
    let country = customer.country;
    let city = customer.city;
    let postal_code = customer.postcode;

    let updated_info = {
      fullname,
      email,
      phone,
      address_1,
      address_2,
      state,
      country,
      city,
      postal_code
    };

    setFormData({
      ...formData,
      receiver_details: {
        ...formData.receiver_details,
        ...updated_info
      },
    });
  };

  const setReceiverText = (value) => {
    setFormData({
      ...formData,
      receiver_details: {
        ...formData.receiver_details,
        fullname: value
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if(saveAddress) {
      saveCustomerAddress();
    } else {
      nextPage();
    }
  };

  return (
    <div className="create-shipment-grid">
      <div className="create-shipment-info">
        <img src={ReceiverIcon} alt="icon" />
        <div>
          <div className="create-shipment-info-top">
            <p className="create-shipment-info-title">Receiver Details</p>
            <p className="create-shipment-info-sub">
              Who is receiving this package and where are they located?
            </p>
          </div>
          {/* <div>
            <p>Saved Customers</p>
            <div className="create-shipment-info-form-row-input">
              <SelectBox
                name="selected_address"
                value={formData.receiver_details.selected_address}
                onChange={handleSavedAddress}
                disabled={addressPending}
                placeholder="Select saved address"
                options={savedAddresses}
              />
            </div>
          </div> */}
        </div>
      </div>
      <div className="create-shipment-info-form">
        <Row className="create-shipment-info-form-row w-100">
          <Col sm={12} lg={12} className="create-shipment-info-form-row-input">
            <label htmlFor="first_name">Fullname</label>
            {/* <TextBox
              name="fullname"
              value={formData.receiver_details.fullname}
              onChange={handleInput}
              placeholder="Receiver's's full name"
            /> */}
            <SelectContactTextBox 
              name="fullname"
              value={formData.receiver_details.fullname}
              onChange={handleReceiverText}
              handleText={setReceiverText}
              placeholder="Receiver's's full name"
            />
          </Col>
          <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
            <label htmlFor="email">Email Address</label>
            <TextBox
              name="email"
              type="email"
              value={formData.receiver_details.email}
              onChange={handleInput}
              placeholder="Receiver's email"
            />
          </Col>
          <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
            <label htmlFor="phone">Phone Number</label>
            <PhoneFieldBox
              value={formData.receiver_details.phone}
              onChange={handlePhoneInput}
            />
          </Col>
          <Col sm={12} className="create-shipment-info-form-row-input">
            <label htmlFor="address">Address [Dropoff]</label>
            <TextBox
              name="address_1"
              value={formData.receiver_details.address_1}
              onChange={handleInput}
              placeholder="Receiver's address [dropoff]"
            />
          </Col>
          <Col sm={12} className="create-shipment-info-form-row-input">
            <label htmlFor="address">Address 2 [Dropoff] (Optional)</label>
            <TextBox
              name="address_2"
              value={formData.receiver_details.address_2}
              onChange={handleInput}
              placeholder="Receiver's address 2 [dropoff]"
            />
          </Col>
          <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
            <label htmlFor="country">Country</label>
            <SelectTextBox
              name="country"
              value={formData.receiver_details.country}
              onChange={handleSelectTextbox}
              placeholder="Select available countries"
              options={countries}
            />
          </Col>
          <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
            <label htmlFor="state">State</label>
            <div className="d-flex gap-1">
              {statesPending && (
                <span className="spinner-border spinner-grow-sm" />
              )}
              <SelectTextBox
                name="state"
                value={formData.receiver_details.state}
                onChange={handleSelectTextbox}
                placeholder="Select available states"
                options={statesOptions}
              />
            </div>
          </Col>
          <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
            <label htmlFor="city">City</label>
            <div className="d-flex gap-1">
              {citiesPending && (
                <span className="spinner-border spinner-grow-sm" />
              )}
              <SelectTextBox
                name="city"
                value={formData.receiver_details.city}
                onChange={handleSelectTextbox}
                placeholder="Select available cities"
                options={citiesOptions}
              />
            </div>
          </Col>
          <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
            <label htmlFor="postal_code">Postal Code</label>
            <TextBox
              name="postal_code"
              value={formData.receiver_details.postal_code}
              onChange={handleInput}
              placeholder="Receiver's postal code"
            />
          </Col>
          <Col sm={12} className="create-shipment-info-form-row-input">
            <div className="confirm-summary-term d-flex gap-2 align-items-center">
              <input
                type="checkbox"
                checked={saveAddress}
                onChange={() => setSaveAddress(!saveAddress)}
                name=""
                id=""
              />
              <p className="m-0" >Save Receiver Info</p>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-end gap-2">
          <button
            className="create-shipment-info-form-btn-secondary"
            onClick={previousPage}
          >
            Previous
          </button>
          <button
            className="create-shipment-info-form-btn"
            onClick={handleSubmit}
            disabled={validatePage()}
          >
            {
              saveLoading ? (
                <span className="spinner-border spinner-grow-sm" />
              ) : "Proceed"
            }
          </button>
        </div>
      </div>
    </div>
  );
};

export default Receiver;
