import "../styles/home.scss";
import "../styles/shipments.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useState, useEffect, useRef } from "react";
import { UserProfileData } from "../library/constants";
import {
  getAdditionalServiceUrl,
} from "../library/URLs";
import axios from "axios";
import styled from "styled-components";
import * as React from "react";
import SubscriptionInactive from "../components/SubscriptionInactive";
import { BsDot, BsPencil, BsThreeDotsVertical, BsTrash } from "react-icons/bs";
import DeletePackageDialog from "../modals/DeletePackageDialog";
import Package from "../assets/packages/featured_seasonal_and_gifts.svg";
import { SearchBox } from "../components/InputFields";
import Tooltip from "@mui/material/Tooltip";
import AddServicesDialog from "../modals/AddServicesDialog";
import EditServiceDialog from "../modals/EditServiceDialog";
import DeleteServiceDialog from "../modals/DeleteServiceDialog";
import EmptyScreen from "../components/EmptyScreen";

function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const EditBtn = styled.button`
  width: 44px;
  height: 44px;
  border-radius: 100%;
  border: none;
  color: #98A2B3;
  font-size: 14px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #E3EFFC;
    color: #0d5eba;
  }
`;

const DeleteBtn = styled.button`
  width: 44px;
  height: 44px;
  border-radius: 100%;
  border: none;
  color: #98A2B3;
  font-size: 14px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #FBEAE9;
    color: #CB1A14;
  }
`;

const Services = () => {
  const form = useRef(null);

  const [chosenDate, setChosenDate] = useState("");

  const [addServices, setAddServices] = useState(false);
  const [deleteService, setDeleteService] = useState(null);
  const [editService, setEditService] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [currentOffsetDate, setCurrentOffsetDate] = useState("");
  const [currentOffsetID, setCurrentOffsetID] = useState(0);
  const [isRetrievingMore, setIsRetrievingMore] = useState(false);

  const [isCustomDateRangeDialogVisible, setIsCustomDateRangeDialogVisible] =
    useState(false);

  const [shipments, setShipments] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [query, setQuery] = useState("");

  // advance search fields

  const data = {
    limit: 20,
    offset_date: currentOffsetDate,
    offset_id: currentOffsetID,
    date: chosenDate,
  };

  const newData = { ...data, ...UserProfileData() };
  console.log(newData);

  const fetchAdditionalServices = () => {
    axios
      .post(getAdditionalServiceUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // if (isLoading === false && isRetrievingMore === false) {
        //     console.log("Should not retrieve");
        //     return;
        // }
        console.log(response["data"]);

        setIsRetrievingMore(false);
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        const newShipments = response["data"]["data"];

        // if (newShipments.length > 0) {
        //     const offsetID = newShipments[newShipments.length - 1].id;
        //     const offsetDate = newShipments[newShipments.length - 1].date_time;

        //     setCurrentOffsetID(offsetID);
        //     setCurrentOffsetDate(offsetDate);
        // }

        setShipments(newShipments);
        setFiltered(newShipments);
      });
  };

  useEffect(() => {
    fetchAdditionalServices();
  }, [isRetrievingMore]);

  // toggle selected shipments

  const setScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      isRetrievingMore === false
    ) {
      if (isRetrievingMore === false) {
        console.log("It's receiving");
        setIsRetrievingMore(true);
      } else {
        console.log("It's not receiving");
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", setScroll);
    return () => {
      window.removeEventListener("scroll", setScroll);
    };
    // eslint-disable-next-line
  }, []);

  const handleQueryChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    setFiltered(
      shipments?.filter((ship) =>
        ship?.service_name?.toLowerCase()?.includes(value?.toLowerCase())
      )
    );
  };

  const shortenTexts = (text) => {
    if (text) {
      if (text?.length > 28) {
        return `${text?.slice(0, 28)}...`;
      } else {
        return text;
      }
    }
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <AddServicesDialog
          isModalVisible={addServices}
          handleCloseDialog={() => setAddServices(false)}
          onSubmit={fetchAdditionalServices}
        />
        <DeleteServiceDialog
          isModalVisible={deleteService !== null}
          handleCloseDialog={() => setDeleteService(null)}
          serviceID={deleteService}
          updateServices={fetchAdditionalServices}
        />
        <EditServiceDialog
          isModalVisible={editService !== null}
          handleCloseDialog={() => setEditService(null)}
          onSubmit={fetchAdditionalServices}
          serviceDet={editService}
        />
        <div className="page pb-5">
          <SubscriptionInactive />

          <div className="container-fluid shipments-container">

            <div
              className="card border-0 p-4 min-vh-100"
              style={{ backgroundColor: "#FFF" }}
            >
              <div className="d-flex flex-row justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={Package} alt="package" />
                  <h5 className="m-0">Charged Services</h5>
                </div>
                <div className="d-flex flex-column flex-md-row gap-3">
                  <div
                    className="d-flex flex align-items-center"
                  >
                    <form
                      className="query-result-container"
                      ref={form}
                    >
                      <SearchBox
                        onChange={handleQueryChange}
                        name="query"
                        value={query}
                        placeholder="Search using service name"
                      />
                    </form>
                  </div>

                  <input
                    className="btn-primary"
                    type="button"
                    onClick={() => setAddServices(true)}
                    value="Add New Service"
                  />
                </div>
              </div>

              <div className="table-contain w-100">
                <table className="shipments-table w-100">
                  <thead>
                    <tr>
                      <th scope="col">Service Name</th>
                      <th scope="col">Description</th>
                      <th scope="col">For Interstate</th>
                      <th scope="col">For International</th>
                      <th scope="col">Fee</th>
                      <th scope="col">
                        <BsThreeDotsVertical />
                      </th>
                    </tr>
                  </thead>
                  {isLoading === true ? null : (
                    <tbody>
                      {filtered.map((serv) => {
                        return (
                          <tr key={serv.id}>
                            <td>{serv.service_name}</td>
                            <td>
                            <Tooltip
                                  title={`${serv?.description}`}
                                  placement="top"
                                ><span>{shortenTexts(serv.description)}</span>
                                </Tooltip></td>
                            <td>{serv.show_interstate}</td>
                            <td>{serv.show_international}</td>
                            <td>&#8358;{parseFloat(serv?.fee)?.toLocaleString("en-US")}</td>
                            <td className="d-flex gap-1">

                              <EditBtn
                                onClick={() => setEditService(serv)}
                              >
                                <BsPencil />
                              </EditBtn>
                              <DeleteBtn
                                onClick={() => setDeleteService(serv.id)}
                              >
                                <BsTrash />
                              </DeleteBtn>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
                {shipments.length > 1 && filtered.length < 1 && (
                  <EmptyScreen message={"No service found"} />
                )}
                {shipments.length < 1 && (
                  <EmptyScreen
                    message={"No service created yet!"}
                    action={() => setAddServices(true)}
                    actionText={"Add Service"}
                  />
                )}
              </div>
              {isRetrievingMore === false ? null : (
                <div style={{ textAlign: "center", marginTop: 40 }}>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />{" "}
                  Retrieving More..
                </div>
              )}

              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
