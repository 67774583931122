

export const MenuDropdown = ({ toggledropdown, children }) => {

  return (
    <div className="position-relative" onClick={toggledropdown} >
      {children}
    </div>
  )
}

export const MenuOption = ({ open, toggledropdown, top="35px", left="-150px", children }) => {

  return (
    <div>
      {
        open && (
          <div className='toggle-menu-overlay' onClick={toggledropdown} ></div>
        )
      }
      <ul 
        className={`toggle-menu-lists ${open ? "toggle-menu-list-open" : "toggle-menu-list-closed"}`} 
        style={{left: left, top: top}}
      >
        {children}
      </ul>
    </div>
  )
}

export const MenuDropdownItem = ({ onClick=()=>{}, children }) => {
  return (
    <li onClick={onClick} className='toggle-menu-list-item' >
      {children}
    </li>
  )
};