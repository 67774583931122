import { Col, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import {BiX} from "react-icons/bi";
import { useEffect, useState } from "react";
import { SelectBox, TextBox } from "../components/InputFields";
import axios from "axios";
import { getAPICarriersUrl } from "../library/URLs";
import { UserProfileData } from "../library/constants";
import { toast } from "react-toastify";

const SubmitButton = styled.button`
  width: 250px;
`;

const BatchApproveDialog = ({ isModalVisible, handleCloseDialog, approveBatch, isLoading }) => {
  const [trackInfo, setTrackInfo] = useState({
    tracking_number: "",
    tracking_number_api: ""
  });
  const [apiCarriers, setApiCarriers] = useState([]);

  const handleInput = (e) => {
    const { name, value } = e.target;

    setTrackInfo({
      ...trackInfo,
      [name]: value
    });
  };

  useEffect(() => {
    fetchAPICarriers();
  }, [])

  const fetchAPICarriers = async () => {
    await axios.post(getAPICarriersUrl, { ...UserProfileData() })
    .then((response) => {
      if(response["data"]["success"] === false) {
        toast.error("An issue occured getting api carriers");
        return;
      }

      let api_carriers = response.data.data.map(carrier => {return {
        label: carrier.name,
        value: carrier.id
      }});
      
      api_carriers.push(
        {label: "Custom", value: "Custom"}
      );
      setApiCarriers(api_carriers);
    })
  };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 style={{marginTop:10}} className="header">APPROVE BATCH</h5>
          <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
      </Modal.Header>
      <Modal.Body>
          <p>You are about to approve this batch</p>
          <p>This means you have confirmed every shipment within the batch.</p>
          <p>Please provide tracking number and batch carrier</p>

          <Row className="gap-3 gy-3 mt-3 gap-md-0">
            <Col className=" col-12 col-md-6">
              <label htmlFor="Description">Tracking Number</label>
              <TextBox
                name="tracking_number"
                className="form-control"
                placeholder="Enter tracking number"
                value={trackInfo.tracking_number}
                onChange={handleInput}
              />
            </Col>
            <Col className=" col-12 col-md-6">
              <label htmlFor="Description">Batch Carrier</label>
              <SelectBox
                name="tracking_number_api"
                className="form-control"
                placeholder="Select carrier"
                value={trackInfo.tracking_number_api}
                onChange={handleInput}
                options={apiCarriers}
              />
            </Col>
          </Row>
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
          <SubmitButton onClick={()=>approveBatch(trackInfo)} type="submit" className="btn-primary">
              {isLoading === false ? (
                  <span className="button-text">Proceed</span>
              ) : (
                  <span className="spinner-border spinner-grow-sm"/>
              )}
          </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default BatchApproveDialog;
