import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { getAllCarrierCountriesUrl, getCitiesByStateNameUrl, getStates2Url, updateAddressUrl } from "../library/URLs";
import { useEffect, useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import { PhoneFieldBox, SelectTextBox, TextBox } from "../components/InputFields";
import { BsSdCard, BsX } from "react-icons/bs";
import nigeriaStates from "../files/nigeriaStates.json";
import { toast } from "react-toastify";

const SubmitButton = styled.button`
`;

const EditAddressDialog = ({
  isModalVisible,
  handleCloseDialog,
  onSubmit,
  address,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statesOptions, setStateOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [statesPending, setStatesPending] = useState(false);
  const [citiesPending, setCitiesPending] = useState(false);
  const [countries, setCountries] = useState([]);

  const [formData, setFormData] = useState({
    full_name: "",
    email_address: "",
    phone_number: "",
    address_1: "",
    address_2: "",
    country: "",
    state: "",
    city: "",
    postcode: ""
  });
  
  useEffect(() => {
    if(address) {
      setFormData({ ...address });
    };

    // eslint-disable-next-line
  }, [isModalVisible])

  const updateAddress = async () => {

    const formValues = {
      ...formData,
      address_id: formData?.id
    };

    const newData = { ...formValues, ...UserProfileData() };
    setIsSubmitting(true);
    await axios
      .post(updateAddressUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {

        setIsSubmitting(false);
        if (response["data"]["success"] === false) {
          toast.error(response["data"]["message"]);
          return;
        };

        toast.success(response["data"]["data"]);
        setTimeout(() => {
          handleCloseDialog();
          onSubmit();
        }, 2000)
      });
  };

  const getCountries = async () => {

    const newData = { ...UserProfileData() };
    await axios
      .post(getAllCarrierCountriesUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {

        if (response["data"]["success"] === false) return;

        let countries = response?.data?.data?.map((cntry) => {
          return {
            label: cntry?.country,
            value: cntry?.country,
            code: cntry?.iso_code
          };
        });

        setCountries(countries);
      });
  };

  const getStates = async () => {
    const data = {
      country: formData?.country,
    };

    const newData = { ...data, ...UserProfileData() };
    let country = formData?.country;
    setStatesPending(true);
    await axios
      .post(getStates2Url, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setStatesPending(false);

        if (response["data"]["success"] === false) return;

        let states = [];
        if (country === "Nigeria") {
          states = nigeriaStates?.data?.map((state) => {
            return {
              label: state?.name,
              value: state?.name,
            };
          });
        } else {
          states = response?.data?.data?.map((state) => {
            return {
              label: state?.name,
              value: state?.name,
            };
          });
        }
        setStateOptions(states);
      });
  };

  const getCities = async () => {
    const data = {
      country: formData?.country,
      state: formData?.state,
    };

    const newData = { ...data, ...UserProfileData() };
    setCitiesPending(true);
    await axios
      .post(getCitiesByStateNameUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setCitiesPending(false);

        if (response["data"]["success"] === false) return;

        let cities = response?.data?.data?.map((city) => {
          return {
            label: city,
            value: city,
          };
        });
        setCitiesOptions(cities);
      });
  };

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getStates();
    // eslint-disable-next-line
  }, [formData?.country]);

  useEffect(() => {
    getCities();
    //eslint-disable-next-line
  }, [formData?.state]);

  const handleInput = (e) => {
    const { value, name } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneInput = (code) => {
    setFormData({
      ...formData,
      phone_number: code
    });
  };

  const handleSelectTextbox = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Modal show={isModalVisible} size="lg" onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">ADD ADDRESS INFO</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>

        <p>Add address information to book shipment.</p>
        <div className="query-result-container">
        <Row className="create-shipment-info-form-row w-100 gy-3">
          <Col sm={12} lg={12} className="create-shipment-info-form-row-input">
            <label htmlFor="first_name">Fullname</label>
            <TextBox
              name="full_name"
              value={formData.full_name}
              onChange={handleInput}
              placeholder="Customer's full name"
            />
          </Col>
          <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
            <label htmlFor="email">Email Address</label>
            <TextBox
              name="email_address"
              type="email"
              value={formData.email_address}
              onChange={handleInput}
              placeholder="Customer's email"
            />
          </Col>
          <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
            <label htmlFor="phone">Phone Number</label>
            <PhoneFieldBox
              value={formData.phone_number}
              onChange={handlePhoneInput}
            />
          </Col>
          <Col sm={12} className="create-shipment-info-form-row-input">
            <label htmlFor="address">Address 1</label>
            <TextBox
              name="address_1"
              value={formData.address_1}
              onChange={handleInput}
              placeholder="Customer's address"
            />
          </Col>
          <Col sm={12} className="create-shipment-info-form-row-input">
            <label htmlFor="address">Address 2 (Optional)</label>
            <TextBox
              name="address_2"
              value={formData.address_2}
              onChange={handleInput}
              placeholder="Customer's address 2"
            />
          </Col>
          <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
            <label htmlFor="country">Country</label>
            <SelectTextBox
              name="country"
              value={formData.country}
              onChange={handleSelectTextbox}
              placeholder="Select available countries"
              options={countries}
            />
          </Col>
          <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
            <label htmlFor="state">State</label>
            <div className="d-flex gap-1">
              {statesPending && (
                <span className="spinner-border spinner-grow-sm" />
              )}
              <SelectTextBox
                name="state"
                value={formData.state}
                onChange={handleSelectTextbox}
                placeholder="Select available states"
                options={statesOptions}
              />
            </div>
          </Col>
          <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
            <label htmlFor="city">City</label>
            <div className="d-flex gap-1">
              {citiesPending && (
                <span className="spinner-border spinner-grow-sm" />
              )}
              <SelectTextBox
                name="city"
                value={formData.city}
                onChange={handleSelectTextbox}
                placeholder="Select available cities"
                options={citiesOptions}
              />
            </div>
          </Col>
          <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
            <label htmlFor="postal_code">Postal Code</label>
            <TextBox
              name="postcode"
              value={formData.postcode}
              onChange={handleInput}
              placeholder="Customer's postal code"
            />
          </Col>
        </Row>
        </div>
        <p className="mt-3">Would you like to continue?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary mx-3" onClick={handleCloseDialog}>
          <BsX />
          Cancel
        </button>
        <SubmitButton
          onClick={updateAddress}
          type="submit"
          className="confirm-button btn-primary"
        >
          {isSubmitting === false ? (
            <span className="button-text d-flex align-items-center gap-2">
              <BsSdCard /> Update Address
            </span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default EditAddressDialog;
