import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import { UserProfileData } from "../library/constants";
import { useEffect, useState } from "react";
import axios from "axios";
import { getInsuranceFeeUrl, insureShipmentUrl } from "../library/URLs";
import { toast } from "react-toastify";

const SubmitButton = styled.button`
  width: 250px;
`;

const InsureShipmentDialog = ({
  isModalVisible,
  handleCloseDialog,
  shipment_id,
  shipment_type,
  item_value,
  sender_country="",
  reloadShipment,
  currency,
  waybillURL
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [insureLoading, setInsureLoading] = useState(false);
  const [insureFee, setInsureFee] = useState();

  const getInsuranceFee = () => {
    const formValues = {
      shipment_type: shipment_type,
      item_value: item_value,
      sender_country: sender_country,
      currency
    };

    const newData = { ...UserProfileData(), ...formValues };
    setInsureLoading(true);

    axios
      .post(getInsuranceFeeUrl, newData)
      .then(function (response) {
        setInsureLoading(false);
        if (response["data"]["success"] === true) {
          setInsureFee(response["data"]["data"]);
        } else {
          toast.error(response["data"]["message"]);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const purchaseShipmentInsurance = (e) => {
    e.preventDefault();

    const formValues = {
      ...UserProfileData(),
      shipment_id,
      currency,
      waybill_url: waybillURL
    };

    setIsLoading(true);

    axios
      .post(insureShipmentUrl, formValues)
      .then(function (response) {
        setIsLoading(false);
        if (response["data"]["success"] === true) {
          toast.success("Shipment successfully insured");

          setTimeout(() => {
            handleCloseDialog();
            reloadShipment();
          }, 2000);
        } else {
          toast.error(response["data"]["message"]);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  useEffect(() => {
    if(shipment_type) {
      getInsuranceFee();
    }
    // eslint-disable-next-line
  }, [isModalVisible]);

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 style={{ marginTop: 10 }} className="header">
          INSURE SHIPMENT
        </h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {insureLoading ? (
          <div className="py-5 d-flex justify-content-center">
            <span className="spinner-border spinner-grow-sm" />
          </div>
        ) : (
          <div>
            <p>
              Insuring this shipment is going to cost a total of &#8358;{" "}
              {parseFloat(`${insureFee}`).toLocaleString(
                "en-US"
              )}
            </p>
            <p>You are about to insure this shipment</p>
            <p>Would you like to proceed?</p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
        <SubmitButton
          disabled={isLoading || insureLoading}
          onClick={purchaseShipmentInsurance}
          type="submit"
          className="btn-primary"
        >
          {isLoading === false ? (
            <span className="button-text">Proceed</span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default InsureShipmentDialog;
