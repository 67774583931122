import { Modal } from "react-bootstrap";
import styled from "styled-components";
import {BiX} from "react-icons/bi";
import * as React from "react";

const SubmitButton = styled.button`
  width: 250px;
`;

const CancelBatchDialog = ({ isModalVisible, handleCloseDialog, cancelBatch, isLoading }) => {

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 style={{marginTop:10}} className="header">CANCEL BATCH</h5>
          <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
      </Modal.Header>
      <Modal.Body>
          <p>You are about to cancel this batch</p>
          <p>Would you like to proceed?</p>

      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
          <SubmitButton onClick={cancelBatch} type="submit" className="btn-primary">
              {isLoading === false ? (
                  <span className="button-text">Proceed</span>
              ) : (
                  <span className="spinner-border spinner-grow-sm"/>
              )}
          </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelBatchDialog;
