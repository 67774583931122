import * as React from "react";
import styled from "styled-components";
import axios from "axios";
import { toast } from "react-toastify";
import { generateInvoiceUrl } from "../../library/URLs";
import { UserProfileData } from "../../library/constants";
import { useParams } from "react-router-dom";
import ProcessorIcon from "../../assets/single-shipment/bar_chart.svg";
import { ImSpinner11 } from "react-icons/im";
import Tooltip from "@mui/material/Tooltip";
import { BsBoxSeam, BsFileText } from "react-icons/bs";

const DHLShipmentContainer = ({
  processor,
  dhlProcessed,
  waybillURL,
  trackingNumber,
  invoiceURL,
  status,
  trackingNumbers,
}) => {
  return (
    <div className="col-12">
      <div className="section-wrap-secondary">
        <div className="top-banner">
          <img src={ProcessorIcon} alt="icon" />
          <p className="m-0">Processor Shipment Details</p>
        </div>
        <div className="shipper-container">
          <div style={{ width: "100%" }}>
            {status !== "Pending Approval" ? (
              <Processed
                trackingNumber={trackingNumber}
                waybillURL={waybillURL}
                invoiceURL={invoiceURL}
                status={status}
                trackingNumbers={trackingNumbers}
              />
            ) : (
              <NotProcessed>
                <p className="m-4">
                  Oops... Sorry, you are yet to process this shipment
                </p>
              </NotProcessed>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const NotProcessed = styled.div`
  height: 365px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconText = styled.div`
  font-size: 14px;
  color: #008561;
`;

export const Processed = ({
  waybillURL,
  trackingNumber,
  invoiceURL,
  status,
  trackingNumbers,
}) => {
  const params = useParams();
  const shipmentID = params["shipmentID"];
  const [loading, setLoading] = React.useState(false);
  const [subPdf, setSubPdf] = React.useState(null);

  // Function to clean up the URL
  const cleanUrl = (url) => {
    const redundantPart =
      "https://parcelflow-user-backend-7948506154d4.herokuapp.com//";
    return url.replace(redundantPart, "");
  };

  // Function to handle the download
  // const downloadPdf = async (pdfUrl) => {
  //   const response = await fetch(pdfUrl, { responseType: 'blob' });

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }

  //     const blob = await response.blob();
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', "invoice" || 'downloaded.pdf'); // Set default filename if not provided

  //     document.body.appendChild(link);
  //     link.click();

  //     document.body.removeChild(link);
  //     window.URL.revokeObjectURL(url);
  // };

  const generateInvoice = () => {
    let info = {
      ...UserProfileData(),
    };
    let newData = { ...info, shipment_id: shipmentID };
    setLoading(true);
    axios
      .post(generateInvoiceUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        if (response["data"]["success"] === false) {
          setLoading(false);
          return;
        }

        let cleanedUrl = cleanUrl(response["data"]);

        setSubPdf(cleanedUrl);

        toast.success("Invoice generated! Click link to open");
        setLoading(false);
      });
  };

  const downloadFile = (url) => {
    const headers = {
      "Content-Type": "application/pdf",
    };
    console.log("hello");
    // url = "https://admin.parcelflow.io/engine/v1/actions/get-shipment-invoice.php"
    axios({
      url: url, //your url
      method: "GET",
      headers: headers, // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "file.pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });

    // return false;
  };

  return (
    <ul>
      <li className="">
        <div>
          <span className="info-label">Invoice</span>
        </div>
        <div className="d-flex gap-3">
          <a
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noreferrer"
            href={subPdf ? subPdf : invoiceURL}
          >
            <button className="btn-redirect" type="button">
              View Invoice
            </button>
          </a>{" "}
          <Tooltip title={`Re-Generate Invoice`} placement="top">
            <button
              style={{ padding: "4px 16px" }}
              onClick={generateInvoice}
              className="btn-primary"
            >
              {loading ? (
                <span className="spinner-border spinner-grow-sm" />
              ) : (
                <span className="d-flex align-items-center gap-2">
                  <ImSpinner11 style={{ fontSize: 10 }} />
                </span>
              )}
            </button>
          </Tooltip>
        </div>
      </li>

      {trackingNumbers?.map((t_number, id) => (
        <ul className="pt-0 mt-0" key={t_number.id}>
          <li>
            <div>
              <span className="info-label">Tracking Number</span>
            </div>
            <div className="mb-3">
              {!t_number?.tracking_number ? null : (
                <span className="d-flex align-items-center gap-2">
                  <p className="m-0">{t_number?.tracking_number}</p>
                </span>
              )}
            </div>
          </li>
          <li style={{borderBottom: trackingNumbers?.length !== (id+1) && "1px solid #E4E7EC"}} >
            <div>
              <span className="info-label">Waybill</span>
            </div>
            <div>
              {status === "Cancelled" ? (
                <p>Shipment has been cancelled and waybill can't be viewed</p>
              ) : !t_number?.waybill_document_url ? null : (
                <span className="d-flex align-items-center gap-2">
                  <p className="m-0">
                    <a
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      href={t_number?.waybill_document_url}
                      rel="noreferrer"
                    >
                      <button className="btn-redirect" type="button">
                        View Waybill
                      </button>
                    </a>
                  </p>
                </span>
              )}
            </div>
          </li>
          <li style={{borderBottom: trackingNumbers?.length !== (id+1) && "1px solid #E4E7EC"}} >
            <div>
              <span className="info-label">Processor Invoice</span>
            </div>
            <div>
              {status === "Cancelled" ? (
                <p>Shipment has been cancelled and waybill can't be viewed</p>
              ) : !t_number?.invoice_document_url ? null : (
                <span className="d-flex align-items-center gap-2">
                  <p className="m-0">
                    <a
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      href={t_number?.invoice_document_url}
                      rel="noreferrer"
                    >
                      <button className="btn-redirect" type="button">
                        View Invoice
                      </button>
                    </a>
                  </p>
                </span>
              )}
            </div>
          </li>
        </ul>
      ))}
    </ul>
  );
};

export default DHLShipmentContainer;
