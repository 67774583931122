export function generateTransactionID(length = 15) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function customerAddress(address_1, address_2, city, state, country, postcode) {
  let customer_address = "";
  if(address_1) {
    customer_address = customer_address + `${address_1}`
  }

  if(address_2) {
    customer_address = customer_address + `, ${address_2}`
  }

  if(city) {
    customer_address = customer_address + `, ${city}`
  }

  if(state) {
    customer_address = customer_address + `, ${state}`
  }

  if(country) {
    customer_address = customer_address + `, ${country}`
  }

  if(postcode) {
    customer_address = customer_address + `, ${postcode}`
  }

  return customer_address;
}