import React, { useEffect, useState } from "react";
import { getTerminalAccountsUrl } from "../../../../library/URLs";
import axios from "axios";
import { UserProfileData } from "../../../../library/constants";
import { TbPencil, TbTrash } from "react-icons/tb";
import { Tooltip } from "@mui/material";
import styled from "styled-components";
import { BsCheckCircleFill, BsPlusCircleFill } from "react-icons/bs";
import AddTerminalAccountDialog from "../../../../modals/AddTerminalAccountDialog";
import EditTerminalAccountDialog from "../../../../modals/EditTerminalAccountDialog";
import DeleteTerminalAccountDialog from "../../../../modals/DeleteTerminalAccountDialog";

const LoadingContainer = styled.div`
  height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const Terminal = ({ opened }) => {
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addTerminalAccount, setAddTerminalAccount] = useState(false);
  const [editTerminalAccount, setEditTerminalAccount] = useState(null);
  const [deleteTerminalAccount, setDeleteTerminalAccount] = useState(null);

  const openAddTerminalAccount = () => {
    setAddTerminalAccount(true);
  };

  const closeAddTerminalAccount = () => {
    setAddTerminalAccount(false);
  };

  const openEditTerminalAccount = (account) => {
    setEditTerminalAccount(account);
  };

  const closeEditTerminalAccount = () => {
    setEditTerminalAccount(null);
  };

  const openDeleteTerminalAccount = (id) => {
    setDeleteTerminalAccount(id);
  };

  const closeDeleteTerminalAccount = () => {
    setDeleteTerminalAccount(null);
  };

  const fetchAccounts = async () => {
    setIsLoading(true);
    await axios
      .post(getTerminalAccountsUrl, UserProfileData())
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.success) {
          let terminal_accounts = res?.data?.data;

          setAccounts(terminal_accounts);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (opened) {
      fetchAccounts();
    }
  }, [opened]);

  return (
    <div>
      <AddTerminalAccountDialog 
        isModalVisible={addTerminalAccount}
        handleCloseDialog={closeAddTerminalAccount}
        onSubmit={fetchAccounts}
      />
      <EditTerminalAccountDialog 
        isModalVisible={editTerminalAccount !== null}
        handleCloseDialog={closeEditTerminalAccount}
        onSubmit={fetchAccounts}
        editAccount={editTerminalAccount}
      />
      <DeleteTerminalAccountDialog 
        isModalVisible={deleteTerminalAccount !== null}
        handleCloseDialog={closeDeleteTerminalAccount}
        onSubmit={fetchAccounts}
        accountID={deleteTerminalAccount}
      />
      {isLoading ? (
        <LoadingContainer>
          <LoadingSpinner className="spinner-border spinner-grow-sm" />
        </LoadingContainer>
      ) : (
        <ul className="p-0" style={{listStyle: "none"}} >
          {accounts?.length < 1 ? (
            <li className="mb-4" >
              No account available
            </li>
          ) : accounts?.map((terminal) => (
            <li
              key={terminal?.id}
              className="d-flex justify-content-between mb-4 align-items-center"
            >
              <label className="form-check-label d-flex gap-4 align-items-center" htmlFor="">
                {terminal?.name}
                {
                  terminal?.is_primary_account === "Yes" && (
                    <BsCheckCircleFill style={{color: "#0F973D", fontSize: 12}} />
                  )
                }
              </label>
              <div className="d-flex gap-4 align-items-center">
                <Tooltip title="Edit Account" placement="top">
                  <span className="d-flex align-items-start">
                    <TbPencil
                      style={{ fontSize: 18, cursor: "pointer" }}
                      onClick={() => openEditTerminalAccount(terminal)}
                    />
                  </span>
                </Tooltip>
                <Tooltip title="Delete Account" placement="top">
                  <span className="d-flex align-items-start">
                    <TbTrash
                      style={{ fontSize: 18, cursor: "pointer" }}
                      onClick={() => openDeleteTerminalAccount(terminal?.id)}
                    />
                  </span>
                </Tooltip>
              </div>
            </li>
          ))}
          <button
            className="mt-2 add-carrier"
            type="button"
            onClick={openAddTerminalAccount}
          >
            <BsPlusCircleFill /> Add Account
          </button>
        </ul>
      )}
    </div>
  );
};

export default Terminal;