import { useEffect, useState } from "react";
import axios from "axios";
import { getAllAdminsURL, getAllTransportersURL } from "../../library/URLs";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import * as React from "react";
import { UserProfileData } from "../../library/constants";
import styled from "styled-components";
import * as BsIcon from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import SubscriptionInactive from "../../components/SubscriptionInactive";
import { FaTruck } from "react-icons/fa";
import "../../styles/transporters.scss";
import LoadingScreen from "../../components/LoadingScreen";
import EmptyScreen from "../../components/EmptyScreen";

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 65px;
  height: 65px;
  cursor: pointer;
`;

const Transporters = () => {
  const navigator = useNavigate();

  const [profileTabsNumber, setProfileTabsNumber] = useState(3);
  const [transporters, setTransporters] = useState([]);
  const [isRetrieving, setIsRetrieving] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [userActionMenuVisibility, setUserActionMenuVisibility] =
    useState(false);

  const toggleUserActionMenu1 = () => {
    setUserActionMenuVisibility(!userActionMenuVisibility);
  };

  const data = {
    limit: 6,
    offset: 0,
  };

  const newData = { ...data, ...UserProfileData() };

  useEffect(() => {
    setIsRetrieving(true);
    axios
      .post(getAllTransportersURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsRetrieving(false);

        if (response["data"]["success"] === false) return;

        const transporters = response["data"]["data"];

        if (transporters.length === 0) setIsEmpty(true);

        setTransporters(transporters);
      });
    //eslint-disable-next-line
  }, []);

  const goToSingleAdmin = (transporterID) => {
    navigator("/transporter/" + transporterID);
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        {/*{isError === true ? (*/}
        {/*    <ErrorNotification message={errorMessage}>*/}
        {/*        This is a notification*/}
        {/*    </ErrorNotification>*/}
        {/*) : null}*/}

        {/*{isSuccess === true ? (*/}
        {/*    <SuccessNotification message={successMessage}>*/}
        {/*        This is a notification*/}
        {/*    </SuccessNotification>*/}
        {/*) : null}*/}
        <div className="page pb-5">
          <SubscriptionInactive />

          {/*<MakeContractUserDialog*/}
          {/*    isModalVisible={isContractUserModalVisible}*/}
          {/*    handleCloseDialog={handleCloseModal}*/}
          {/*    customerID={customerID}*/}
          {/*    getContractDetails={true}*/}
          {/*/>*/}

          {/*<MakeRegularUserDialog*/}
          {/*    isModalVisible={isRegularUserModalVisible}*/}
          {/*    handleCloseDialog={handleCloseModal}*/}
          {/*    customerID={customerID}*/}
          {/*/>*/}

          <div className="container-fluid shipments-container">
            <div
              className="card border-0 p-4 min-vh-100"
              style={{ backgroundColor: "#FFF" }}
            >
              <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  {/* <img src={AdminIcon} alt="group" /> */}
                  <FaTruck style={{ fontSize: 24, color: "#98a2b3" }} />
                  <h5 className="m-0">Transporters</h5>
                </div>
                <div className="d-flex flex-column flex-md-row gap-3">
                  <button
                    onClick={() => navigator("/transporter/create-transporter")}
                    style={{ fontSize: 12 }}
                    className=" btn-primary"
                  >
                    Create Transporter
                  </button>
                </div>
              </div>
              <div>
                <div className="row mt-4 pr-none transporters">
                  {isRetrieving ? (
                    <LoadingScreen />
                  ) : (
                    transporters.map((admin) => {
                      return (
                        <div
                          key={admin.id}
                          className="col-xl-3 col-md-6 mb-3 mr-3 pr-none"
                        >
                          <div className="transporter-card">
                            <Link to={`/transporter/${admin.id}`}>
                              <div
                                style={{ padding: "32px 0", cursor: "pointer" }}
                                className="card-body shipper-container"
                              >
                                <div className="d-flex flex-column align-items-center">
                                  <ProfileImage
                                    onClick={(e) => goToSingleAdmin(admin.id)}
                                    src={admin.rider_profile_image_url}
                                  />
                                  <div
                                    style={{
                                      textAlign: "center",
                                      marginTop: 20,
                                    }}
                                  >
                                    <span className="title">{admin.name}</span>
                                    <p>{admin.phone}</p>
                                  </div>
                                </div>
                              </div>
                            </Link>
                            {/* <div className="card-footer bg-light d-flex justify-content-around">
                            <div>
                              <a href={"/transporter/" + admin.id}>Profile</a>
                            </div>
                            <div>
                              <a href={"/transporter/" + admin.id}>
                                Activities
                              </a>
                            </div>
                          </div> */}
                          </div>
                        </div>
                      );
                    })
                  )}
                  {
                  transporters.length < 1 && !isRetrieving && (
                    <EmptyScreen
                      message={"No transporter registered!"}
                      action={() => navigator("/transporter/create-transporter")}
                      actionText={"Register Transporter"}
                    />
                  )
                }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transporters;
