export const weightSummation = (arr) => {
  const totalSum = arr.reduce((sum, current) => {
    const weight = Number(current.weight);
    return sum + (isNaN(weight) ? 0 : weight);
  }, 0);

  return Math.round(totalSum * 100) / 100;
};

export const valueSummation = (arr) => {
  return arr.reduce((sum, current) => {
    const value = Number(current.value);
    const quantity = Number(current.quantity);
    return sum + (isNaN(value) || isNaN(quantity) ? 0 : value * quantity);
  }, 0);
};

export function getTotalValue(items) {
  let totalValue = 0;
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const quantity = parseFloat(item.quantity);
    const value = parseFloat(item.value);
    totalValue += quantity * value;
  }
  return totalValue;
}

const colorCodes = [
  // "#FBE2B7",
  // "#F2BCBA",
  // "#FCD2C2",
  // "#F0E6E6",
  // "#C6DDF7",
  // "#F7D394",
  "#139C5F",
];

export const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * colorCodes.length);
  return colorCodes[randomIndex];
};

export const getInitials = (input) => {
  if (!input) return ""; // Handle empty input

  const conjunctions = ["and", "of"];
  const words = input
    .split(" ")
    .filter((word) => word && !conjunctions.includes(word.toLowerCase()));

  let initials = "";

  for (let i = 0; i < words.length && initials.length < 2; i++) {
    initials += words[i][0]?.toUpperCase() || ""; // Safeguard against undefined
  }

  return initials;
};

export const descriptionExerpt = (text) => {
  if (text.length <= 35) {
    return text;
  } else {
    return `${text.slice(0, 35)}...`;
  }
};

export const fetchAllPackageItems = (packages) => {
  let allPackageItems = [];

  // Outer loop to iterate through the varObj array
  for (let i = 0; i < packages.length; i++) {
    // Inner loop to iterate through the sports array for each object
    for (let j = 0; j < packages[i].package_items.length; j++) {
      // Push each sport object into the allSports array
      allPackageItems.push(packages[i].package_items[j]);
    }
  }

  return allPackageItems;
};

export const fetchPackageTotalWeight = (packages) => {
  let packageWeight = packages?.reduce((accumulator, currentObject) => {
    return accumulator + parseFloat(currentObject?.package_weight);
  }, 0);

  return packageWeight;
};

export const fetchPackageTotalValue = (packages) => {
  let packageValue = packages?.reduce((accumulator, currentObject) => {
    return accumulator + parseFloat(currentObject?.package_value);
  }, 0);

  return packageValue;
};

export const transformPackageItemArrayData = (data) => {
  const groupedData = data.reduce((acc, item) => {
    const { package: pkg, weight, value } = item;

    // Initialize group if it doesn't exist
    if (!acc[pkg]) {
      acc[pkg] = {
        number_of_items: 0,
        package_value: 0,
        package_weight: 0,
        package_length: item.package_length,
        package_width: item.package_width,
        package_height: item.package_height,
        description: "Package",
        package_items: [],
      };
    }

    // Update group
    acc[pkg].number_of_items += 1; // Count the number of items
    acc[pkg].package_weight += parseFloat(weight); // Sum weight
    acc[pkg].package_value += parseFloat(value); // Sum value
    acc[pkg].package_items.push({
      category: item?.name,
      name: item?.name,
      description: item?.description,
      weight: item?.weight,
      quantity: item?.quantity,
      value: item?.value,
      value_currency: item?.value_currency,
      hs_code: item?.hs_code,
      image_url: item?.image_url,
      image_name: item?.name
    }); // Add the item details

    return acc;
  }, {});

  // Convert grouped object to an array
  return Object.values(groupedData);
};
