import "../styles/home.scss";
import "../styles/single-shipment.scss";
import "../styles/components.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import SubscriptionInactive from "../components/SubscriptionInactive";
import CreateStationForm from "./CreateStationForm";
import StationsIcon from "../assets/sidebar/Stations.svg";

const CreateStation = () => {
  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />
          <div className="container-fluid">
            <div className="card border-0 px-3 px-md-4 py-5" style={{background: "FFF"}}>
              <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={StationsIcon} alt="stations" />
                  <h5 className="m-0">Create Station</h5>
                </div>
                <div className="d-flex flex-column flex-md-row gap-3">
                </div>
              </div>
                <CreateStationForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateStation;
