import React, { useEffect, useState } from "react";
import { getTopshipAccountsUrl } from "../../../../library/URLs";
import axios from "axios";
import { UserProfileData } from "../../../../library/constants";
import { TbPencil, TbTrash } from "react-icons/tb";
import { Tooltip } from "@mui/material";
import styled from "styled-components";
import { BsCheckCircleFill, BsPlusCircleFill } from "react-icons/bs";
import AddTopshipAccountDialog from "../../../../modals/AddTopshipAccountDialog";
import EditTopshipAccountDialog from "../../../../modals/EditTopshipAccountDialog";
import DeleteTopshipAccountDialog from "../../../../modals/DeleteTopshipAccountDialog";

const LoadingContainer = styled.div`
  height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const Topship = ({ opened }) => {
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addTopshipAccount, setAddTopshipAccount] = useState(false);
  const [editTopshipAccount, setEditTopshipAccount] = useState(null);
  const [deleteTopshipAccount, setDeleteTopshipAccount] = useState(null);

  const openAddTopshipAccount = () => {
    setAddTopshipAccount(true);
  };

  const closeAddTopshipAccount = () => {
    setAddTopshipAccount(false);
  };

  const openEditTopshipAccount = (account) => {
    setEditTopshipAccount(account);
  };

  const closeEditTopshipAccount = () => {
    setEditTopshipAccount(null);
  };

  const openDeleteTopshipAccount = (id) => {
    setDeleteTopshipAccount(id);
  };

  const closeDeleteTopshipAccount = () => {
    setDeleteTopshipAccount(null);
  };

  const fetchAccounts = async () => {
    setIsLoading(true);
    await axios
      .post(getTopshipAccountsUrl, UserProfileData())
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.success) {
          let topship_accounts = res?.data?.data;

          setAccounts(topship_accounts);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (opened) {
      fetchAccounts();
    }
  }, [opened]);

  return (
    <div>
      <AddTopshipAccountDialog 
        isModalVisible={addTopshipAccount}
        handleCloseDialog={closeAddTopshipAccount}
        onSubmit={fetchAccounts}
      />
      <EditTopshipAccountDialog 
        isModalVisible={editTopshipAccount !== null}
        handleCloseDialog={closeEditTopshipAccount}
        onSubmit={fetchAccounts}
        editAccount={editTopshipAccount}
      />
      <DeleteTopshipAccountDialog 
        isModalVisible={deleteTopshipAccount !== null}
        handleCloseDialog={closeDeleteTopshipAccount}
        onSubmit={fetchAccounts}
        accountID={deleteTopshipAccount}
      />
      {isLoading ? (
        <LoadingContainer>
          <LoadingSpinner className="spinner-border spinner-grow-sm" />
        </LoadingContainer>
      ) : (
        <ul className="p-0" style={{listStyle: "none"}} >
          {accounts?.length < 1 ? (
            <li className="mb-4" >
              No account available
            </li>
          ) : accounts?.map((topship) => (
            <li
              key={topship?.id}
              className="d-flex justify-content-between mb-4 align-items-center"
            >
              <label className="form-check-label d-flex gap-4 align-items-center" htmlFor="">
                {topship?.name}
                {
                  topship?.is_primary_account === "Yes" && (
                    <BsCheckCircleFill style={{color: "#0F973D", fontSize: 12}} />
                  )
                }
              </label>
              <div className="d-flex gap-4 align-items-center">
                <Tooltip title="Edit Account" placement="top">
                  <span className="d-flex align-items-start">
                    <TbPencil
                      style={{ fontSize: 18, cursor: "pointer" }}
                      onClick={() => openEditTopshipAccount(topship)}
                    />
                  </span>
                </Tooltip>
                <Tooltip title="Delete Account" placement="top">
                  <span className="d-flex align-items-start">
                    <TbTrash
                      style={{ fontSize: 18, cursor: "pointer" }}
                      onClick={() => openDeleteTopshipAccount(topship?.id)}
                    />
                  </span>
                </Tooltip>
              </div>
            </li>
          ))}
          <button
            className="mt-2 add-carrier"
            type="button"
            onClick={openAddTopshipAccount}
          >
            <BsPlusCircleFill /> Add Account
          </button>
        </ul>
      )}
    </div>
  );
};

export default Topship;