import "../styles/home.scss";
import "../styles/create-shipment.scss";
import "../styles/components.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useEffect, useState } from "react";
import SubscriptionInactive from "../components/SubscriptionInactive";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import CreateShipIcon from "../assets/create-shipment/monitor_weight_gain.svg";
import LocalIcon from "../assets/create-shipment/local.svg";
import LocalActiveIcon from "../assets/create-shipment/local-active.svg";
import InterstateIcon from "../assets/create-shipment/interstate.svg";
import InterstateActiveIcon from "../assets/create-shipment/interstate-active.svg";
import InternationalIcon from "../assets/create-shipment/international.svg";
import InternationalActiveIcon from "../assets/create-shipment/international-active.svg";
import LocalShipment from "./CreateShipmentForms/Local";
import InterstateShipment from "./CreateShipmentForms/Interstate";
import InternationalShipment from "./CreateShipmentForms/International";
import { useSearchParams } from "react-router-dom";
import { getShipmentDetailsURL, getShipmentPackagesURL } from "../library/URLs";
import axios from "axios";
import { UserProfileData } from "../library/constants";
import LoadingScreen from "../components/LoadingScreen";
import { transformPackageItemArrayData } from "./CreateShipmentForms/Utilities";

const CreateShipment = () => {
  const [searchParam] = useSearchParams();
  const shipmentID = searchParam.get("shipmentID");

  // State management
  const [shipmentType, setShipmentType] = useState("local");

  const [localShipment, setLocalShipment] = useState(null);
  const [interstateShipment, setInterstateShipment] = useState(null);
  const [internationalShipment, setInternationalShipment] = useState(null);
  const [shipmentLoading, setShipmentLoading] = useState(false);
  const [packageItems, setPackageItems] = useState([]);
  // End state management

  // Handle API calls

  const fetchShipmentInfo = () => {
    const data = {
      shipment_id: shipmentID,
    };

    const newData = { ...data, ...UserProfileData() };
    setShipmentLoading(true);

    axios
      .post(getShipmentDetailsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        setShipmentLoading(false);
        if (response["data"]["success"] === false) return;

        const shipmentDetails = response["data"];
        const shipmentType = shipmentDetails["data"]["shipment_type"];
        const shipmentNumber = shipmentDetails["data"]["shipment_no"];

        if (shipmentType === "Local") {
          setLocalShipment(shipmentDetails["data"]);
          setShipmentType("local");
        } else if (shipmentType === "Interstate") {
          // setInterstateShipment(shipmentDetails["data"]);
          fetchShipmentPackages(shipmentNumber, shipmentDetails["data"], shipmentType);
          setShipmentType("interstate");
        } else if (
          shipmentType === "International" ||
          shipmentType === "Import"
        ) {
          fetchShipmentPackages(shipmentNumber, shipmentDetails["data"], shipmentType);
          setShipmentType("international");
        }
      });
  };

  const fetchShipmentPackages = (shipment_number, shipmentInfo, shipment_type) => {
    const data = {
      shipment_number,
    };

    const newData = { ...data, ...UserProfileData() };

    axios
      .post(getShipmentPackagesURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response["data"]["success"] === false) return;

        let transformed_data = transformPackageItemArrayData(response?.data);
        let shipment_info = {
          ...shipmentInfo,
          packages: transformed_data
        }
        if(shipment_type === "International" || shipment_type === "Import") {
          setInternationalShipment(shipment_info);
        } else {
          setInterstateShipment(shipment_info);
        }
        // setPackageItems(transformed_data);
      });
  };

  // End handle API calls

  // Handle side effect

  useEffect(() => {
    if (shipmentID) {
      fetchShipmentInfo();
    }
    // eslint-disable-next-line
  }, [shipmentID]);

  // End handle side effect

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />
          <div className="container-fluid create-shipment-container">
            <div className="navigation-contain">
              <button className="back-btn">
                <BsChevronLeft />
              </button>
              <ul className="nav-options">
                <li>Shipments</li>
                <li className="nav-options-arrow">
                  <BsChevronRight />{" "}
                </li>
                <li>Create Shipments</li>
                <li className="nav-options-arrow">
                  <BsChevronRight />{" "}
                </li>
                <li className="nav-options-active">
                  {shipmentType === "local"
                    ? "Intracity Shipment"
                    : shipmentType === "interstate"
                    ? "Interstate Shipment"
                    : shipmentType === "international"
                    ? "International Shipment"
                    : ""}
                </li>
              </ul>
            </div>

            <div className="create-shipment-wrapper">
              <div
                className="create-shipment-title"
                style={{ fontWeight: 500 }}
              >
                <img src={CreateShipIcon} alt="icon" className="mt-2" />
                Create Shipment
              </div>
              <ul className="create-shipment-tab">
                <li
                  onClick={() => setShipmentType("local")}
                  className={`create-shipment-tab-option ${
                    shipmentType === "local" &&
                    "create-shipment-tab-option-active"
                  }`}
                >
                  {shipmentType === "local" ? (
                    <img src={LocalActiveIcon} alt="local" />
                  ) : (
                    <img src={LocalIcon} alt="local" />
                  )}
                  Intracity Shipment
                </li>
                <li
                  onClick={() => setShipmentType("interstate")}
                  className={`create-shipment-tab-option ${
                    shipmentType === "interstate" &&
                    "create-shipment-tab-option-active"
                  }`}
                >
                  {shipmentType === "interstate" ? (
                    <img src={InterstateActiveIcon} alt="interstate" />
                  ) : (
                    <img src={InterstateIcon} alt="interstate" />
                  )}
                  Interstate Shipment
                </li>
                <li
                  onClick={() => setShipmentType("international")}
                  className={`create-shipment-tab-option ${
                    shipmentType === "international" &&
                    "create-shipment-tab-option-active"
                  }`}
                >
                  {shipmentType === "international" ? (
                    <img src={InternationalActiveIcon} alt="international" />
                  ) : (
                    <img src={InternationalIcon} alt="international" />
                  )}
                  International Shipment
                </li>
              </ul>
              {shipmentLoading ? (
                <LoadingScreen />
              ) : (
                <div className="shipment-form-contain">
                  {shipmentType === "local" ? (
                    <LocalShipment shipmentInfo={localShipment} />
                  ) : shipmentType === "interstate" ? (
                    <InterstateShipment
                      shipmentInfo={interstateShipment}
                    />
                  ) : shipmentType === "international" ? (
                    <InternationalShipment
                      shipmentInfo={internationalShipment}
                    />
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateShipment;
