import "../styles/home.scss";
import "../styles/customers.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useEffect, useRef, useState } from "react";
import { UserProfileData } from "../library/constants";
import axios from "axios";
import { getAddressBookUrl } from "../library/URLs";
import styled from "styled-components";
import * as React from "react";
import SubscriptionInactive from "../components/SubscriptionInactive";
import { SearchBox } from "../components/InputFields";
import Group from "../assets/customers/group.svg";
import EmptyScreen from "../components/EmptyScreen";
import { customerAddress } from "../library/utilities";
import Tooltip from "@mui/material/Tooltip";
import { BsPencil, BsThreeDotsVertical, BsTrash } from "react-icons/bs";
import AddAddressDialog from "../modals/AddAddressDialog";
import EditAddressDialog from "../modals/EditAddressDialog";
import DeleteAddressDialog from "../modals/DeleteAddressDialog";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const EditBtn = styled.button`
  width: 44px;
  height: 44px;
  border-radius: 100%;
  border: none;
  color: #98a2b3;
  font-size: 14px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #e3effc;
    color: #0d5eba;
  }
`;

const DeleteBtn = styled.button`
  width: 44px;
  height: 44px;
  border-radius: 100%;
  border: none;
  color: #98a2b3;
  font-size: 14px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #fbeae9;
    color: #cb1a14;
  }
`;

const AddressBook = () => {
  const form = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [addressBook, setAddressBook] = useState([]);
  const [query, setQuery] = useState("");
  const [filteredAddress, setFilteredAddress] = useState([]);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [showUpdateAddress, setShowUpdateAddress] = useState(null);
  const [showDeleteAddress, setShowDeleteAddress] = useState(null);

  const getAddressBook = async () => {
    const newData = { ...UserProfileData() };
    setIsLoading(true);

    await axios
      .post(getAddressBookUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        const addresses = response["data"]["data"];

        setAddressBook(addresses);
        setFilteredAddress(addresses);
      });
  };

  useEffect(() => {
    getAddressBook();

    // eslint-disable-next-line
  }, []);

  const searchForCustomers = (e) => {
    e.preventDefault();
  };

  const handleQueryChange = (e) => {
    const { value } = e.target;

    let filtered_query = addressBook.filter(
      (address) =>
        address.address_1?.toLowerCase()?.includes(value?.toLowerCase()) ||
        address.full_name?.toLowerCase()?.includes(value?.toLowerCase())
    );
    setQuery(value);
    setFilteredAddress(filtered_query);
  };

  const shortenTexts = (text) => {
    if (text) {
      if (text?.length > 36) {
        return `${text?.slice(0, 36)}...`;
      } else {
        return text;
      }
    }
  };

  const openAddAddress = () => {
    setShowAddAddress(true);
  };

  const closeAddAddress = () => {
    setShowAddAddress(false);
  };

  const openUpdateAddress = (address) => {
    setShowUpdateAddress(address);
  };

  const closeUpdateAddress = () => {
    setShowUpdateAddress(null);
  };

  const openDeleteAddress = (id) => {
    setShowDeleteAddress(id)
  };

  const closeDeleteAddress = () => {
    setShowDeleteAddress(null);
  };

  return (
    <div className="home">
      <AddAddressDialog 
        isModalVisible={showAddAddress}
        handleCloseDialog={closeAddAddress}
        onSubmit={getAddressBook}
      />
      <EditAddressDialog 
        isModalVisible={showUpdateAddress !== null}
        handleCloseDialog={closeUpdateAddress}
        onSubmit={getAddressBook}
        address={showUpdateAddress}
      />
      <DeleteAddressDialog 
        isModalVisible={showDeleteAddress !== null}
        handleCloseDialog={closeDeleteAddress}
        onSubmit={getAddressBook}
        addressID={showDeleteAddress}
      />
      <Sidebar />
      <AddAddressDialog />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />
          <div className="container-fluid shipments-container">
            <div
              className="card border-0 p-4 min-vh-100"
              style={{ backgroundColor: "#FFF" }}
            >
              <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={Group} alt="group" />
                  <h5 className="mb-2">Address Book</h5>
                </div>
                <div className="d-flex flex-column flex-md-row gap-3">
                  <form
                    className="query-result-container "
                    ref={form}
                    onSubmit={searchForCustomers}
                  >
                    <SearchBox
                      onChange={handleQueryChange}
                      name="query"
                      value={query}
                      placeholder="Search using fullname or address"
                    />
                  </form>

                  <button
                    className="confirm-button btn-primary"
                    type="button"
                    onClick={openAddAddress}
                  >
                    Add Address
                  </button>
                </div>
              </div>
              {/* <div className="d-flex justify-content-end mb-3">
                        
                    </div> */}
              <div className="table-contain w-100">
                <table className="shipments-table w-100">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col">Address</th>
                      <th scope="col"><BsThreeDotsVertical /></th>
                    </tr>
                  </thead>
                  {isLoading === true ? null : (
                    <tbody>
                      {filteredAddress.map((address) => {
                        let address_info = customerAddress(
                          address?.address_1,
                          address.address_2,
                          address.city,
                          address.state,
                          address.country,
                          address.postcode
                        );
                        return (
                          <tr key={address.id}>
                            <td>
                              <Tooltip
                                title={`${address.full_name}`}
                                placement="top"
                              >
                                <span>{address.full_name}</span>
                              </Tooltip>
                            </td>
                            <td>{address.email_address}</td>
                            <td>{address.phone_number}</td>
                            <td>
                              <Tooltip
                                title={`${address_info}`}
                                placement="top"
                              >
                                <span>{shortenTexts(address_info)}</span>
                              </Tooltip>
                            </td>
                            <td className="d-flex gap-1">
                              <EditBtn
                                onClick={() => openUpdateAddress(address)}
                              >
                                <BsPencil />
                              </EditBtn>
                              <DeleteBtn
                                onClick={() => openDeleteAddress(address.id)}
                              >
                                <BsTrash />
                              </DeleteBtn>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
                {addressBook.length < 1 && !isLoading && (
                  <EmptyScreen message={"No address info saved!"} />
                )}
              </div>

              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressBook;
