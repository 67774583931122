import React, { useEffect, useState } from "react";
import PaymentIcon from "../../../assets/create-shipment/payment-page.svg";
// import TransporterIcon from "../../../assets/create-shipment/transport.svg";
import CarrierIcon from "../../../assets/create-shipment/carrier.svg";
import {
  SelectBox,
  SelectCustomerTextBox,
  TextBox,
} from "../../../components/InputFields";
import { Col, Row } from "react-bootstrap";
import {
  calculateLocalShipmentUrl,
  getTransporterByStateUrl,
  getPaymentMethodsUrl,
  getStationsByStateUrl,
} from "../../../library/URLs";
import axios from "axios";
import { UserProfileData } from "../../../library/constants";
import { toast } from "react-toastify";
import { SessionManagement } from "../../../library/SessionManagement";

const Payment = ({
  nextPage,
  previousPage,
  formData,
  setFormData,
  setCarriers,
  pricingMethod,
}) => {
  const [transporters, setTransporters] = useState([]);
  const [stations, setStations] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isPending, setIsPending] = useState(false);

  const getRiders = async () => {
    await axios
      .post(getTransporterByStateUrl, {state: formData.shipment_state,...UserProfileData()})
      .then(function (response) {
        console.log(response);
        if (response["data"]["success"] === true) {
          const transporters = response["data"]["data"].map((transporter) => {
            return {
              label: transporter?.name,
              value: transporter?.id,
            };
          });

          setTransporters(transporters);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const getStations = async () => {
    await axios
      .post(getStationsByStateUrl, {state: formData.shipment_state,...UserProfileData()})
      .then(function (response) {
        console.log(response);
        if (response["data"]["success"] === true) {
          const stations = response["data"]["data"].map((station) => {
            return {
              label: station?.address,
              value: station?.id,
            };
          });

          setStations(stations);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const getPaymentMethods = async () => {
    await axios
      .post(getPaymentMethodsUrl, UserProfileData())
      .then(function (response) {
        console.log(response);
        if (response["data"]["success"] === true) {
          const payments = [];
          if (response["data"]["data"]["cash"] === "Yes") {
            payments.push({ label: "Cash", value: "Cash" });
          }

          if (response["data"]["data"]["bank_transfer"] === "Yes") {
            payments.push({ label: "Bank Transfer", value: "Bank Transfer" });
          }

          if (response["data"]["data"]["card_payment"] === "Yes") {
            payments.push({ label: "Card Payment", value: "Card Payment" });
          }

          if (response["data"]["data"]["wallet"] === "Yes") {
            payments.push({ label: "Wallet", value: "Wallet" });
          }

          setPaymentMethods(payments);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  useEffect(() => {
    getRiders();
    getStations();
    getPaymentMethods();
    // eslint-disable-next-line
  }, []);

  const handlePayment = (e) => {
    const { name, value } = e.target;

    if (name === "payment_method" && value === "Wallet") {
      setFormData({
        ...formData,
        [name]: value,
        use_customer_account: "Yes",
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // const payment_options = [
  //   { label: "Cash On Delivery", value: "Cash On Delivery" },
  //   { label: "Cash On Pickup", value: "Cash On Pickup" },
  //   { label: "Card Payment", value: "Card Payment" },
  //   { label: "Direct Transfer", value: "Direct Transfer" },
  //   { label: "Wallet", value: "Wallet" },
  // ];

  const vehicle_options = [
    { label: "Motorbike", value: "Motorbike" },
    { label: "Van", value: "Van" },
  ];

  const validatePage = () => {
    if (formData.payment_method === "") {
      return true;
    } else if (formData.transporter === "") {
      return true;
    } else if (formData.description === "") {
      return true;
    } else if (formData.shipping_date === "") {
      return true;
    } else if (formData.pickup_station === "") {
      return true;
    }

    return false;
  };

  const calculateLocalShipment = async () => {
    const data = {
      sender_name: formData.sender_details.fullname,
      sender_address: formData?.sender_details?.address,
      sender_email: formData.sender_details.email
      ? formData.sender_details.email
      : SessionManagement.getEmail(),
      sender_phone: formData.sender_details.phone,
      receiver_name: formData.receiver_details.fullname,
      receiver_address: formData?.receiver_details?.address,
      receiver_phone: formData.receiver_details.phone,
      receiver_email: formData?.receiver_details?.email
      ? formData?.receiver_details?.email
      : SessionManagement.getEmail(),
      state: formData?.shipment_state,
      item_value: formData?.item_Value,
      vehicle_type: formData.vehicle_type,
      weight: formData.item_weight,
      customer_id: formData.customer_wallet_id
    };

    const newData = { ...data, ...UserProfileData() };
    setIsPending(true);
    await axios
      .post(calculateLocalShipmentUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsPending(false);
        if (response["data"]["success"] === false) {
          toast.error(response["data"]["message"]);
          return;
        }

        setCarriers(response["data"]["data"]);
        nextPage();
      });
  };

  const handleSuggestedCustomers = (name, value, customers) => {
    setFormData({
      ...formData,
      [name]: value,
      suggestedCustomers: customers,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (transporters?.length > 0 || stations.length > 0) {
      setFormData({
        ...formData,
        transporters: transporters,
        stations: stations,
      });
    }

    if (pricingMethod === "Yes") {
      calculateLocalShipment();
    } else {
      nextPage();
    }
  };

  return (
    <div>
      <div className="create-shipment-grid">
        <div className="create-shipment-info">
          <img src={PaymentIcon} alt="icon" />
          <div>
            <div className="create-shipment-info-top">
              <p className="create-shipment-info-title">Payment Method</p>
              <p className="create-shipment-info-sub">
                What payment method should be assigned to this Shipment?
              </p>
            </div>
          </div>
        </div>
        <div className="create-shipment-info-form">
          <Row className="create-shipment-info-form-row">
            <Col sm={12} md={6} className="create-shipment-info-form-row-input">
              <label htmlFor="payment_method">Payment Method</label>
              <SelectBox
                name="payment_method"
                value={formData.payment_method}
                onChange={handlePayment}
                options={paymentMethods}
                placeholder="Select payment method"
              />
            </Col>

            <Col sm={12} md={6} className="create-shipment-info-form-row-input">
              <label htmlFor="use_customer_account">
                Are you booking for a customer?
              </label>
              <SelectBox
                name="use_customer_account"
                value={formData.use_customer_account}
                disabled={formData?.payment_method === "Wallet"}
                onChange={handlePayment}
                options={[
                  { label: "No", value: "No" },
                  { label: "Yes", value: "Yes" },
                ]}
                placeholder="Select option"
              />
            </Col>

            {(formData?.payment_method === "Wallet" ||
              formData?.use_customer_account === "Yes") && (
              <Col
                sm={12}
                lg={6}
                className="create-shipment-info-form-row-input"
              >
                <label htmlFor="pickup_station">Customer's Account</label>
                <SelectCustomerTextBox
                  name="customer_wallet_id"
                  value={formData.customer_wallet_id}
                  onChange={handleSuggestedCustomers}
                  suggestions={formData?.suggestedCustomers}
                  placeholder="Select customer wallet account"
                />
              </Col>
            )}
          </Row>
        </div>
      </div>
      <div className="create-shipment-grid">
        <div className="create-shipment-info">
          <img src={CarrierIcon} alt="icon" />
          <div>
            <div className="create-shipment-info-top">
              <p className="create-shipment-info-title">Transporter Details</p>
              <p className="create-shipment-info-sub">
                Who would you like to deliver this package?
              </p>
            </div>
          </div>
        </div>
        <div className="create-shipment-info-form">
          <Row className="create-shipment-info-form-row">
            <Col sm={12} md={6} className="create-shipment-info-form-row-input">
              <label htmlFor="payment_method">Package Weight (optional)</label>
              <TextBox
                name="item_weight"
                value={formData.item_weight}
                onChange={handlePayment}
                prefix="KG"
                placeholder="Enter package weight"
              />
            </Col>
            <Col sm={12} md={6} className="create-shipment-info-form-row-input">
              <label htmlFor="payment_method">Package Value (optional)</label>
              <TextBox
                name="item_value"
                value={formData.item_value}
                onChange={handlePayment}
                prefix="NGN"
                placeholder="Enter package value"
              />
            </Col>
            <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
              <label htmlFor="transporter">Transporter</label>
              <SelectBox
                name="transporter"
                value={formData.transporter}
                onChange={handlePayment}
                options={transporters}
                placeholder="Select transporter"
              />
            </Col>
            <Col sm={12} md={6} className="create-shipment-info-form-row-input">
              <label htmlFor="payment_method">Vehicle Type</label>
              <SelectBox
                name="vehicle_type"
                value={formData.vehicle_type}
                onChange={handlePayment}
                options={vehicle_options}
                placeholder="Select vehicle option"
              />
            </Col>
            <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
              <label htmlFor="pickup_station">Pickup Stations</label>
              <SelectBox
                name="pickup_station"
                value={formData.pickup_station}
                onChange={handlePayment}
                options={stations}
                placeholder="Select station"
              />
            </Col>
            <Col sm={12} md={6} className="create-shipment-info-form-row-input">
              <label htmlFor="payment_method">Pickup Date</label>
              <TextBox
                name="shipping_date"
                value={formData.shipping_date}
                onChange={handlePayment}
                placeholder="Enter pickup date"
                type="date"
              />
            </Col>
            <Col sm={12} className="create-shipment-info-form-row-input">
              <label htmlFor="payment_method">Description</label>
              <TextBox
                name="description"
                value={formData.description}
                onChange={handlePayment}
                placeholder="Enter package description"
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end gap-2">
            <button
              className="create-shipment-info-form-btn-secondary"
              onClick={previousPage}
            >
              Previous
            </button>
            <button
              className="create-shipment-info-form-btn"
              onClick={handleSubmit}
              disabled={validatePage()}
            >
              {isPending ? (
                <span className="spinner-border spinner-grow-sm" />
              ) : (
                "Proceed"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
