import "../../styles/home.scss";
import "../../styles/shipments.scss";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import { useState, useEffect } from "react";
import { UserProfileData } from "../../library/constants";
import {
  getPendingKycUrl,
} from "../../library/URLs";
import axios from "axios";
import styled from "styled-components";
import * as React from "react";
import SubscriptionInactive from "../../components/SubscriptionInactive";
import { BsThreeDotsVertical } from "react-icons/bs";
import ApproveDialog from "../../modals/AddPhone/kyc/ApproveDialog";
import RejectDialog from "../../modals/AddPhone/kyc/RejectDialog";
import { SearchBox, SelectBox } from "../../components/InputFields";
import EmptyScreen from "../../components/EmptyScreen";
import AdminIcon from "../../assets/admins/group.svg";

// function titleCase(str) {
//   var splitStr = str.toLowerCase().split(" ");
//   for (var i = 0; i < splitStr.length; i++) {
//     // You do not need to check if i is larger than splitStr length, as your for does that for you
//     // Assign it back to the array
//     splitStr[i] =
//       splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
//   }
//   // Directly return the joined string
//   return splitStr.join(" ");
// }

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const KycApprovals = () => {

  const [chosenPaymentStatus, setChosenPaymentStatus] = useState("");
  const [chosenDate, setChosenDate] = useState("");
  const [approveKyc, setApproveKyc] = useState(null);
  const [rejectKyc, setRejectKyc] = useState(null);
  const [queryData, setQueryData] = useState("");
  const [accountType, setAccountType] = useState("");

  const shipmentType = "all-shipments";

  const [isLoading, setIsLoading] = useState(true);
  const [currentOffsetDate, setCurrentOffsetDate] = useState("");
  const [currentOffsetID, setCurrentOffsetID] = useState(0);
  const [isRetrievingMore, setIsRetrievingMore] = useState(false);


  const [userKycs, setUserKycs] = useState([]);


  const data = {
    limit: 20,
    offset_date: currentOffsetDate,
    offset_id: currentOffsetID,
    status: shipmentType === "All Shipments" ? "All" : shipmentType,
    payment_status: chosenPaymentStatus,
    date: chosenDate,
  };

  const newData = { ...data, ...UserProfileData() };
  console.log(newData);

  const fetchKyc = () => {
    setIsLoading(true);
    axios
      .post(getPendingKycUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response["data"]);

        setIsRetrievingMore(false);
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        const newShipments = response["data"]["data"];

        setUserKycs(newShipments);
      });
  };

  useEffect(() => {
    fetchKyc();
    // eslint-disable-next-line
  }, []);

//   const setScroll = () => {
//     if (
//       window.innerHeight + window.scrollY >= document.body.offsetHeight &&
//       isRetrievingMore === false
//     ) {
//       if (isRetrievingMore === false) {
//         console.log("It's receiving");
//         setIsRetrievingMore(true);
//       } else {
//         console.log("It's not receiving");
//       }
//     }
//   };

//   React.useEffect(() => {
//     window.addEventListener("scroll", setScroll);
//     return () => {
//       window.removeEventListener("scroll", setScroll);
//     };
//   }, []);

  const filteredKyc = userKycs?.filter(
    (kyc) =>
      kyc?.fullname?.toLowerCase()?.includes(queryData?.toLowerCase()) &&
      kyc?.account_type?.toLowerCase()?.includes(accountType?.toLowerCase())
  );

  const account_type_options = [
    {label: "All Customers", value: "All"},
    {label: "Individual", value: "Individual"},
    {label: "Business", value: "Business"},
    {label: "3P Logistics", value: "3P Logistics"}
  ]

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <ApproveDialog
          isModalVisible={approveKyc !== null}
          handleCloseDialog={() => setApproveKyc(null)}
          userID={approveKyc}
          callback={fetchKyc}
        />
        <RejectDialog
          isModalVisible={rejectKyc !== null}
          handleCloseDialog={() => setRejectKyc(null)}
          userID={rejectKyc}
          callback={fetchKyc}
        />
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />
          <div className="container-fluid shipments-container">
            {/* <SearchShipmentsDialog
              isModalVisible={isSearchShipmentDialogVisible}
              handleCloseDialog={handleCloseSearchShipmentsDialog}
            /> */}

            <div className="card border-0 p-4 min-vh-100" style={{background: "#FFF"}} >
              <div className="d-flex justify-content-between mb-4">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={AdminIcon} alt="group" />
                  <h5 className="mb-1">Pending KYC</h5>
                </div>
                <div
                  className="d-md-flex align-items-center gap-md-2 gap-4 top-filter-section mb-3 mb-md-0"
                >
                  <div
                    className="query-result-container mb-2 mb-md-0 "
                    style={{ width: "100%" }}
                    // ref={form}
                    // onSubmit={searchForCustomers}
                  >
                    <SearchBox
                      onChange={(e) => setQueryData(e.target.value)}
                      name="queryData"
                      value={queryData}
                      type="text"
                      placeholder="Search using customer's name"
                    />
                    
                  </div>
                  <SelectBox 
                    onChange={(e) => setAccountType(e.target.value)}
                    name="accountType"
                    value={accountType}
                    style={{paddingTop: 6, paddingBottom: 6}}
                    placeholder="Select account type"
                    options={account_type_options}
                  />                  
                </div>
              </div>

              <div className="table-contain w-100">
                <table className="shipments-table w-100">
                  <thead>
                    <tr>
                      <th scope="col">Full Name</th>
                      <th scope="col">Account Type</th>
                      <th scope="col">Nin</th>
                      <th scope="col">CAC</th>
                      <th scope="col">Utility Bill</th>
                      <th scope="col">Identification</th>
                      <th scope="col"><BsThreeDotsVertical /> </th>
                    </tr>
                  </thead>
                  {isLoading === true ? null : (
                    <tbody>
                      {filteredKyc.map((kyc) => {
                        return (
                          <tr key={kyc.id}>
                            <td>{kyc.fullname}</td>
                            <td>{kyc.account_type}</td>
                            <td>
                              {kyc?.nin?.length > 2 ? (
                                <a
                                  href={kyc?.nin}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  View
                                </a>
                              ) : (
                                "Nil"
                              )}
                            </td>
                            <td>
                              {kyc?.cac_url?.length > 2 ? (
                                <a
                                  href={kyc?.cac_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  View
                                </a>
                              ) : (
                                "Nil"
                              )}
                            </td>
                            <td>
                              {kyc?.utility_bill_url?.length > 2 ? (
                                <a
                                  href={kyc?.utility_bill_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  View
                                </a>
                              ) : (
                                "Nil"
                              )}
                            </td>
                            <td>
                              {kyc?.identification_url?.length > 2 ? (
                                <a
                                  href={kyc?.identification_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  View
                                </a>
                              ) : (
                                "Nil"
                              )}
                            </td>
                            <td>
                              <div className="d-flex gap-2">
                                <span
                                  className="text-success"
                                  onClick={() => setApproveKyc(kyc?.id)}
                                >
                                  Approve
                                </span>
                                <span>.</span>
                                <span
                                  className="text-danger"
                                  onClick={() => setRejectKyc(kyc?.id)}
                                >
                                  Reject
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
              {
                filteredKyc.length < 1 && !isLoading && (
                  <EmptyScreen 
                    message={"No pending KYC approval"}
                  />
                )
              }

              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KycApprovals;
