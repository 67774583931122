import "../styles/home.scss";
import "../styles/single-admin.scss";
import "../styles/components.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getAdminDetailsURL,
  getCustomerDetailsURL,
  getTransporterDetailsURL,
} from "../library/URLs";
import axios from "axios";
import { UserProfileData } from "../library/constants";
import { HiOutlineDotsVertical } from "react-icons/hi";
import styled from "styled-components";
import * as React from "react";
import TopupUserWalletDialog from "../modals/TopupUserWallet";
import AdminActivities from "./Widgets/Admin/AdminActivities";
import { SessionManagement } from "../library/SessionManagement";
import SuspendAdminDialog from "../modals/SuspendAdminDialog";
import UnsuspendAdminDialog from "../modals/UnsuspendAdminDialog";
import DeleteAdminDialog from "../modals/DeleteAdminDialog";
import moment from "moment";
import SubscriptionInactive from "../components/SubscriptionInactive";
import DeleteTransporterDialog from "../modals/DeleteTransporterDialog";
import DeactivateTransporterDialog from "../modals/DeactivateTransporterDialog";
import ActivateTransporterDialog from "../modals/ActivateTransporterDialog";
import EditTransporterDialog from "../modals/EditTransporterDialog";
import ResetTransporterDialog from "../modals/ResetTransporterPasswordDialog";
import TransporterActivities from "./Widgets/Transporter/TransporterActivities";

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 65px;
  height: 65px;
`;

const CommunicationContainer = styled.div`
  padding: 4px 8px;
  border-radius: 5px;
  background-color: #e5e7e9;
  color: #2e4053;
  font-size: 12px;
  margin-right: 3px;
  margin-left: 3px;
  cursor: pointer;
`;

const SingleTransporter = () => {
  const params = useParams();
  const transporterID = params["transporterID"];

  const [isFundUserWalletModalVisible, setIsFundUserWalletModalVisibility] =
    useState(false);
  const [isDeductUserWalletModalVisible, setIsDeductUserWalletModalVisibility] =
    useState(false);
  const [isActivateTransporterModalVisible, setUnsuspendAdminModalVisibility] =
    useState(false);
  const [isDeactivateTransporterModalVisible, setSuspendAdminModalVisibility] =
    useState(false);
  const [isDeleteTransporterModalVisible, setDeleteTransporterModalVisibility] =
    useState(false);
  const [isEditTransporterModalVisible, setEditTransporterModalVisibility] =
    useState(false);
  const [
    isResetTransporterPasswordModalVisible,
    setResetTransporterPasswordModalVisibility,
  ] = useState(false);

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [username, setUsername] = useState("");
  const [memberType, setMemberType] = useState("");
  const [memberStatus, setMemberStatus] = useState("");
  const [state, setState] = useState("");

  const [profileImageURL, setProfileImageURL] = useState(
    "https://users.mylogisticsapp.com/engine/v1/actions/profiles/default-profile-image.png"
  );
  const [creationTimestamp, setCreationTimestamp] = useState("");
  const [lastActivityTimestamp, setLastActivityTimestamp] = useState("");

  const [adminActionMenuVisibility, setAdminActionMenuVisibility] =
    useState(false);

  const toggleUserActionMenu = () => {
    setAdminActionMenuVisibility(!adminActionMenuVisibility);
  };

  const activateTransporterModal = () => {
    setAdminActionMenuVisibility(!adminActionMenuVisibility);
    setUnsuspendAdminModalVisibility(!isActivateTransporterModalVisible);
  };

  const deactivateTransporterModal = () => {
    setAdminActionMenuVisibility(!adminActionMenuVisibility);
    setSuspendAdminModalVisibility(!isDeactivateTransporterModalVisible);
  };

  const deleteTransporterModal = () => {
    setAdminActionMenuVisibility(!adminActionMenuVisibility);
    setDeleteTransporterModalVisibility(!isDeleteTransporterModalVisible);
  };

  const editTransporterModal = () => {
    setAdminActionMenuVisibility(!adminActionMenuVisibility);
    setEditTransporterModalVisibility(!isEditTransporterModalVisible);
  };

  const resetTransporterPasswordModal = () => {
    setAdminActionMenuVisibility(!adminActionMenuVisibility);
    setResetTransporterPasswordModalVisibility(
      !isResetTransporterPasswordModalVisible
    );
  };

  const handleCloseModal = () => {
    setUnsuspendAdminModalVisibility(false);
    setSuspendAdminModalVisibility(false);
    setDeleteTransporterModalVisibility(false);
    setEditTransporterModalVisibility(false);
    setResetTransporterPasswordModalVisibility(false);
  };


  const closeDeleteDialog = (refresh) => {
    setDeleteTransporterModalVisibility(false);

    if (refresh === true) {
      window.location.href = "/transporters";
    }
  };

  const adminPrivileges = SessionManagement.getUserPrivileges();

  const data = {
    rider_id: transporterID,
  };

  const newData = { ...data, ...UserProfileData() };

  console.log(newData);

  useEffect(() => {
    axios
      .post(getTransporterDetailsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        if (response["data"]["success"] === false) return;

        const adminDetails = response["data"];

        const name = adminDetails["data"]["name"];
        const phoneNumber = adminDetails["data"]["phone"];
        const email = adminDetails["data"]["username"];
        const memberType = adminDetails["data"]["employee_type"];
        const memberStatus = adminDetails["data"]["status"];
        const profileImageURL = adminDetails["data"]["rider_profile_image_url"];
        const state = adminDetails["data"]["state"] ?? "";

        const creationTimestamp = new Date(
          adminDetails["data"]["date_time"]
        ).toDateString();
        const lastActivityTimestamp =
          adminDetails["data"]["last_seen_timestamp"] === undefined
            ? "Not Available"
            : moment(
                adminDetails["data"]["last_seen_timestamp"],
                "YYYY-MM-DD h:mm:ss"
              ).fromNow();

        setName(name);
        setPhoneNumber(phoneNumber);
        setUsername(email);
        setMemberType(memberType);
        setMemberStatus(memberStatus);
        setProfileImageURL(profileImageURL);
        setCreationTimestamp(creationTimestamp);
        setLastActivityTimestamp(lastActivityTimestamp);
        setState(state);
      });
  }, []);

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />

          <div className="d-flex flex-row justify-content-between mb-2 mx-3">
            <h5 className="header">Transporter Details</h5>
            <span>Home</span>
          </div>

          <DeleteTransporterDialog
            isModalVisible={isDeleteTransporterModalVisible}
            handleCloseDialog={closeDeleteDialog}
            transporterID={transporterID}
          />

          <ActivateTransporterDialog
            isModalVisible={isActivateTransporterModalVisible}
            handleCloseDialog={handleCloseModal}
            transporterID={transporterID}
          />

          <DeactivateTransporterDialog
            isModalVisible={isDeactivateTransporterModalVisible}
            handleCloseDialog={handleCloseModal}
            transporterID={transporterID}
          />

          <EditTransporterDialog
            isModalVisible={isEditTransporterModalVisible}
            handleCloseDialog={handleCloseModal}
            transporterID={transporterID}
            transporterName={name}
            transporterUsername={username}
            transporterPhoneNumber={phoneNumber}
            transporterState={state}
          />

          <ResetTransporterDialog
            isModalVisible={isResetTransporterPasswordModalVisible}
            handleCloseDialog={handleCloseModal}
            transporterID={transporterID}
          />

          <div className="container-fluid">
            <div className="row mt-4">
              <div className="col-xl-4 col-md-12">
                <div className="card bg-light border-0 shadow">
                  <div className="card-body shipper-container">
                    <div className="d-flex flex-column align-items-center">
                      <div
                        style={{ width: "100%" }}
                        className="d-flex justify-content-end"
                      >
                        <div className="user-menu-container">
                          {parseInt(
                            adminPrivileges["delete_employee"] ?? "0"
                          ) === 1 ||
                          parseInt(
                            adminPrivileges["suspend_employee"] ?? "0"
                          ) === 1 ||
                          parseInt(
                            adminPrivileges["unsuspend_employee"] ?? "0"
                          ) === 1 ? (
                            <HiOutlineDotsVertical
                              onClick={toggleUserActionMenu}
                              style={{ width: 25, cursor: "pointer" }}
                              className="icon"
                            />
                          ) : (
                            <div style={{ height: "25px" }} />
                          )}

                          <div
                            className={`user-menu ${
                              adminActionMenuVisibility
                                ? "user-menu-visible"
                                : null
                            }`}
                          >
                            {memberStatus === "suspended" ? (
                              parseInt(
                                adminPrivileges["activate_rider"] ?? "0"
                              ) === 1 ? (
                                <span onClick={activateTransporterModal}>
                                  Activate Transporter
                                </span>
                              ) : null
                            ) : parseInt(
                                adminPrivileges["deactivate_rider"] ?? "0"
                              ) === 1 ? (
                              <span onClick={deactivateTransporterModal}>
                                Deactivate Transporter
                              </span>
                            ) : null}
                            {parseInt(
                              adminPrivileges["delete_rider"] ?? "0"
                            ) === 1 ? (
                              <span onClick={() => deleteTransporterModal()}>
                                Delete Transporter
                              </span>
                            ) : null}
                            {parseInt(
                              adminPrivileges["change_rider_details"] ?? "0"
                            ) === 1 ? (
                              <span onClick={() => editTransporterModal()}>
                                Edit Transporter Details
                              </span>
                            ) : null}
                            {parseInt(
                              adminPrivileges["change_rider_details"] ?? "0"
                            ) === 1 ? (
                              <span
                                onClick={() => resetTransporterPasswordModal()}
                              >
                                Reset Password
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <ProfileImage src={profileImageURL} />
                      <div style={{ textAlign: "center" }}>
                        <span className="title">{name}</span>
                        <p>{memberType}</p>
                      </div>
                    </div>
                    <div className="mt-4">
                      <span className="title">Username</span>
                      <p>{username}</p>
                    </div>
                    <div className="mt-4">
                      <span className="title">Phone Number</span>
                      <p>{phoneNumber}</p>
                    </div>
                    <div className="mt-4">
                      <span className="title">State</span>
                      <p>{state}</p>
                    </div>
                    <div className="mt-4">
                      <span className="title">Creation Date</span>
                      <p>{creationTimestamp}</p>
                    </div>
                    <div className="mt-4">
                      <span className="title">Last Seen</span>
                      <p>{lastActivityTimestamp}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-8 col-md-12">
                <TransporterActivities
                  adminID={transporterID}
                  adminPrivileges={adminPrivileges}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleTransporter;
