import { Col, Modal, Row } from "react-bootstrap";
import { useRef, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import {
  getAllCarriersUrl,
  getAPICarriersUrl,
  getContractDetailsUserURL,
  makeContractUser2URL,
} from "../library/URLs";
import { UserProfileData } from "../library/constants";
import { BiTrash, BiX } from "react-icons/bi";
import * as React from "react";
import { SelectBox, TextBox } from "../components/InputFields";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const LoadingContainer = styled.div`
  height: 365px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

const AddBtn = styled.button`
  background: transparent;
  border: none;
  color: #1f7556;
  font-size: 0.8rem;
  margin-top: 0.6rem;
  margin-bottom: 0.4rem;
`;

const MakeContractUserDialog = ({
  isModalVisible,
  handleCloseDialog,
  customerID,
  getContractDetails,
}) => {
  const form = useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRetrieving, setIsRetrieving] = useState(getContractDetails);

  const [localShipmentIsChecked, setLocalShipmentIsChecked] = useState(false);
  const [interstateShipmentIsChecked, setInterstateShipmentIsChecked] =
    useState(false);
  const [internationalShipmentIsChecked, setInternationalShipmentIsChecked] =
    useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [localPricingType, setLocalPricingType] = useState("Standard Price");
  const [localPricingValue, setLocalPricingValue] = useState("");
  const [localPaymentPlan, setLocalPaymentPlan] = useState("No Payment Plan");
  const [localPricingList, setLocalPricingList] = useState([]);

  const [interstatePricingType, setInterstatePricingType] =
    useState("Standard Price");
  const [interstatePricingValue, setInterstatePricingValue] = useState("");
  const [interstatePaymentPlan, setInterstatePaymentPlan] =
    useState("No Payment Plan");
  const [interstateAccessWaybill, setInterstateAccessWaybill] = useState("No");
  const [interstatePricingList, setInterstatePricingList] = useState([]);

  const [internationalPricingType, setInternationalPricingType] =
    useState("Standard Price");
  const [internationalPricingValue, setInternationalPricingValue] =
    useState("");
  const [internationalPaymentPlan, setInternationalPaymentPlan] =
    useState("No Payment Plan");
  const [internationalPricingList, setInternationalPricingList] = useState([]);
  const [internationalAccessWaybill, setInternationalAccessWaybill] =
    useState("No");

  const [localPricingValueLabel, setLocalPricingValueLabel] = useState(
    "Standard Price For Local"
  );
  const [localPricingValueReadOnly, setLocalPricingValueReadOnly] =
    useState(true);

  const [interstatePricingValueLabel, setInterstatePricingValueLabel] =
    useState("Standard Price For Interstate");
  const [interstatePricingValueReadOnly, setInterstatePricingValueReadOnly] =
    useState(true);

  const [internationalPricingValueLabel, setInternationalPricingValueLabel] =
    useState("Standard Price For Int'l");
  const [
    internationalPricingValueReadOnly,
    setInternationalPricingValueReadOnly,
  ] = useState(true);

  const [intracityCarriers, setIntracityCarriers] = useState([]);
  const [interstateCarriers, setInterstateCarriers] = useState([]);
  const [internationalCarriers, setInternationalCarriers] = useState([]);
  // const [importCarriers, setImportCarriers] = useState([]);

  const fetchAPICarriers = async () => {
    await axios
      .post(getAllCarriersUrl, UserProfileData())
      .then((res) => {
        if (res?.data?.success) {
          let intracity = res?.data?.data?.filter(
            (carrier) => carrier?.shipment_type === "Local"
          )?.map(carrier => {return {
            label: carrier?.masked_name,
            value: carrier?.id
          }});
          let interstate = res?.data?.data?.filter(
            (carrier) => carrier?.shipment_type === "Interstate"
          )?.map(carrier => {return {
            label: carrier?.masked_name,
            value: carrier?.id
          }});
          let international = res?.data?.data?.filter(
            (carrier) => carrier?.shipment_type === "International"
          )?.map(carrier => {return {
            label: carrier?.masked_name,
            value: carrier?.id
          }});
          // let import_carriers = res?.data?.data?.filter(
          //   (carrier) => carrier?.shipment_type === "Import"
          // );

          setIntracityCarriers(intracity);
          setInterstateCarriers(interstate);
          setInternationalCarriers(international);
          // setImportCarriers(import_carriers);
        }
      })
      .catch((err) => {});
  };

  const toggleLocalShipment = () => {
    setLocalShipmentIsChecked(!localShipmentIsChecked);
    if (localPricingList.length < 1) {
      addLocalPricing();
    }
  };

  const toggleInterstateShipment = () => {
    setInterstateShipmentIsChecked(!interstateShipmentIsChecked);
    if (interstatePricingList.length < 1) {
      addInterstatePricing();
    }
  };

  const toggleInternationalShipment = () => {
    setInternationalShipmentIsChecked(!internationalShipmentIsChecked);
    if (internationalPricingList.length < 1) {
      addInternationalPricing();
    }
  };

  const makeContractUser = (e) => {
    e.preventDefault();

    const updatedLocal =
      localPricingList?.length > 0 &&
      localPricingList?.map((item) => {
        return { ...item, payment_plan: localPaymentPlan };
      });

    const updatedInterstate =
      interstatePricingList?.length > 0 &&
      interstatePricingList?.map((item) => {
        return {
          ...item,
          payment_plan: interstatePaymentPlan,
          waybill_access: interstateAccessWaybill,
        };
      });

    const updatedInternational =
      internationalPricingList?.length > 0 &&
      internationalPricingList?.map((item) => {
        return {
          ...item,
          payment_plan: internationalPaymentPlan,
          waybill_access: internationalAccessWaybill,
        };
      });

    const formValues = {
      customer_id: customerID,
      local: localShipmentIsChecked ? updatedLocal : [],
      interstate: interstateShipmentIsChecked ? updatedInterstate : [],
      international: internationalShipmentIsChecked ? updatedInternational : [],
    };

    const newData = { ...formValues, ...UserProfileData() };

    console.log(newData);

    setErrorVisibility(false);
    setIsSubmitting(true);

    axios
      .post(makeContractUser2URL, newData)
      .then(function (response) {
        console.log(response);
        setIsSubmitting(false);

        if (response["data"]["success"] === false) {
          window.scrollTo(0, 0);
          setErrorVisibility(true);
          setErrorMessage(response["data"]["message"]);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        } else if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          setSuccessMessage(
            "User has successfully been set to a contract user"
          );

          setTimeout(() => {
            setSuccessVisibility(false);
            handleCloseDialog("Refresh");
          }, 3000);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const fetchContractDetails = () => {
    const formValues = {};
      formValues["customer_id"] = customerID;

      const newData = { ...formValues, ...UserProfileData() };

      axios
        .post(getContractDetailsUserURL, newData)
        .then(function (response) {
          setIsRetrieving(false);

          if (response["data"]["success"] === true) {
            const contractDetails = response["data"]["data"];
            let localCopyList = [...localPricingList];
            let interstateCopyList = [...interstatePricingList];
            let internationalCopyList = [...internationalPricingList];

            contractDetails.forEach((details) => {
              let pricingType = details?.pricing_type;
              let pricingValue = details?.pricing_value;
              let carrierValue = details?.carrier;
              let payPlan = details?.payment_plan;
              let accessWaybill = details?.waybill_access;

              let currentPlan = {
                pricing_type: pricingType,
                pricing_value: pricingValue,
                carriers: carrierValue,
                carrier_id: carrierValue,
                payment_plan: payPlan,
              };

              if (
                details.shipment_type === "Local" &&
                details.status === "active"
              ) {
                setLocalShipmentIsChecked(true);
                localCopyList.push(currentPlan);
                setLocalPaymentPlan(payPlan);
              }
              if (
                details.shipment_type === "Interstate" &&
                details.status === "active"
              ) {
                setInterstateShipmentIsChecked(true);

                interstateCopyList.push(currentPlan);
                setInterstatePaymentPlan(payPlan);
                setInterstateAccessWaybill(accessWaybill);
              }
              if (
                details.shipment_type === "International" &&
                details.status === "active"
              ) {
                setInternationalShipmentIsChecked(true);

                internationalCopyList.push(currentPlan);
                setInternationalPaymentPlan(payPlan);
                setInternationalAccessWaybill(accessWaybill);
              }
            });

            setLocalPricingList(localCopyList);
            setInterstatePricingList(interstateCopyList);
            setInternationalPricingList(internationalCopyList);
          }
        })
        .catch(function (error) {
          return error;
        });
  };

  React.useEffect(() => {
    if (getContractDetails === true) {
      fetchContractDetails();
      fetchAPICarriers();
    };
    // eslint-disable-next-line
  }, [getContractDetails]);

  const onChangeLocalPricingType = (event) => {
    if (event.target.value === "Standard Price") {
      setLocalPricingValueLabel("Standard Price For Local");
      setLocalPricingValueReadOnly(true);
    } else if (event.target.value === "Fixed Price") {
      setLocalPricingValueLabel("Fixed Price For Local");
      setLocalPricingValueReadOnly(false);
    } else if (event.target.value === "Discounted Price") {
      setLocalPricingValueLabel("Percentage Off For Local");
      setLocalPricingValueReadOnly(false);
    }
  };

  const onChangeInterstatePricingType = (event) => {
    if (event.target.value === "Standard Price") {
      setInterstatePricingValueLabel("Standard Price For Interstate");
      setInterstatePricingValueReadOnly(true);
    } else if (event.target.value === "Fixed Price") {
      setInterstatePricingValueLabel("Fixed Price For Interstate");
      setInterstatePricingValueReadOnly(false);
    } else if (event.target.value === "Discounted Price") {
      setInterstatePricingValueLabel("Percentage Off For Interstate");
      setInterstatePricingValueReadOnly(false);
    }
  };

  const onChangeInternationalPricingType = (event) => {
    if (event.target.value === "Standard Price") {
      setInternationalPricingValueLabel("Standard Price For Int'l");
      setInternationalPricingValueReadOnly(true);
    } else if (event.target.value === "Fixed Price") {
      setInternationalPricingValueLabel("Fixed Price For Int'l");
      setInternationalPricingValueReadOnly(false);
    } else if (event.target.value === "Discounted Price") {
      setInternationalPricingValueLabel("Percentage Off For Int'l");
      setInternationalPricingValueReadOnly(false);
    }
  };

  const handleLocalPricing = (e, id) => {
    const { name, value } = e.target;

    let localPricingCopy = [...localPricingList];
    let updatedPricingInfo = {
      ...localPricingCopy[id],
      [name]: value,
    };

    localPricingCopy[id] = updatedPricingInfo;

    setLocalPricingList(localPricingCopy);
  };

  const addLocalPricing = () => {
    let localPricingCopy = [...localPricingList];

    localPricingCopy.push({});
    setLocalPricingList(localPricingCopy);
  };

  const removeLocalPricing = (id) => {
    let localPricingCopy = [...localPricingList];

    localPricingCopy.splice(id, 1);

    setLocalPricingList(localPricingCopy);
  };

  // Interstate Functions
  const handleInterstatePricing = (e, id) => {
    const { name, value } = e.target;

    let interstatePricingCopy = [...interstatePricingList];
    let updatedPricingInfo = {
      ...interstatePricingCopy[id],
      [name]: value,
    };

    interstatePricingCopy[id] = updatedPricingInfo;

    setInterstatePricingList(interstatePricingCopy);
  };

  const addInterstatePricing = () => {
    let interstatePricingCopy = [...interstatePricingList];

    interstatePricingCopy.push({});
    setInterstatePricingList(interstatePricingCopy);
  };

  const removeInterstatePricing = (id) => {
    let interstatePricingCopy = [...interstatePricingList];

    interstatePricingCopy.splice(id, 1);

    setInterstatePricingList(interstatePricingCopy);
  };

  // International functions
  const handleInternationalPricing = (e, id) => {
    const { name, value } = e.target;

    let internationalPricingCopy = [...internationalPricingList];
    let updatedPricingInfo = {
      ...internationalPricingCopy[id],
      [name]: value,
    };

    internationalPricingCopy[id] = updatedPricingInfo;

    setInternationalPricingList(internationalPricingCopy);
  };

  const addInternationalPricing = () => {
    let internationalPricingCopy = [...internationalPricingList];

    internationalPricingCopy.push({});
    setInternationalPricingList(internationalPricingCopy);
  };

  const removeInternationalPricing = (id) => {
    let internationalPricingCopy = [...internationalPricingList];

    internationalPricingCopy.splice(id, 1);

    setInternationalPricingList(internationalPricingCopy);
  };

  const toggleInterstateAccessWaybill = () => {
    if (interstateAccessWaybill === "Yes") {
      setInterstateAccessWaybill("No");
    } else {
      setInterstateAccessWaybill("Yes");
    }
  };

  const toggleInternationalAccessWaybill = () => {
    if (internationalAccessWaybill === "Yes") {
      setInternationalAccessWaybill("No");
    } else {
      setInternationalAccessWaybill("Yes");
    }
  };

  const pricing_type_options = [
    {label: "Standard Price", value: "Standard Price"},
    {label: "Fixed Price", value: "Fixed Price"},
    {label: "Discounted Price", value: "Discounted Price"}
  ];

  const payment_plan_options = [
    {label: "No Payment Plan", value: "No Payment Plan"},
    {label: "Weekly", value: "Weekly"},
    {label: "Monthly", value: "Monthly"}
  ];

  return (
    <Modal
      show={isModalVisible}
      onHide={handleCloseDialog}
      dialogClassName="dialog-mid-larger"
    >
      <Modal.Header>
        <h5 style={{ marginTop: 10 }} className="header">
          MAKE CONTRACT USER
        </h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        {isRetrieving === true ? (
          <LoadingContainer>
            <LoadingSpinner className="spinner-border spinner-grow-sm" />{" "}
            <span>Retrieving contract details...</span>
          </LoadingContainer>
        ) : (
          <form ref={form} onSubmit={makeContractUser}>
            <div className="local-shipment-form form-check">
              <input
                checked={localShipmentIsChecked}
                onChange={toggleLocalShipment}
                className="form-check-input"
                type="checkbox"
                value=""
                id="localShipment"
              />
              <label className="form-check-label" htmlFor="localShipment">
                Local Shipment
              </label>
            </div>

            {localShipmentIsChecked === false ? null : (
              <div>
                {localPricingList.map((pricingInfo, id) => (
                  <Row className="mt-3" key={id}>
                    <div className="d-flex justify-content-end mb-2">
                      <BiTrash
                        onClick={
                          localPricingList?.length > 1
                            ? () => removeLocalPricing(id)
                            : () => {}
                        }
                        className={`${
                          localPricingList?.length > 1
                            ? "text-danger"
                            : "text-muted"
                        }`}
                      />
                    </div>
                    <Col xs={12} md={6} lg={4}>
                      <label htmlFor="Sender Postal">
                        Pricing Plan For Local
                      </label>
                      <SelectBox 
                        name="pricing_type"
                        onChange={(e) => handleLocalPricing(e, id)}
                        value={pricingInfo?.pricing_type}
                        options={pricing_type_options}
                        placeholder="Select pricing type"
                      />
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                      <label htmlFor="Fixed Price">
                        {pricingInfo?.pricing_type === "Standard Price"
                          ? "Standard Price For Local"
                          : pricingInfo?.pricing_value === "Fixed Price"
                          ? "Fixed Price For Local"
                          : "Percentage Off For Local"}
                      </label>
                      <TextBox 
                        name="pricing_value"
                        onChange={(e) => handleLocalPricing(e, id)}
                        type="text"
                        value={pricingInfo?.pricing_value}
                        readOnly={
                          pricingInfo?.pricing_type === "Standard Price"
                        }
                      />
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                      <label htmlFor="Fixed Price">Carrier</label>
                      <SelectBox 
                        onChange={(e) => handleLocalPricing(e, id)}
                        name="carrier_id"
                        value={pricingInfo?.carriers}
                        options={intracityCarriers}
                        placeholder="Select local carrier"
                      />
                    </Col>
                    <Col xs={12} className="d-flex justify-content-end">
                      <AddBtn type="button" onClick={addLocalPricing}>
                        Add another discount
                      </AddBtn>
                    </Col>
                    <Col xs={12} >
                      <div className="">
                        <label htmlFor="Payment Plan">Payment Plan</label>
                        <SelectBox
                          name="payment_plan"
                          onChange={(e) => handleLocalPricing(e, id)}
                          value={pricingInfo?.payment_plan} 
                          options={payment_plan_options}
                          placeholder="select local payment plan"
                        />
                      </div>
                    </Col>
                  </Row>
                ))}
              </div>
            )}

            <div className="local-shipment-form form-check mt-4">
              <input
                checked={interstateShipmentIsChecked}
                onChange={toggleInterstateShipment}
                className="form-check-input"
                type="checkbox"
                value=""
                id="interstateShipment"
              />
              <label className="form-check-label" htmlFor="interstateShipment">
                Interstate Shipment
              </label>
            </div>

            {interstateShipmentIsChecked === false ? null : (
              <div>
                {interstatePricingList.map((pricingInfo, id) => (
                  <Row className="mt-3" key={id}>
                    <div className="d-flex justify-content-end mb-2">
                      <BiTrash
                        onClick={
                          interstatePricingList?.length > 1
                            ? () => removeInterstatePricing(id)
                            : () => {}
                        }
                        className={`${
                          interstatePricingList?.length > 1
                            ? "text-danger"
                            : "text-muted"
                        }`}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <Col xs={12} md={6} lg={4}>
                      <label htmlFor="Sender Postal">
                        Pricing Plan For Interstate
                      </label>
                      <SelectBox 
                        name="pricing_type"
                        onChange={(e) => handleInterstatePricing(e, id)}
                        value={pricingInfo?.pricing_type}
                        options={pricing_type_options}
                        placeholder="Select pricing type"
                      />
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                      <label htmlFor="Fixed Price">
                        {pricingInfo?.pricing_type === "Standard Price"
                          ? "Standard Price For Interstate"
                          : pricingInfo?.pricing_value === "Fixed Price"
                          ? "Fixed Price For Interstate"
                          : "Percentage Off For Interstate"}
                      </label>
                      <TextBox 
                        name="pricing_value"
                        onChange={(e) => handleInterstatePricing(e, id)}
                        type="text"
                        value={pricingInfo?.pricing_value}
                        readOnly={
                          pricingInfo?.pricing_type === "Standard Price"
                        }
                      />
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                      <label htmlFor="Fixed Price">Carrier</label>
                      <SelectBox 
                        onChange={(e) => handleInterstatePricing(e, id)}
                        name="carrier_id"
                        value={pricingInfo?.carriers}
                        options={interstateCarriers}
                        placeholder="Select interstate carrier"
                      />
                    </Col>
                    <Col xs={12} className="d-flex justify-content-end">
                      <AddBtn type="button" onClick={addInterstatePricing}>
                        Add another discount
                      </AddBtn>
                    </Col>
                    <Col xs={12} >
                      <div className="">
                        <label htmlFor="Payment Plan">Payment Plan</label>
                        <SelectBox
                          name="payment_plan"
                          onChange={(e) => handleInterstatePricing(e, id)}
                          value={pricingInfo?.payment_plan} 
                          options={payment_plan_options}
                          placeholder="select interstate payment plan"
                        />
                      </div>
                    </Col>
                  </Row>
                ))}
                <div className="mt-2">
                  <input
                    checked={interstateAccessWaybill === "Yes"}
                    onChange={toggleInterstateAccessWaybill}
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="interstateShipment"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="interstateShipment"
                  >
                    Allow customer to access waybill
                  </label>
                </div>
              </div>
            )}

            <div className="local-shipment-form form-check mt-4">
              <input
                checked={internationalShipmentIsChecked}
                onChange={toggleInternationalShipment}
                className="form-check-input"
                type="checkbox"
                value=""
                id="internationalShipment"
              />
              <label
                className="form-check-label"
                htmlFor="internationalShipment"
              >
                International Shipment
              </label>
            </div>

            {internationalShipmentIsChecked === false ? null : (
              <div>
                {internationalPricingList.map((pricingInfo, id) => (
                  <Row className="mt-3" key={id}>
                    <div className="d-flex justify-content-end mb-2">
                      <BiTrash
                        onClick={
                          internationalPricingList?.length > 1
                            ? () => removeInternationalPricing(id)
                            : () => {}
                        }
                        className={`${
                          internationalPricingList?.length > 1
                            ? "text-danger"
                            : "text-muted"
                        }`}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <Col xs={12} md={6} lg={4}>
                      <label htmlFor="Sender Postal">
                        Pricing Plan For International
                      </label>
                      <SelectBox 
                        name="pricing_type"
                        onChange={(e) => handleInternationalPricing(e, id)}
                        value={pricingInfo?.pricing_type}
                        options={pricing_type_options}
                        placeholder="Select pricing type"
                      />
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                      <label htmlFor="Fixed Price">
                        {pricingInfo?.pricing_type === "Standard Price"
                          ? "Standard Price For International"
                          : pricingInfo?.pricing_value === "Fixed Price"
                          ? "Fixed Price For International"
                          : "Percentage Off For International"}
                      </label>
                      <TextBox 
                        name="pricing_value"
                        onChange={(e) => handleInternationalPricing(e, id)}
                        type="text"
                        value={pricingInfo?.pricing_value}
                        readOnly={
                          pricingInfo?.pricing_type === "Standard Price"
                        }
                      />
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                      <label htmlFor="Fixed Price">Carrier</label>
                      <SelectBox 
                        onChange={(e) => handleInternationalPricing(e, id)}
                        name="carrier_id"
                        value={pricingInfo?.carriers}
                        options={internationalCarriers}
                        placeholder="Select international carrier"
                      />
                    </Col>
                    <Col xs={12} className="d-flex justify-content-end">
                      <AddBtn type="button" onClick={addInternationalPricing}>
                        Add another discount
                      </AddBtn>
                    </Col>
                    <Col xs={12} >
                      <div className="">
                        <label htmlFor="Payment Plan">Payment Plan</label>
                        <SelectBox
                          name="payment_plan"
                          onChange={(e) => handleInternationalPricing(e, id)}
                          value={pricingInfo?.payment_plan} 
                          options={payment_plan_options}
                          placeholder="select internationa payment plan"
                        />
                      </div>
                    </Col>
                  </Row>
                ))}
                <div className="mt-2">
                  <input
                    checked={internationalAccessWaybill === "Yes"}
                    onChange={toggleInternationalAccessWaybill}
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="interstateShipment"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="interstateShipment"
                  >
                    Allow customer to access waybill
                  </label>
                </div>
              </div>
            )}
          </form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
        <SubmitButton
          onClick={makeContractUser}
          type="submit"
          className="confirm-button"
        >
          {isSubmitting === false ? (
            <span className="button-text">MAKE CONTRACT USER</span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default MakeContractUserDialog;
