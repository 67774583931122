import "../styles/home.scss";
import "../styles/single-customer.scss";
import "../styles/components.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getCustomerDetailsURL,
  getCustomerStatsURL,
  getCustomerVirtualAccountUrl,
} from "../library/URLs";
import axios from "axios";
import { UserProfileData } from "../library/constants";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { BsDot } from "react-icons/bs";
import styled from "styled-components";
import * as React from "react";
import MakeContractUserDialog from "../modals/MakeContractUserDialog";
import MakeRegularUserDialog from "../modals/MakeRegularUserDialog";
import CustomerProfileShipments from "./Widgets/Customer/CustomerProfileShipments";
import contractIcon from "../images/contract-icon.png";
import CustomerOverview from "./Widgets/Customer/CustomerOverview";
import moment from "moment";
import CustomerWallet from "./Widgets/Customer/CustomerWallet";
import CustomerReferrals from "./Widgets/Customer/CustomerReferrals";
import SendEmailDialog from "../modals/SendEmailDialog";
import SubscriptionInactive from "../components/SubscriptionInactive";
import CustomerKycDocs from "./Widgets/Customer/CustomerDocument";
import ActivateCustomerDialog from "../modals/ActivateCustomerDialog";
import DeactivateCustomerDialog from "../modals/DeactivateCustomerDialog";
import SuspendUserDialog from "../modals/SuspendUserDialog";
import UnSuspendUserDialog from "../modals/UnsuspendCustomerDialog";
import Person from "../assets/single-customer/person_4.svg";
import Contract from "../assets/customers/workspace_premium.svg";
import Regular from "../assets/customers/military_tech.svg";
import Individual from "../assets/customers/individual.svg";
import Business from "../assets/customers/user-group.svg";
import Logistics from "../assets/customers/truck.svg";
import Tooltip from "@mui/material/Tooltip";
import Mail from "../assets/single-customer/mail.svg";
import Notify from "../assets/single-customer/edit_notifications.svg";
import Chat from "../assets/single-customer/chat.svg";
import ShipmentsIcon from "../assets/single-customer/deployed_code.svg";
import TransactionsIcon from "../assets/single-customer/payments.svg";
//
import OverviewIcon from "../assets/single-customer/overview.svg";
import OverviewActiveIcon from "../assets/single-customer/overview_active.svg";
import ShipmentIcon from "../assets/single-customer/shipments_icon.svg";
import ShipmentActiveIcon from "../assets/single-customer/deployed_code_active.svg";
import WalletIcon from "../assets/single-customer/wallet.svg";
import WalletActiveIcon from "../assets/single-customer/wallet_active.svg";
import ReferralIcon from "../assets/single-customer/referral.svg";
import ReferralActiveIcon from "../assets/single-customer/referral_active.svg";
import DocIcon from "../assets/single-customer/description.svg";
import DocActiveIcon from "../assets/single-customer/description_active.svg";

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 150px;
  height: 150px;
`;

const CommunicationContainer = styled.div`
  padding: 8px 8px;
  border-radius: 5px;
  background-color: #e5e7e9;
  color: #2e4053;
  font-size: 12px;
  height: 35px;
  margin-right: 3px;
  margin-left: 3px;
  cursor: pointer;
`;

const SingleCustomer = () => {
  const params = useParams();
  const customerID = params["customerID"];

  const [isContractUserModalVisible, setContractUserModalVisibility] =
    useState(false);
  const [isRegularUserModalVisible, setRegularUserModalVisibility] =
    useState(false);

  const [emailDialogVisibility, setEmailDialogVisibility] = useState(false);

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [memberType, setMemberType] = useState("");
  const [accountType, setAccountType] = useState("");
  const [profileImageURL, setProfileImageURL] = useState(
    "https://users.mylogisticsapp.com/engine/v1/actions/profiles/default-profile-image.png"
  );
  const [registrationTimestamp, setRegistrationTimestamp] = useState("");
  const [lastActivityTimestamp, setLastActivityTimestamp] = useState("");
  const [status, setStatus] = useState("");

  const [numberOfShipments, setNumberOfShipments] = useState("...");
  const [numberOfTransactions, setNumberOfTransactions] = useState("...");

  const [activateDialog, setActivateDialog] = useState(false);
  const [deactivateDialog, setDeactivateDialog] = useState(false);
  const [suspendDialog, setSuspendDialog] = useState(false);
  const [unsuspendDialog, setUnsuspendDialog] = useState(false);
  const [walletInfo, setWalletInfo] = useState({
    bank: "",
    account_number: "",
    account_name: "",
  });

  const [activeTab, setActiveTab] = useState("CustomerOverview");

  const [primaryPickupAddress, setPrimaryPickupAddress] = useState("");
  const [secondaryPickupAddress, setSecondaryPickupAddress] = useState("");

  const [userActionMenuVisibility, setUserActionMenuVisibility] =
    useState(false);

  const toggleUserActionMenu = () => {
    setUserActionMenuVisibility(!userActionMenuVisibility);
  };

  const toggleContractUserModal = () => {
    setUserActionMenuVisibility(!userActionMenuVisibility);
    setContractUserModalVisibility(!isContractUserModalVisible);
  };

  const handleRegularUserModal = () => {
    setUserActionMenuVisibility(!userActionMenuVisibility);
    setRegularUserModalVisibility(!isRegularUserModalVisible);
  };

  const toggleEmailDialog = (type) => {
    setEmailDialogVisibility(!emailDialogVisibility);
  };

  const handleCloseModal = (refresh) => {
    setContractUserModalVisibility(false);
    setRegularUserModalVisibility(false);
    setEmailDialogVisibility(false);

    if (refresh === "Refresh") {
      window.location.reload();
    }
  };

  const data = {
    customer_id: customerID,
  };

  const newData = { ...data, ...UserProfileData() };

  const fetchCustomer = () => {
    axios
      .post(getCustomerDetailsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response["data"]["success"] === false) return;

        const shipmentDetails = response["data"];

        const name = shipmentDetails["data"]["fullname"];
        const phoneNumber = shipmentDetails["data"]["phone"];
        const email = shipmentDetails["data"]["email"];
        const referralCode = shipmentDetails["data"]["ref_code"];
        const memberType = shipmentDetails["data"]["member_type"];
        const account_type = shipmentDetails["data"]["account_type"];
        const profileImageURL = shipmentDetails["data"]["profile_image_url"];

        const registrationTime = moment(shipmentDetails["data"]["date_time"]);
        const registrationTimestamp =
          registrationTime.format("ddd MMM Do, h:mm A");

        const lastActivityTime = moment(
          shipmentDetails["data"]["last_activity"]
        );
        const lastActivityTimestamp =
          lastActivityTime.format("ddd MMM Do, h:mm A");

        const address = shipmentDetails["data"]["primary_pickup_address"];

        const customerStatus = shipmentDetails["data"]["status"];

        setPrimaryPickupAddress(address);

        setName(name);
        setPhoneNumber(phoneNumber);
        setEmail(email);
        setReferralCode(referralCode);
        setMemberType(memberType);
        setAccountType(account_type);
        setProfileImageURL(profileImageURL);
        setRegistrationTimestamp(registrationTimestamp);
        setLastActivityTimestamp(lastActivityTimestamp);
        setStatus(customerStatus);
      });
  };

  const fetchCustomerVirtualAcct = () => {
    axios
      .post(getCustomerVirtualAccountUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response["data"]["success"] === false) return;

        setWalletInfo(response["data"]["data"]);
      });
  };

  useEffect(() => {
    fetchCustomer();
    fetchCustomerVirtualAcct();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    axios
      .post(getCustomerStatsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response["data"]["success"] === false) return;

        const totalShipments = response["data"]["total_shipments"];
        const totalShipmentWorth = response["data"]["total_shipments_worth"];

        setNumberOfShipments(totalShipments);
        setNumberOfTransactions(
          "₦" +
            (totalShipmentWorth === null
              ? "0"
              : parseFloat(totalShipmentWorth).toLocaleString("en"))
        );
      });
    // eslint-disable-next-line
  }, []);

  const accountName = (account_name) => {
    if (account_name?.length > 26) {
      return `${account_name?.slice(0, 26)}...`;
    } else {
      return account_name;
    }
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />

          <MakeContractUserDialog
            isModalVisible={isContractUserModalVisible}
            handleCloseDialog={handleCloseModal}
            customerID={customerID}
            getContractDetails={true}
          />

          <MakeRegularUserDialog
            isModalVisible={isRegularUserModalVisible}
            handleCloseDialog={handleCloseModal}
            customerID={customerID}
          />

          <SendEmailDialog
            isModalVisible={emailDialogVisibility}
            handleCloseDialog={handleCloseModal}
            customerID={customerID}
          />

          <ActivateCustomerDialog
            isModalVisible={activateDialog}
            handleCloseDialog={() => setActivateDialog(false)}
            customerID={customerID}
            updateInfo={fetchCustomer}
          />

          <DeactivateCustomerDialog
            isModalVisible={deactivateDialog}
            handleCloseDialog={() => setDeactivateDialog(false)}
            customerID={customerID}
            updateInfo={fetchCustomer}
          />

          <SuspendUserDialog
            isModalVisible={suspendDialog}
            handleCloseDialog={() => setSuspendDialog(false)}
            customerID={customerID}
            updateInfo={fetchCustomer}
          />

          <UnSuspendUserDialog
            isModalVisible={unsuspendDialog}
            handleCloseDialog={() => setUnsuspendDialog(false)}
            customerID={customerID}
            updateInfo={fetchCustomer}
          />

          <div
            className="container-fluid rounded-4 card border-0"
            style={{ backgroundColor: "#FFF" }}
          >
            <div className="row mt-4">
              <div className="col-xl-12 col-md-12">
                <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                  <div className="d-flex align-items-center flex-row gap-2 ">
                    <img src={Person} alt="person" />
                    <h5 className="m-0">Customer Detail</h5>
                  </div>
                </div>
                <div className="card rounded-4 section-card">
                  <div className="card-body">
                    <div className="d-flex flex-column gap-4 py-md-4 flex-md-row justify-content-between">
                      <div className="d-flex flex-md-row flex-column gap-3 gap-md-5">
                        <div>
                          <ProfileImage src={profileImageURL} />
                          <div className="wallet-account-info">
                            <div className="account-section">
                              <p className="account-label">Bank Name:</p>
                              <p className="account-info">
                                {walletInfo?.bank ?? "N/A"}
                              </p>
                            </div>

                            <div className="account-section">
                              <p className="account-label">Acct Number:</p>
                              <p className="account-info">
                                {walletInfo?.account_number ?? "N/A"}
                              </p>
                            </div>

                            <div className="account-section">
                              <p className="account-label">Acct Name:</p>
                              <Tooltip title={`${walletInfo?.account_name}`} placement="top">
                                <p className="account-info">
                                  {walletInfo?.account_name
                                    ? accountName(walletInfo?.account_name)
                                    : "N/A"}
                                </p>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column">
                          <div
                            className="d-flex flex-row align-items-center"
                            style={{ textAlign: "center" }}
                          >
                            <span
                              className=" text-dark"
                              style={{
                                fontSize: 22,
                                marginRight: 10,
                                fontWeight: 500,
                              }}
                            >
                              {name}
                            </span>
                            <BsDot />
                            <span className="d-flex gap-1 align-items-center">
                              {memberType === "Contract" ? (
                                <img src={Contract} alt="contract" />
                              ) : (
                                <img src={Regular} alt="Regular" />
                              )}
                              {memberType}
                            </span>
                            <BsDot />
                            <span className="d-flex account-type-text gap-1 align-items-center">
                              {accountType === "Individual" ? (
                                <img src={Individual} alt="individual" />
                              ) : accountType === "Business" ? (
                                <img src={Business} alt="business" />
                              ) : (
                                <img src={Logistics} alt="3pl" />
                              )}
                              {accountType}
                            </span>
                          </div>
                          <div className="d-flex flex-row align-items-center">
                            <span>{email}</span>
                            <BsDot />
                            <span>{phoneNumber}</span>
                          </div>
                          <div className="d-flex gap-3 flex-row mt-4">
                            <div className="customer-info-card">
                              <div className="top-section">
                                <div className="info-card-icon">
                                  <img src={ShipmentsIcon} alt="icon" />
                                </div>
                                <span>Shipments</span>
                              </div>
                              <span className="bottom-section">
                                {numberOfShipments}
                              </span>
                            </div>
                            <div className="customer-info-card">
                              <div className="top-section">
                                <div className="info-card-icon">
                                  <img src={TransactionsIcon} alt="icon" />
                                </div>
                                <span>Transactions</span>
                              </div>
                              <span className="bottom-section">
                                {numberOfTransactions}
                              </span>
                            </div>
                          </div>
                          <div className="d-grid d-md-flex gap-2 pt-3">
                            {status === "Active" && (
                              <input
                                className="btn-error"
                                type="button"
                                onClick={() => setSuspendDialog(true)}
                                value="Suspend User"
                              />
                            )}
                            {status === "Active" && (
                              <input
                                className="btn-error"
                                type="button"
                                onClick={() => setDeactivateDialog(true)}
                                value="Deactivate User"
                              />
                            )}
                            {status === "Suspended" && (
                              <input
                                className="btn-secondary"
                                type="button"
                                onClick={() => setUnsuspendDialog(true)}
                                value="Unsuspend User"
                              />
                            )}
                            {status === "Deactivated" && (
                              <input
                                className="btn-secondary"
                                type="button"
                                onClick={() => setActivateDialog(true)}
                                value="Activate User"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex gap-2">
                        <Tooltip title="Send SMS" placement="bottom">
                          <button className="icon-btn">
                            <img src={Chat} alt="send sms" />
                          </button>
                        </Tooltip>
                        <Tooltip title="Send Notification" placement="bottom">
                          <button className="icon-btn">
                            <img src={Notify} alt="send notifications" />
                          </button>
                        </Tooltip>
                        <Tooltip title="Send Email" placement="bottom">
                          <button
                            className="icon-btn"
                            onClick={(e) => toggleEmailDialog("open")}
                          >
                            <img src={Mail} alt="send mail" />
                          </button>
                        </Tooltip>
                        <HiOutlineDotsVertical
                          onClick={toggleUserActionMenu}
                          style={{
                            width: 25,
                            cursor: "pointer",
                            marginTop: 10,
                          }}
                        />
                        <div
                          className={`user-menu ${
                            userActionMenuVisibility
                              ? "user-menu-visible"
                              : null
                          }`}
                        >
                          {memberType === "Regular" ? (
                            <span onClick={toggleContractUserModal}>
                              Make Contract User
                            </span>
                          ) : (
                            <div>
                              <span onClick={handleRegularUserModal}>
                                Make Regular User
                              </span>
                              <span onClick={toggleContractUserModal}>
                                Edit Contract Details
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-xl-12 col-md-12">
                <div className="section-card rounded-4 overflow-hidden">
                  <div className="mt-5 d-flex border-bottom mx-3">
                    <span
                      onClick={(e) => setActiveTab("CustomerOverview")}
                      className={`cust-profile-tab ${
                        activeTab === "CustomerOverview"
                          ? "active-cust-profile"
                          : null
                      }`}
                    >
                      {activeTab === "CustomerOverview" ? (
                        <img src={OverviewActiveIcon} alt="icon" />
                      ) : (
                        <img src={OverviewIcon} alt="icon" />
                      )}
                      Overview
                    </span>
                    <span
                      onClick={(e) => setActiveTab("CustomerShipments")}
                      className={`cust-profile-tab ${
                        activeTab === "CustomerShipments"
                          ? "active-cust-profile"
                          : null
                      }`}
                    >
                      {activeTab === "CustomerShipments" ? (
                        <img src={ShipmentActiveIcon} alt="icon" />
                      ) : (
                        <img src={ShipmentIcon} alt="icon" />
                      )}
                      Shipments
                    </span>
                    <span
                      onClick={(e) => setActiveTab("CustomerWallet")}
                      className={`cust-profile-tab ${
                        activeTab === "CustomerWallet"
                          ? "active-cust-profile"
                          : null
                      }`}
                    >
                      {activeTab === "CustomerWallet" ? (
                        <img src={WalletActiveIcon} alt="icon" />
                      ) : (
                        <img src={WalletIcon} alt="icon" />
                      )}
                      Wallet
                    </span>
                    <span
                      onClick={(e) => setActiveTab("CustomerReferrals")}
                      className={`cust-profile-tab ${
                        activeTab === "CustomerReferrals"
                          ? "active-cust-profile"
                          : null
                      }`}
                    >
                      {activeTab === "CustomerReferrals" ? (
                        <img src={ReferralActiveIcon} alt="icon" />
                      ) : (
                        <img src={ReferralIcon} alt="icon" />
                      )}
                      Referrals
                    </span>
                    <span
                      onClick={(e) => setActiveTab("CustomerDocuments")}
                      className={`cust-profile-tab ${
                        activeTab === "CustomerDocuments"
                          ? "active-cust-profile"
                          : null
                      }`}
                    >
                      {activeTab === "CustomerDocuments" ? (
                        <img src={DocActiveIcon} alt="icon" />
                      ) : (
                        <img src={DocIcon} alt="icon" />
                      )}
                      Documents
                    </span>

                    <span
                      onClick={(e) => setActiveTab("CustomerGroups")}
                      className={`cust-profile-tab ${
                        activeTab === "CustomerGroups"
                          ? "active-cust-profile"
                          : null
                      }`}
                    >
                      {activeTab === "CustomerGroups" ? (
                        <img src={DocActiveIcon} alt="icon" />
                      ) : (
                        <img src={DocIcon} alt="icon" />
                      )}
                      Groups
                    </span>

                    <span
                      onClick={(e) => setActiveTab("CustomerSettings")}
                      className={`cust-profile-tab ${
                        activeTab === "CustomerSettings"
                          ? "active-cust-profile"
                          : null
                      }`}
                    >
                      {activeTab === "CustomerSettings" ? (
                        <img src={DocActiveIcon} alt="icon" />
                      ) : (
                        <img src={DocIcon} alt="icon" />
                      )}
                      Settings
                    </span>
                  </div>
                  <div
                    className={` ${
                      activeTab === "CustomerOverview"
                        ? "show-customer-tab"
                        : "hide-customer-tab"
                    }`}
                  >
                    <CustomerOverview
                      primaryPickupAddress={primaryPickupAddress}
                      secondaryPickupAddress={secondaryPickupAddress}
                      lastActivityTimestamp={lastActivityTimestamp}
                      registrationTimestamp={registrationTimestamp}
                    />
                  </div>
                  <div
                    className={`${
                      activeTab === "CustomerShipments"
                        ? "show-customer-tab"
                        : "hide-customer-tab"
                    }`}
                  >
                    <CustomerProfileShipments customerID={customerID} />
                  </div>
                  <div
                    className={`${
                      activeTab === "CustomerWallet"
                        ? "show-customer-tab"
                        : "hide-customer-tab"
                    }`}
                  >
                    <CustomerWallet
                      customerID={customerID}
                      customerName={name}
                      customerPhoneNumber={phoneNumber}
                    />
                  </div>
                  <div
                    className={`${
                      activeTab === "CustomerReferrals"
                        ? "show-customer-tab"
                        : "hide-customer-tab"
                    }`}
                  >
                    <CustomerReferrals
                      customerID={customerID}
                      referralCode={referralCode}
                    />
                  </div>
                  <div
                    className={`${
                      activeTab === "CustomerDocuments"
                        ? "show-customer-tab"
                        : "hide-customer-tab"
                    }`}
                  >
                    <CustomerKycDocs customerID={customerID} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleCustomer;
