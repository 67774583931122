import { Modal } from "react-bootstrap";
import {BiX} from "react-icons/bi";
import { BsX } from "react-icons/bs";

const ProfileConfirmationDialog = ({ isModalVisible, isLoading,  handleCloseDialog,updateProfile }) => {
  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">UPDATE PROFILE INFORMATION</h5>
          <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
      </Modal.Header>
      <Modal.Body>
          <p>You are about to update your profile.</p>
          <p>This change would affect your personal information.</p>
          <p>Would you like to continue?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary mx-3" onClick={(e) => handleCloseDialog(false)}>
          <BsX /> CANCEL
        </button>
          <button onClick={updateProfile} type="submit" className="confirm-button btn-primary">
              {isLoading === false ? (
                  <span className="button-text d-flex align-items-center gap-2"> Proceed</span>
              ) : (
                  <span className="spinner-border spinner-grow-sm"/>
              )}
          </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ProfileConfirmationDialog