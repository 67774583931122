import { Modal } from "react-bootstrap";
import axios from "axios";
import { deleteAddressUrl} from "../library/URLs";
import {useState} from "react";
import {UserProfileData} from "../library/constants";
import styled from "styled-components";
import {BiX} from "react-icons/bi";
import { BsTrash, BsX } from "react-icons/bs";
import { toast } from "react-toastify";

const SubmitButton = styled.button`
`;

const DeleteAddressDialog = ({ isModalVisible, handleCloseDialog, addressID, onSubmit=()=>{} }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const deleteAddress = (e) => {
        const formValues = {
          address_id: addressID
        };

        const newData = { ...formValues, ...UserProfileData() };

        setIsSubmitting(true);

        console.log(newData);

        axios
            .post(deleteAddressUrl, newData)
            .then(function (response) {
                console.log(response);
                setIsSubmitting(false);
                if (response["data"]["success"] === true) {
                    toast.success(response["data"]["data"]);
                    onSubmit();

                    setTimeout(() => {
                        handleCloseDialog(true);
                    }, 2000);
                } else {
                    toast.error(response["data"]["data"]);
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">DELETE ADDRESS INFO</h5>
          <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
      </Modal.Header>
      <Modal.Body>
          <p>You are about to delete this address</p>
          <p>This means you won't be able to use this address to populate customer info when booking shipment.</p>
          <p>Would you like to continue?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary mx-3" onClick={(e) => handleCloseDialog(false)}>
          <BsX /> CANCEL
        </button>
          <SubmitButton onClick={deleteAddress} type="submit" className="confirm-button btn-primary">
              {isSubmitting === false ? (
                  <span className="button-text d-flex align-items-center gap-2"><BsTrash /> Delete Address</span>
              ) : (
                  <span className="spinner-border spinner-grow-sm"/>
              )}
          </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteAddressDialog;
