import React from "react";
import styled from "styled-components";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const LoadingScreen = () => {
  return (
    <div>
      <LoadingContainer>
        <LoadingSpinner className="spinner-border spinner-grow-sm" />
      </LoadingContainer>
    </div>
  );
};

export default LoadingScreen;
