import "../styles/home.scss";
import Search from "../assets/Search.svg";
import { useEffect, useState } from "react";
import {
  BsCheck,
  BsChevronDown,
  BsChevronUp,
  BsDot,
  BsEye,
  BsEyeSlash,
} from "react-icons/bs";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useRef } from "react";
import Select from "react-select";
import axios from "axios";
import { getAddressBookUrl, searchForCustomersURL, uploadPackageImgUrl } from "../library/URLs";
import { TbPhoto } from "react-icons/tb";
import { UserProfileData } from "../library/constants";

export const SearchBox = ({ value, name = "", onChange, placeholder = "" }) => {
  return (
    <div className="search-input-contain">
      <input
        name={name}
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="search-input"
      />
      <div className="search-icon">
        <img src={Search} alt="search" />
      </div>
    </div>
  );
};

export const TextBox = ({
  value,
  name = "",
  onChange,
  placeholder = "",
  type = "text",
  prefix = "",
  defaultValue,
  disabled = false,
  ...props
}) => {
  return (
    <div className="text-input-contain">
      {prefix && <p className="text-prefix">{prefix}</p>}
      <input
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={`text-input ${prefix && "prefix-input"}`}
        disabled={disabled}
        {...props}
      />
    </div>
  );
};

export const PasswordBox = ({
  value,
  name = "",
  onChange,
  placeholder = "",
  defaultValue,
  disabled = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="text-input-contain">
      <input
        name={name}
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={`text-input`}
        disabled={disabled}
      />
      {showPassword ? (
        <BsEyeSlash
          onClick={() => setShowPassword(false)}
          className="password-icon"
        />
      ) : (
        <BsEye
          onClick={() => setShowPassword(true)}
          className="password-icon"
        />
      )}
    </div>
  );
};

export const SelectBox = ({
  value,
  name = "",
  onChange,
  placeholder = "",
  defaultValue,
  disabled = false,
  options = [],
  ...props
}) => {
  return (
    <div className="text-input-contain">
      <select
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={`text-input`}
        disabled={disabled}
        {...props}
      >
        <option value="" hidden>
          {placeholder}
        </option>
        {options.map((opt) => (
          <option value={opt.value} key={opt.value}>
            {opt.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export const TextFieldBox = ({
  value,
  name = "",
  onChange,
  placeholder = "",
  defaultValue,
  disabled = false,
}) => {
  return (
    <div className="text-input-contain">
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={`text-input`}
        disabled={disabled}
        rows={3}
      ></textarea>
    </div>
  );
};

export const CheckBox = ({ onChange, checked }) => {
  return (
    <div>
      <div
        onClick={onChange}
        className={`input-check ${checked && "input-check-checked"}`}
      >
        {checked && <BsCheck />}
      </div>
    </div>
  );
};

export const RadioCheck = ({ onChange, checked }) => {
  return (
    <div>
      <div onClick={onChange} className={`radio-check`}>
        {checked && <div className="radio-check-checked"></div>}
      </div>
    </div>
  );
};

export const PhoneFieldBox = ({
  value,
  onChange,
  disabled = false,
  defaultCountry = "NG",
}) => {
  return (
    <div
      className="text-input-contain"
      style={{ position: "relative", overflow: "hidden" }}
    >
      <PhoneInput
        defaultCountry={defaultCountry}
        value={value}
        onChange={onChange}
        disabled={disabled}
        style={{ backgroundColor: disabled && "#F0F2F5", }}
      />
      {
        disabled && (
          <div
          className=" "
          style={{
            backgroundColor: "#F0F2F5",
            zIndex: 3,
            width: "40px",
            height: "30px",
            position: "absolute",
            left: "10px",
            top: "5px",
            borderRadius: "8px"
          }}
        ></div>
        )
      }
    </div>
  );
};

export const SelectTextBox = ({
  value,
  name = "",
  onChange,
  placeholder = "",
  options = [],
  disabled = false,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const listRef = useRef();

  const selectOption = (name, value) => {
    onChange(name, value);
    setShowDropdown(false);
  };

  return (
    <div className="select-text-input-contain">
      {showDropdown && (
        <div
          className={`select-text-close`}
          onClick={(e) => {
            e.stopPropagation();
            setShowDropdown(false);
          }}
        ></div>
      )}
      <div
        className={`select-text-input-contain ${
          showDropdown && "select-text-input-contain-index"
        }`}
      >
        <input
          name={name}
          type="text"
          value={value}
          onChange={(e) => onChange(name, e.target.value)}
          placeholder={placeholder}
          className={`text-input`}
          onFocus={() => setShowDropdown(true)}
          disabled={disabled}
        />
        {showDropdown ? (
          <BsChevronUp className="select-text-arrow" />
        ) : (
          <BsChevronDown className="select-text-arrow" />
        )}
        {showDropdown && !disabled && (
          <div ref={listRef} className="select-text-dropdown">
            <ul className="select-text-dropdown-list">
              {options
                ?.filter((opt) =>
                  opt?.value?.toLowerCase()?.includes(value?.toLowerCase())
                )
                ?.map((opt) => (
                  <li
                    key={opt?.value}
                    onClick={() => selectOption(name, opt?.value)}
                  >
                    {opt?.label}
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export const SelectTextBox2 = ({
  value,
  name = "",
  onChange,
  placeholder = "",
  options = [],
  disabled = false,
  id,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const listRef = useRef();

  const selectOption = (id, name, value) => {
    onChange(id, name, value);
    setShowDropdown(false);
  };

  return (
    <div className="select-text-input-contain">
      {showDropdown && (
        <div
          className={`select-text-close`}
          onClick={(e) => {
            e.stopPropagation();
            setShowDropdown(false);
          }}
        ></div>
      )}
      <div
        className={`select-text-input-contain ${
          showDropdown && "select-text-input-contain-index"
        }`}
      >
        <input
          name={name}
          type="text"
          value={value}
          onChange={(e) => onChange(name, e.target.value)}
          placeholder={placeholder}
          className={`text-input`}
          onFocus={() => setShowDropdown(true)}
          disabled={disabled}
        />
        {showDropdown ? (
          <BsChevronUp className="select-text-arrow" />
        ) : (
          <BsChevronDown className="select-text-arrow" />
        )}
        {showDropdown && !disabled && (
          <div ref={listRef} className="select-text-dropdown">
            <ul className="select-text-dropdown-list">
              {options
                ?.filter((opt) =>
                  opt?.value?.toLowerCase()?.includes(value?.toLowerCase())
                )
                ?.map((opt) => (
                  <li
                    key={opt?.value}
                    onClick={() => selectOption(id, name, opt?.value)}
                  >
                    {opt?.label}
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export const MultiSelectBox = ({
  value,
  name = "",
  onChange,
  placeholder = "",
  options = [],
  disabled = false,
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "white",
      borderRadius: "10px",
      border: "1px solid #D0D5DD",
      fontSize: "14px",
      padding: "6px 20px;",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "lightgray" : "white",
      color: "black",
      padding: 10,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  return (
    <div className="text-input-contain">
      <Select
        styles={customStyles}
        value={value}
        isMulti
        name={name}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        isDisabled={disabled}
        className="basic-multi-select"
        classNamePrefix="select"
      />
    </div>
  );
};

export const UploadImgField = ({
  handleInput,
  fileName,
  setFileName,
  imageUrl,
  id=null
}) => {
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const imgInputRef = useRef();

  const uploadLogoImage = (file) => {
    const imgFormData = new FormData();
    imgFormData.append("image", file);

    setUploadingLogo(true);

    axios
      .post(uploadPackageImgUrl, imgFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        setUploadingLogo(false);
        // setCarrierForm({
        //   ...carrierForm,
        //   image_url: response?.data,
        // });
        console.log("af ", id);
        if(id !== null) {
          handleInput(response?.data, id);
        } else {
          handleInput(response?.data);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const triggerLogoFileInput = () => {
    if (!uploadingLogo) {
      imgInputRef.current.click();
    }
  };

  const handleLogoInput = (e) => {
    if (!uploadingLogo) {
      const { files } = e.target;
      setFileName(files[0]?.name);
      uploadLogoImage(files[0]);
    }
  };

  return (
    <div
      className="upload-input-contain"
      style={{ cursor: uploadingLogo && "no-drop" }}
    >
      <div className="w-100">
        <input
          style={{
            visibility: "hidden",
            position: "fixed",
            bottom: 0,
            zIndex: -1,
          }}
          type="file"
          onChange={handleLogoInput}
          ref={imgInputRef}
          accept="image/*"
          name=""
          id=""
        />
        <div className="upload-input-wrap" onClick={triggerLogoFileInput}>
          {uploadingLogo ? (
            <div className="d-flex justify-content-center py-2">
              <span className="spinner-border spinner-grow-sm" />
            </div>
          ) : (
            <div>
              <div className="d-flex justify-content-center mb-2">
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt={fileName}
                    className="upload-img-pic"
                  />
                ) : (
                  <TbPhoto className="photo-icon" />
                )}
              </div>
              {fileName ? (
                <p className="upload-img-filename">{fileName}</p>
              ) : (
                <p className="upload-img-filename">No Image Uploaded</p>
              )}
              <p className="upload-img-filename">Click Here to Upload Image</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const UploadImgField2 = ({
  handleInput,
  fileName,
  setFileName,
  imageUrl,
  id=null
}) => {
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const imgInputRef = useRef();

  const uploadLogoImage = (file) => {
    const imgFormData = new FormData();
    imgFormData.append("image", file);

    setUploadingLogo(true);

    axios
      .post(uploadPackageImgUrl, imgFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        setUploadingLogo(false);
        // setCarrierForm({
        //   ...carrierForm,
        //   image_url: response?.data,
        // });
        console.log("af ", id);
        if(id !== null) {
          handleInput(response?.data, id);
        } else {
          handleInput(response?.data);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const triggerLogoFileInput = () => {
    if (!uploadingLogo) {
      imgInputRef.current.click();
    }
  };

  const handleLogoInput = (e) => {
    if (!uploadingLogo) {
      const { files } = e.target;
      setFileName(files[0]?.name);
      uploadLogoImage(files[0]);
    }
  };

  return (
    <div
      className="upload-input-contain-02"
      style={{ cursor: uploadingLogo && "no-drop" }}
    >
      <div className="w-100 d-flex gap-2">
        <input
          style={{
            visibility: "hidden",
            position: "fixed",
            bottom: 0,
            zIndex: -1,
          }}
          type="file"
          onChange={handleLogoInput}
          ref={imgInputRef}
          accept="image/*"
          name=""
          id=""
        />
        <div className="upload-input-wrap" onClick={triggerLogoFileInput}>
          {uploadingLogo ? (
            <div className="d-flex justify-content-center py-2">
              <span className="spinner-border spinner-grow-sm" />
            </div>
          ) : (
            <div className="d-flex gap-2" >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt={fileName}
                    className="upload-img-pic"
                  />
                ) : (
                  <TbPhoto className="photo-icon" />
                )}
              <div className="d-flex justify-content-center mb-2">
                
              </div>
              {fileName ? (
                <p className="upload-img-filename">{fileName}</p>
              ) : (
                <p className="upload-img-filename">No Image</p>
              )}
              {/* <p className="upload-img-filename">Click Here to Upload Image</p> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const SelectCustomerTextBox = ({
  value,
  name = "",
  onChange,
  suggestions=[],
  placeholder = "",
  disabled = false,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [customerSuggestions, setCustomerSuggestions] = useState([]);
  const [textQuery, setTextQuery] = useState("");

  useEffect(() => {
    let selected_customer = suggestions?.find(cust => parseInt(cust?.id) === parseInt(value));
    let customer = "";
    if(selected_customer) {
      customer = `${selected_customer?.fullname} - ${selected_customer?.email}`;
    }

    setTextQuery(customer);
    setCustomerSuggestions(suggestions);
    // eslint-disable-next-line
  }, [])

  const listRef = useRef();

  const selectOption = (name, value, customer) => {
    onChange(name, value, customerSuggestions);
    setTextQuery(customer);
    setShowDropdown(false);
  };

  const searchForCustomers = (value) => {
    const query = value;

    if (query.toString().trim() === "") {
      return null;
    }

    const formValues = {};
    formValues["query"] = query;
    formValues["limit"] = "5";

    const newData = { ...formValues, ...UserProfileData() };


    axios.post(searchForCustomersURL, newData).then(function (response) {
      console.log(response);

      if (response["data"]["success"] === false) return;

      const customers = response["data"]["data"];

      setCustomerSuggestions(customers);
    });
  };

  const handleInput = (e) => {
    const { value } = e.target;

    setTextQuery(value);
    searchForCustomers(value);
  };

  return (
    <div className="select-text-input-contain">
      {showDropdown && (
        <div
          className={`select-text-close`}
          onClick={(e) => {
            e.stopPropagation();
            setShowDropdown(false);
          }}
        ></div>
      )}
      <div
        className={`select-text-input-contain ${
          showDropdown && "select-text-input-contain-index"
        }`}
      >
        <input
          name={name}
          type="text"
          value={textQuery}
          autoComplete="no"
          onChange={handleInput}
          placeholder={placeholder}
          className={`text-input`}
          onFocus={() => setShowDropdown(true)}
          disabled={disabled}
        />
        {showDropdown ? (
          <BsChevronUp className="select-text-arrow" />
        ) : (
          <BsChevronDown className="select-text-arrow" />
        )}
        {showDropdown && !disabled && (
          <div ref={listRef} className="select-text-dropdown">
            <ul className="select-text-dropdown-list">
              {customerSuggestions?.map((opt) => {
                const fullName = opt.fullname;
                const phoneNumber = opt.phone;
                const emailAddress = opt.email;
                return (
                  <li
                    key={opt?.value}
                    onClick={() => selectOption(name, opt?.id, `${fullName} - ${emailAddress}`)}
                  >
                    <span>{fullName}</span>
                    <div className="d-flex flex-row align-items-center">
                      <span style={{ fontSize: 13, marginTop: -5 }}>
                        {phoneNumber}
                      </span>
                      <BsDot />
                      <span style={{ fontSize: 13, marginTop: -5 }}>
                        {emailAddress}
                      </span>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export const SelectContactTextBox = ({
  value,
  name = "",
  onChange,
  placeholder = "",
  disabled = false,
  handleText
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [customerSuggestions, setCustomerSuggestions] = useState([]);
  const [textQuery, setTextQuery] = useState("");

  useEffect(() => {
    setTextQuery(value);
    getAddressBook();
    // eslint-disable-next-line
  }, [])

  const listRef = useRef();

  const selectOption = (customer) => {
    onChange(customer);
    setTextQuery(customer.full_name);
    setShowDropdown(false);
  };

  const getAddressBook = async () => {
    const newData = { ...UserProfileData() };

    await axios
      .post(getAddressBookUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {

        if (response["data"]["success"] === false) return;

        const addresses = response.data.data

        setCustomerSuggestions(addresses);
      });
  };

  const handleInput = (e) => {
    const { value } = e.target;

    setTextQuery(value);
    handleText(value);
    // searchForCustomers(value);
  };

  return (
    <div className="select-text-input-contain">
      {showDropdown && (
        <div
          className={`select-text-close`}
          onClick={(e) => {
            e.stopPropagation();
            setShowDropdown(false);
          }}
        ></div>
      )}
      <div
        className={`select-text-input-contain ${
          showDropdown && "select-text-input-contain-index"
        }`}
      >
        <input
          name={name}
          type="text"
          value={textQuery}
          onChange={handleInput}
          placeholder={placeholder}
          className={`text-input`}
          onFocus={() => setShowDropdown(true)}
          disabled={disabled}
        />
        {showDropdown ? (
          <BsChevronUp className="select-text-arrow" />
        ) : (
          <BsChevronDown className="select-text-arrow" />
        )}
        {showDropdown && !disabled && (
          <div ref={listRef} className="select-text-dropdown">
            <ul className="select-text-dropdown-list">
              {customerSuggestions?.filter(opt => opt?.full_name?.toLowerCase()?.includes(textQuery?.toLowerCase()))
              ?.map((opt) => {
                const fullName = opt.full_name;
                const phoneNumber = opt.phone_number;
                const emailAddress = opt.email_address;
                return (
                  <li
                    key={opt?.id}
                    onClick={() => selectOption(opt)}
                  >
                    <span>{fullName}</span>
                    <div className="d-flex flex-row align-items-center">
                      <span style={{ fontSize: 13, marginTop: -5 }}>
                        {phoneNumber}
                      </span>
                      <BsDot />
                      <span style={{ fontSize: 13, marginTop: -5 }}>
                        {emailAddress}
                      </span>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};