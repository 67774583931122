import { Col, Row } from "react-bootstrap";
import * as React from "react";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { SessionManagement } from "../../../library/SessionManagement";
import PriceSettingsDialog from "../../../modals/MotorbikePriceSettingsDialog";
import ChangePasswordModal from "../../../modals/ChangePasswordModal";
import { generateTransactionID } from "../../../library/utilities";
import { UserProfileData } from "../../../library/constants";
import axios from "axios";
import {
  createInternationalShipmentURL,
  getAppInfoUrl,
  updateAppInfoUrl,
  updateProfileURL,
  uploadMediaUrl,
} from "../../../library/URLs";
import User from "../../../assets/settings/account_details/user.svg";
import { TbEdit } from "react-icons/tb";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { PhoneFieldBox, TextBox } from "../../../components/InputFields";
import CompanyConfirmationDialog from "../../../modals/CompanyConfirmationDialog";

const SubmitButton = styled.button`
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #fcd2d2;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #c2eddd;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
`;

const CompanyDetails = () => {
  const [companyInfo, setCompanyInfo] = useState({
    app_name: SessionManagement.getCompanyName(),
    support_phone: "",
    support_email: "",
    app_image_url: "",
  });
  const fileInputRef = useRef(null);
  const [uploadLoading, setUploadLoading] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [editCompany, setEditCompany] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);
  const [showCompanyDialog, setShowCompanyDialog] = useState(false);

  const [changePasswordDialogVisibility, setChangePasswordDialogVisibility] =
    useState(false);

  const profileImageURL = SessionManagement.getCompanyLogoURL();

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    getAppInfo();
    // eslint-disable-next-line
  }, []);

  const getAppInfo = () => {
    const newData = { ...UserProfileData() };

    console.log(newData);

    axios
      .post(getAppInfoUrl, newData)
      .then(function (response) {
        if (response?.data?.success) {
          let response_data = response?.data?.data;
          setCompanyInfo({
            ...companyInfo,
            app_image_url: response_data?.logo_url,
            app_name: response_data?.name,
            support_email: response_data?.support_email,
            support_phone: response_data?.support_phone,
          });
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const handleCloseModal = () => {
    setChangePasswordDialogVisibility(false);
  };

  const form = useRef(null);

  const uploadImage = (file) => {
    setErrorVisibility(false);

    const formData = new FormData();
    formData.append("document", file);

    setUploadLoading(true);

    axios
      .post(uploadMediaUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        console.log(response);
        setUploadLoading(false);
        setCompanyInfo({
          ...companyInfo,
          app_image_url: response.data,
        });
      })
      .catch(function (error) {
        return error;
      });
  };

  const handleFileInput = (e) => {
    const { files } = e.target;
    uploadImage(files[0]);
  };

  const updateAppInfo = (e) => {
    e.preventDefault();

    setErrorVisibility(false);

    const formValues = { ...companyInfo };

    const newData = { ...formValues, ...UserProfileData() };

    console.log(newData);

    setIsUpdating(true);

    axios
      .post(updateAppInfoUrl, newData)
      .then(function (response) {
        console.log(response);
        setIsUpdating(false);

        if (response["data"]["success"] === false) {
          window.scrollTo(0, 0);
          setErrorVisibility(true);
          setErrorMessage(response["data"]["message"]);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        } else if (response["data"]["success"] === true) {
          window.scrollTo(0, 0);
          setEditCompany(false);

          SessionManagement.setCompanyName(companyInfo.app_name);
          SessionManagement.setCompanyLogoURL(companyInfo.app_image_url);

          setSuccessVisibility(true);
          setSuccessMessage(
            "Company Information has been updated successfully."
          );

          setTimeout(() => {
            setSuccessVisibility(false);
          }, 3000);
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    setCompanyInfo({
      ...companyInfo,
      [name]: value,
    });
  };

  const openCompanyConfirmation = (e) => {
    e.preventDefault();

    setShowCompanyDialog(true);
  };

  const closeCompanyConfirmation = (e) => {
    e.preventDefault();

    setShowCompanyDialog(false);
  };

  const handlePhoneInput = (code) => {
    setCompanyInfo({
      ...companyInfo,
      support_phone: code,
    });
  };

  return (
    <>
      {isError === false ? null : (
        <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
      )}

      {isSuccess === false ? null : (
        <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
      )}

      <CompanyConfirmationDialog
        isModalVisible={showCompanyDialog}
        handleCloseDialog={closeCompanyConfirmation}
        isLoading={isUpdating}
        updateComapanyInfo={updateAppInfo}
      />

      <div className="settings_section">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex gap-3 align-items-start">
            <img src={User} alt="user" className="mt-2" />
            <div>
              <p style={{ fontSize: 22, marginBottom: 8, fontWeight: "bold" }}>
                Company Details
              </p>
              <span>Edit your company information below</span>
            </div>
          </div>
          {editCompany ? (
            <div
              style={{ marginTop: 20 }}
              onClick={() => setEditCompany(false)}
              className=" settings_btn"
            >
              {" "}
              Cancel
            </div>
          ) : (
            <div
              style={{ marginTop: 20 }}
              onClick={() => setEditCompany(true)}
              className=" settings_btn"
            >
              {" "}
              <TbEdit /> Edit Details
            </div>
          )}
        </div>
        <form ref={form}>
          <div className="row mt-4">
            <div className="col-xl-4 col-md-6 mt-4 mt-md-0 ps-md-5 col-sm-12 col">
              <div className="d-flex flex-column align-items-start">
                <div
                  style={{
                    width: "fit-content",
                    height: "fit-content",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <img
                    style={{ width: 110, height: 110, borderRadius: "50%" }}
                    src={
                      companyInfo.app_image_url === ""
                        ? profileImageURL
                        : companyInfo.app_image_url
                    }
                    alt="Company logo"
                  />
                  {uploadLoading && (
                    <div
                      className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
                      style={{ backgroundColor: "#FFFFFF66", zIndex: 4 }}
                    >
                      <span className="spinner-border spinner-grow-sm" />
                    </div>
                  )}
                </div>
                <span style={{ marginTop: 15, fontSize: 14 }}>
                  Change company profile image.
                </span>
                <input
                  style={{
                    visibility: "hidden",
                    position: "fixed",
                    bottom: 0,
                    zIndex: -1,
                  }}
                  type="file"
                  onChange={handleFileInput}
                  ref={fileInputRef}
                  disabled={!editCompany}
                  accept="image/*"
                  name=""
                  id=""
                />
                <div
                  style={{ marginTop: 20 }}
                  onClick={triggerFileInput}
                  className=" settings_btn"
                >
                  <MdOutlineAddPhotoAlternate /> Choose Image
                </div>
              </div>
            </div>

            <div className="col-xl-8 col-md-6 col-12 col">
              <Row>
                <Col className="form-group col-xl-12 col-12 col-md-12">
                  <label htmlFor="Business name">Business Name</label>
                  <TextBox
                    name="app_name"
                    type="text"
                    value={companyInfo.app_name}
                    disabled={!editCompany}
                    onChange={handleInput}
                    placeholder=""
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="form-group col-12 col-xl-6 mb-3 mb-md-0 col-md-6">
                  <label htmlFor="Email Address">Contact Email Address</label>
                  <TextBox
                    name="support_email"
                    disabled={!editCompany}
                    type="email"
                    value={companyInfo.support_email}
                    onChange={handleInput}
                  />
                </Col>

                <Col className="form-group col-12 col-xl-6 col-md-6">
                  <label htmlFor="Email">Contact Phone Number</label>
                  <PhoneFieldBox
                    disabled={!editCompany}
                    value={companyInfo.support_phone}
                    onChange={handlePhoneInput}
                  />
                </Col>
              </Row>
            </div>

            <div className="d-flex flex-row my-5 justify-content-end">
              <SubmitButton
                disabled={uploadLoading || !editCompany}
                type="submit"
                onClick={openCompanyConfirmation}
                className="btn-primary"
              >
                Update
              </SubmitButton>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CompanyDetails;
