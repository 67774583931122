import { Col, Modal, Row } from "react-bootstrap";
import { BiX } from "react-icons/bi";

const RatesDocumentDialog = ({
  isModalVisible,
  handleCloseDialog
}) => {
  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">RATES DOCUMENT SAMPLES</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="query-result-container">
          <p>
            Here are some samples of pricing templates you can upload in excel format
          </p>
          <Row className="gy-5" >
            <Col md={6} >
              <div className="">
                <label htmlFor="Description" className="mb-1">
                  Aramex Rate Card
                </label>
                <a href="http://admin.parcelflow.site/engine/v1/actions/rates/sample-aramex.xlsx" target="_blank" rel="noreferrer">
                  <button className="btn-primary" >
                    Open Template
                  </button>
                </a>
              </div>
            </Col>
            <Col md={6} >
              <div className="">
                <label htmlFor="Description" className="mb-1">
                  DHL Rate Card
                </label>
                <a href="http://admin.parcelflow.site/engine/v1/actions/rates/sample-dhl.xlsx" target="_blank" rel="noreferrer">
                  <button className="btn-primary" >
                    Open Template
                  </button>
                </a>
              </div>
            </Col>
            <Col md={6} >
              <div className="">
                <label htmlFor="Description" className="mb-1">
                  Fedex Rate Card
                </label>
                <a href="http://admin.parcelflow.site/engine/v1/actions/rates/sample-fedex.xlsx" target="_blank" rel="noreferrer">
                  <button className="btn-primary" >
                    Open Template
                  </button>
                </a>
              </div>
            </Col>
            <Col md={6} >
              <div className="">
                <label htmlFor="Description" className="mb-1">
                  UPS Rate Card
                </label>
                <a href="http://admin.parcelflow.site/engine/v1/actions/rates/sample-ups.xlsx" target="_blank" rel="noreferrer">
                  <button className="btn-primary" >
                    Open Template
                  </button>
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RatesDocumentDialog;
