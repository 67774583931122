import "../styles/home.scss";
import "../styles/shipments.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserProfileData } from "../library/constants";
import {
  getAllCustomerWalletURL,
  getCustomerWalletHistoryURL,
  getStationsURL,
  getTransactionsURL,
  searchForCustomersURL,
} from "../library/URLs";
import axios from "axios";
import styled from "styled-components";
import * as React from "react";
import moment from "moment";
import * as BiIcons from "react-icons/bi";
import { GoPrimitiveDot } from "react-icons/go";
import CustomDateRangeDialog from "../modals/CustomDateRangeDialog";
import SubscriptionInactive from "../components/SubscriptionInactive";
import { BsDot } from "react-icons/bs";
import { Col, Row } from "react-bootstrap";
import DeleteStationDialog from "../modals/DeleteStationDialog";
import StationsIcon from "../assets/sidebar/Stations.svg";
import StationInfo from "./Station/StationInfo";
import StationStaffs from "./Station/StationStaffs";
import StationShipments from "./Station/StationShipments";
import StationReports from "./Station/StationReports";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const SingleStation = () => {
  const navigator = useNavigate();
  const params = useParams();
  const stationID = params["stationID"];

  const [isLoading, setIsLoading] = useState(true);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [isRetrievingMore, setIsRetrievingMore] = useState(false);
  const [deleteStation, setDeleteStation] = useState(false);
  const [stationTab, setStationTab] = useState("employees");

  const [station, setStation] = useState({});

  const data = {
    limit: 40,
    offset: currentOffset,
    status: "All",
  };

  const newData = { ...data, ...UserProfileData() };

  useEffect(() => {
    axios
      .post(getStationsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (isLoading === false && isRetrievingMore === false) {
          console.log("Should not retrieve");
          return;
        }
        console.log(response);

        setIsRetrievingMore(false);
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        const newCustomers = response["data"]["data"]["data"]?.find(
          (item) => item.id === parseInt(stationID)
        );

        setStation(newCustomers);
      });
  }, []);

  const handleTabs = (option) => {
    setStationTab(option);
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <DeleteStationDialog
            isModalVisible={deleteStation}
            handleCloseDialog={() => setDeleteStation(false)}
            stationID={stationID}
          />
          <SubscriptionInactive />
          <div className="container-fluid shipments-container">
            <div className="card border-0 p-4 min-vh-100 bg-white">
              <div className="mb-4">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={StationsIcon} alt="shipments" />
                  <h5 className="mb-2">{station?.station_name} Station</h5>
                </div>
              </div>
              <div className="d-grid mb-4 d-md-flex justify-content-end gap-2">
                <input
                  className="btn-primary"
                  type="button"
                  onClick={() =>
                    navigator("/stations/edit-station/" + stationID)
                  }
                  value="EDIT STATION"
                />

                <input
                  className="btn-error"
                  type="button"
                  onClick={() => setDeleteStation(true)}
                  value="DELETE"
                />
              </div>
              {!isLoading && (
                <div>
                  <div className="row admin-wrapper">
                    <Col lg={4}>
                      <div
                        className="card border-0"
                        style={{ background: "transparent" }}
                      >
                        <div className="card-body shipper-container">
                          <p style={{ fontSize: 16, fontWeight: 500 }}>
                            Station Info
                          </p>
                          <StationInfo station={station} />
                        </div>
                      </div>
                    </Col>
                    <Col lg={8}>
                      <div
                        className="card border-0"
                        style={{ background: "transparent" }}
                      >
                        <div className="card-body shipper-container">
                          <div className="single-station-tabs">
                            <div
                              onClick={() => handleTabs("employees")}
                              className={`${
                                stationTab === "employees"
                                  ? "station-tab-active"
                                  : "station-tab"
                              }`}
                            >
                              Employees
                            </div>
                            <div
                              onClick={() => handleTabs("shipments")}
                              className={`${
                                stationTab === "shipments"
                                  ? "station-tab-active"
                                  : "station-tab"
                              }`}
                            >
                              Shipments
                            </div>
                            <div
                              onClick={() => handleTabs("reports")}
                              className={`${
                                stationTab === "reports"
                                  ? "station-tab-active"
                                  : "station-tab"
                              }`}
                            >
                              Report
                            </div>
                          </div>
                          <div>
                            {
                              stationTab === "employees" ? (
                                <StationStaffs />
                              ) : stationTab === "shipments" ? (
                                <StationShipments />
                              ) : stationTab === "reports" ? (
                                <StationReports />
                              ) : null
                            }
                          </div>
                        </div>
                      </div>
                    </Col>
                  </div>
                </div>
              )}

              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleStation;
