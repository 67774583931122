import React, { useEffect, useState } from "react";
import { getRedstarAccountsUrl } from "../../../../library/URLs";
import axios from "axios";
import { UserProfileData } from "../../../../library/constants";
import { TbPencil, TbTrash } from "react-icons/tb";
import { Tooltip } from "@mui/material";
import styled from "styled-components";
import { BsCheckCircleFill, BsPlusCircleFill } from "react-icons/bs";
import AddRedstarAccountDialog from "../../../../modals/AddRedstarAccountDialog";
import EditRedstarAccountDialog from "../../../../modals/EditRedstarAccountDialog";
import DeleteRedstarAccountDialog from "../../../../modals/DeleteRedstarAccountDialog";

const LoadingContainer = styled.div`
  height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const Redstar = ({ opened }) => {
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addRedstarAccount, setAddRedstarAccount] = useState(false);
  const [editRedstarAccount, setEditRedstarAccount] = useState(null);
  const [deleteRedstarAccount, setDeleteRedstarAccount] = useState(null);

  const openAddRedstarAccount = () => {
    setAddRedstarAccount(true);
  };

  const closeAddRedstarAccount = () => {
    setAddRedstarAccount(false);
  };

  const openEditRedstarAccount = (account) => {
    setEditRedstarAccount(account);
  };

  const closeEditRedstarAccount = () => {
    setEditRedstarAccount(null);
  };

  const openDeleteRedstarAccount = (id) => {
    setDeleteRedstarAccount(id);
  };

  const closeDeleteRedstarAccount = () => {
    setDeleteRedstarAccount(null);
  };

  const fetchAccounts = async () => {
    setIsLoading(true);
    await axios
      .post(getRedstarAccountsUrl, UserProfileData())
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.success) {
          let redstar_accounts = res?.data?.data;

          setAccounts(redstar_accounts);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (opened) {
      fetchAccounts();
    }
  }, [opened]);

  return (
    <div>
      <AddRedstarAccountDialog 
        isModalVisible={addRedstarAccount}
        handleCloseDialog={closeAddRedstarAccount}
        onSubmit={fetchAccounts}
      />
      <EditRedstarAccountDialog 
        isModalVisible={editRedstarAccount !== null}
        handleCloseDialog={closeEditRedstarAccount}
        onSubmit={fetchAccounts}
        editAccount={editRedstarAccount}
      />
      <DeleteRedstarAccountDialog 
        isModalVisible={deleteRedstarAccount !== null}
        handleCloseDialog={closeDeleteRedstarAccount}
        onSubmit={fetchAccounts}
        accountID={deleteRedstarAccount}
      />
      {isLoading ? (
        <LoadingContainer>
          <LoadingSpinner className="spinner-border spinner-grow-sm" />
        </LoadingContainer>
      ) : (
        <ul className="p-0" style={{listStyle: "none"}} >
          {accounts?.length < 1 ? (
            <li className="mb-4" >
              No account available
            </li>
          ) : accounts?.map((redstar) => (
            <li
              key={redstar?.id}
              className="d-flex justify-content-between mb-4 align-items-center"
            >
              <label className="form-check-label d-flex gap-4 align-items-center" htmlFor="">
                {redstar?.name}
                {
                  redstar?.is_primary_account === "Yes" && (
                    <BsCheckCircleFill style={{color: "#0F973D", fontSize: 12}} />
                  )
                }
              </label>
              <div className="d-flex gap-4 align-items-center">
                <Tooltip title="Edit Account" placement="top">
                  <span className="d-flex align-items-start">
                    <TbPencil
                      style={{ fontSize: 18, cursor: "pointer" }}
                      onClick={() => openEditRedstarAccount(redstar)}
                    />
                  </span>
                </Tooltip>
                <Tooltip title="Delete Account" placement="top">
                  <span className="d-flex align-items-start">
                    <TbTrash
                      style={{ fontSize: 18, cursor: "pointer" }}
                      onClick={() => openDeleteRedstarAccount(redstar?.id)}
                    />
                  </span>
                </Tooltip>
              </div>
            </li>
          ))}
          <button
            className="mt-2 add-carrier"
            type="button"
            onClick={openAddRedstarAccount}
          >
            <BsPlusCircleFill /> Add Account
          </button>
        </ul>
      )}
    </div>
  );
};

export default Redstar;