import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  BsChevronLeft,
  BsChevronRight,
  BsQuestionCircleFill,
} from "react-icons/bs";
import styled from "styled-components";
import { CheckBox, RadioCheck } from "../../../components/InputFields";
import { FaClipboardList } from "react-icons/fa";

const ParcelCommerce = ({
  prevPage,
  answers,
  setAnswers,
  handleSubmit,
  isSubmitting,
}) => {
  const PageHeader = styled.h4`
    font-family: Prompt, sans-serif;
    font-size: 32px;
    line-height: 28.8px;
    margin-bottom: 8px;
    color: #000;

    @media (max-width: 556px) {
      font-size: 2.4rem;
    }
  `;

  const PageSubheader = styled.h4`
    font-size: 14px;
    color: #667185;
    margin-bottom: 48px;
    font-weight: normal;
  `;

  const SubLight = styled.span`
    color: #667185;
  `;

  const que1Answer = (e) => {
    const { value } = e.target;

    setAnswers({
      ...answers,
      parcelflow_commerce: {
        ...answers?.parcelflow_commerce,
        web_url: value,
      },
    });
  };

  const que2Answer = (answer) => {
    setAnswers({
      ...answers,
      parcelflow_commerce: {
        ...answers?.parcelflow_commerce,
        parcel_connect: answer,
      },
    });
  };

  const que3Answer = (e) => {
    const { value } = e.target;
    setAnswers({
      ...answers,
      parcelflow_commerce: {
        ...answers?.parcelflow_commerce,
        sales_product: value,
      },
    });
  };

  // const submit = (e) => {
  //   e.preventDefault();
  //   console.log(" parcelcommerce answers ", answers);
  //   handleSubmit(e);
  // };

  return (
    <>
      <PageHeader className="feature-header page-header">
        Operations - <SubLight>Parcelflow Commerce</SubLight>
      </PageHeader>
      <PageSubheader>
        This information will be used to assist you in setting up your account
        and would never be shared
      </PageSubheader>
      <div className="progress-bar">
        <div className={`progress-bar-inactive progress-bar-active`}></div>
        <div className={`progress-bar-active`}></div>
      </div>
      <form>
        <div className="question-wrap mb-3">
          <div className="question-text">
            <BsQuestionCircleFill />
            Enter the url of your ecommerce website
          </div>
          <input
            type="text"
            name="web_url"
            value={answers?.parcelflow_commerce?.web_url}
            onChange={que1Answer}
            placeholder="Enter your answer"
            className="question-textbox"
          />
        </div>
        <div className="question-wrap mb-3">
          <div className="question-text">
            <BsQuestionCircleFill />
            How do you intend to connect to Parcelflow?
          </div>
          <ul className="multi-answer">
            <li>
              <RadioCheck
                checked={
                  answers?.parcelflow_commerce?.parcel_connect ===
                  "Use Parcelflow plugin for WooCommerce"
                }
                onChange={() =>
                  que2Answer("Use Parcelflow plugin for WooCommerce")
                }
              />
              Use Parcelflow plugin for WooCommerce
            </li>
            <li>
              <RadioCheck
                checked={
                  answers?.parcelflow_commerce?.parcel_connect ===
                  "Use Parcelflow plugin for Shopify"
                }
                onChange={() => que2Answer("Use Parcelflow plugin for Shopify")}
              />
              Use Parcelflow plugin for Shopify
            </li>
            <li>
              <RadioCheck
                checked={
                  answers?.parcelflow_commerce?.parcel_connect ===
                  "Use API directly "
                }
                onChange={() => que2Answer("Use API directly ")}
              />
              Use API directly
            </li>
          </ul>
        </div>
        {/* <div className="question-wrap mb-3" >
          <div className="question-text" >
            <FaClipboardList />
            What do you want to sell on your website?
          </div>
          <div>
            <textarea 
              type="text" 
              name="sales_product" 
              value={answers?.parcelflow_commerce?.sales_product} 
              onChange={que3Answer}
              className="question-textarea"
              placeholder="Enter your answer"
              rows={3}
            ></textarea>
          </div>
        </div> */}
        <div className="d-flex flex-row my-5 gap-4 justify-content-end">
          <button type="button" className="btn-secondary" onClick={prevPage}>
            <BsChevronLeft />
          </button>
          <button
            type="submit"
            onClick={handleSubmit}
            className="login-button btn-primary rounded"
          >
            {isSubmitting === false ? (
              <span className="button-text d-flex align-items-center justify-content-center">
                Proceed <BsChevronRight />
              </span>
            ) : (
              <span className="spinner-border spinner-grow-sm" />
            )}
          </button>
        </div>

        {/* <PageSubheader>
                      Already have an account? <a href="/login">Login</a>
                    </PageSubheader> */}
      </form>
    </>
  );
};

export default ParcelCommerce;
