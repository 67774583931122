import { Button, Col, Modal, Row } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { getAllCarriersUrl } from "../library/URLs";
import { UserProfileData } from "../library/constants";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { BiX } from "react-icons/bi";
import * as React from "react";
import { SessionManagement } from "../library/SessionManagement";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { TextBox } from "../components/InputFields";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import { shortenTexts } from "../components/reusables";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const LoadingContainer = styled.div`
  height: 365px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;
// Note: call get all carriers here to list out processors.
const SearchShipmentsDialog = ({
  isModalVisible,
  handleCloseDialog,
  advanceQueries,
  handleAdvanceSearchQuery,
  setAdvanceSearchQueries,
  loading,
}) => {
  const [localShipment, setLocalShipment] = useState(false);
  const [interstateShipment, setInterstateShipment] = useState(false);
  const [internationalShipment, setInternationalShipment] = useState(false);
  const [carriers, setCarriers] = useState([]);

  const getAllCarriers = async () => {
    const data = { ...UserProfileData() };

    await axios.post(getAllCarriersUrl, data).then((res) => {
      if (res.data?.success === false) {
        toast.error(res.data?.message);
        return;
      }

      setCarriers(res.data?.data);
    });
  };

  useEffect(() => {
    getAllCarriers();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    handleAdvanceSearchQuery();
    // handleCloseDialog();
  };

  const [paidShipment, setPaidShipment] = useState(false);
  const [unpaidShipment, setUnpaidShipment] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const toggleShipmentType = (e, type) => {
    if (type === "localShipment") {
      setLocalShipment(!localShipment);
    } else if (type === "interstateShipment") {
      setInterstateShipment(!interstateShipment);
    } else if (type === "internationalShipment") {
      setInternationalShipment(!internationalShipment);
    }
  };

  const toggleShipmentPaidStatus = (e, type) => {
    if (type === "paidShipment") {
      setPaidShipment(!paidShipment);
    } else if (type === "unpaidShipment") {
      setUnpaidShipment(!unpaidShipment);
    }
  };

  const handleInput = (e) => {
    const { value, name } = e.target;

    setAdvanceSearchQueries({
      ...advanceQueries,
      [name]: value,
    });
  };

  const handleShipmentType = (shipment) => {
    let shipmentCopy = [...advanceQueries?.shipment_types];
    if (shipmentCopy?.includes(shipment)) {
      shipmentCopy = shipmentCopy.filter((ship) => ship !== shipment);
    } else {
      shipmentCopy.push(shipment);
    }

    setAdvanceSearchQueries({
      ...advanceQueries,
      shipment_types: shipmentCopy,
    });
  };

  const handleShipmentStatus = (shipment) => {
    let shipmentCopy = [...advanceQueries?.shipment_status];
    if (shipmentCopy?.includes(shipment)) {
      shipmentCopy = shipmentCopy.filter((ship) => ship !== shipment);
    } else {
      shipmentCopy.push(shipment);
    }

    setAdvanceSearchQueries({
      ...advanceQueries,
      shipment_status: shipmentCopy,
    });
  };

  const handleShipmentCarriers = (carrier) => {
    let carriersCopy = [...advanceQueries?.carriers];
    if (carriersCopy?.includes(carrier)) {
      carriersCopy = carriersCopy.filter((processor) => processor !== carrier);
    } else {
      carriersCopy.push(carrier);
    }

    setAdvanceSearchQueries({
      ...advanceQueries,
      carriers: carriersCopy,
    });
  };

  // const validate = () => {
  //     // Untouched state of advance filter search
  //     let validateState = {
  //         receiver_name: "",
  //         receiver_address: "",
  //         shipment_types: [],
  //         carriers: [],
  //         start_date: "",
  //         end_date: "",
  //         start_date_format: "",
  //         end_date_format: "",
  //         shipment_number: "",
  //         "3pl_tracking_number": "",
  //     };
  //      // Deep comparison of advanceSearchQueries and validateState
  //     for (let key in advanceQueries) {
  //         if (advanceQueries[key] !== validateState[key]) {
  //             return false; // Return false if any property value differs
  //         }
  //     }

  //     return true; // Return true if all properties are identical
  // };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 style={{ marginTop: 10 }} className="header">
          SEARCH SHIPMENTS
        </h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <div>
          <Row className="mt-3">
            {/* <Col>
              <label htmlFor="Receiver Name">Receiver Name</label>
              <TextBox
                name="receiver_name"
                value={advanceQueries?.receiver_name}
                onChange={handleInput}
                type="text"
              />
            </Col> */}
            {/* <Col>
                            <label htmlFor="Receiver Address">Receiver Address</label>
                            <TextBox 
                                name="receiver_address"
                                value={advanceQueries?.receiver_address}
                                onChange={handleInput}
                                type="text"
                            />
                        </Col> */}
          </Row>

          <Row className="mt-3">
            <span>Shipment Type</span>
            <Col className="col-12 col-md-4">
              <input
                name="intracity_truck"
                checked={advanceQueries?.shipment_types?.includes("Local")}
                onChange={() => handleShipmentType("Local")}
                className="form-check-input"
                type="checkbox"
                value="1"
              />
              <label className="form-check-label" htmlFor="localShipment">
                Local
              </label>
            </Col>
            <Col className="col-12 col-md-4">
              <input
                name="interstate_shipment"
                checked={advanceQueries?.shipment_types?.includes("Interstate")}
                onChange={() => handleShipmentType("Interstate")}
                className="form-check-input"
                type="checkbox"
                value="1"
              />
              <label className="form-check-label" htmlFor="interstateShipment">
                Interstate
              </label>
            </Col>
            <Col className="col-12 col-md-4">
              <input
                name="international_shipment"
                checked={advanceQueries?.shipment_types?.includes(
                  "International"
                )}
                onChange={() => handleShipmentType("International")}
                className="form-check-input"
                type="checkbox"
                value="1"
              />
              <label
                className="form-check-label"
                htmlFor="internationalShipment"
              >
                International
              </label>
            </Col>
          </Row>

          {/* <Row className="mt-3">
            <Col>
              <label htmlFor="Receiver Name">Shipment ID/Number</label>
              <TextBox
                name="shipment_number"
                type="text"
                value={advanceQueries?.shipment_number}
                onChange={handleInput}
              />
            </Col>
            <Col>
              <label htmlFor="Receiver Address">Carrier Tracking Number</label>
              <TextBox
                name="3pl_tracking_number"
                type="text"
                value={advanceQueries["3pl_tracking_number"] ?? ""}
                onChange={handleInput}
              />
            </Col>
          </Row> */}

          <Row className="mt-3 gy-md-3">
            <span>Shipment Status</span>
            <Col className="col-12 col-md-4">
              <input
                name="intracity_truck"
                checked={advanceQueries?.shipment_status?.includes("Pending Approval")}
                onChange={() => handleShipmentStatus("Pending Approval")}
                className="form-check-input"
                type="checkbox"
                value="1"
              />
              <label className="form-check-label" htmlFor="localShipment">
                Pending Approval
              </label>
            </Col>
            <Col className="col-12 col-md-4">
              <input
                name="interstate_shipment"
                checked={advanceQueries?.shipment_status?.includes("Approved")}
                onChange={() => handleShipmentStatus("Approved")}
                className="form-check-input"
                type="checkbox"
                value="1"
              />
              <label className="form-check-label" htmlFor="interstateShipment">
                Approved
              </label>
            </Col>
            <Col className="col-12 col-md-4">
              <input
                name="international_shipment"
                checked={advanceQueries?.shipment_status?.includes(
                  "In Transit"
                )}
                onChange={() => handleShipmentStatus("In Transit")}
                className="form-check-input"
                type="checkbox"
                value="1"
              />
              <label
                className="form-check-label"
                htmlFor="internationalShipment"
              >
                In Transit
              </label>
            </Col>

            <Col className="col-12 col-md-4">
              <input
                name="international_shipment"
                checked={advanceQueries?.shipment_status?.includes(
                  "Delivered"
                )}
                onChange={() => handleShipmentStatus("Delivered")}
                className="form-check-input"
                type="checkbox"
                value="1"
              />
              <label
                className="form-check-label"
                htmlFor="internationalShipment"
              >
                Delivered
              </label>
            </Col>
          </Row>

          <Row className="mt-3 gy-md-3">
            <span>Carrier Processor</span>
            {carriers.map((carrier) => (
              <Col className="col-12 col-md-4" key={carrier.id}>
                <input
                  name="intracity_truck"
                  checked={advanceQueries?.carriers?.includes(`${carrier.id}`)}
                  onChange={() => handleShipmentCarriers(`${carrier.id}`)}
                  className="form-check-input"
                  type="checkbox"
                  value="1"
                />
                <Tooltip title={`${carrier.masked_name} (${carrier.shipment_type})`} placement="top">
                  <label className="form-check-label" htmlFor="localShipment">
                    {shortenTexts(carrier.masked_name, 12)}
                  </label>
                </Tooltip>
              </Col>
            ))}
          </Row>

          {/* <Row className="mt-3">
            <Col>
              <label htmlFor="Pickup Location">Start Date</label>
              <ReactDatePicker
                name="start_date"
                className="form-control"
                selected={advanceQueries?.start_date_format}
                dateFormat="P"
                onChange={(date) => {
                  setAdvanceSearchQueries({
                    ...advanceQueries,
                    start_date_format: date,
                    start_date: moment(date)?.format("MM/DD/YYYY"),
                  });
                }}
              />
            </Col>
            <Col>
              <label htmlFor="Dropoff Location">End Date</label>
              <ReactDatePicker
                name="end_date"
                className="form-control"
                selected={advanceQueries?.end_date_format}
                dateFormat="P"
                onChange={(date) => {
                  setAdvanceSearchQueries({
                    ...advanceQueries,
                    end_date_format: date,
                    end_date: moment(date)?.format("MM/DD/YYYY"),
                  });
                }}
              />
            </Col>
          </Row> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary mx-3" onClick={handleCloseDialog}>
          Cancel
        </button>
        <SubmitButton
          onClick={handleSubmit}
          type="submit"
          className="btn-primary"
        >
          {loading === false ? (
            <span className="button-text">Search Shipments</span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default SearchShipmentsDialog;
