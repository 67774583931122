import "../../styles/home.scss";
import "../../styles/reports.scss";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserProfileData } from "../../library/constants";
import { getShipmentsURL, getShipmentReportURL } from "../../library/URLs";
import axios from "axios";
import styled from "styled-components";
import moment from "moment";
import * as BiIcons from "react-icons/bi";
import dataIcon from "../../images/no-data.png";
import { Dropdown } from "react-bootstrap";
import { saveAs } from "file-saver";
import { utils, write } from "xlsx";
import * as ExcelJS from "exceljs";
import { SelectBox, TextBox } from "../../components/InputFields";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import ExportIcon from "../../assets/shipments/file_save.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import ReportIcon from "../../assets/reports/ReportIcon.svg";
import { customerAddress } from "../../library/utilities";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const ShipmentReports = () => {
  const navigator = useNavigate();

  const [reportType, setReportType] = useState("");
  const [reportTime, setReportTime] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  const [isOptionSelected, setIsOptionSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [isRetrievingMore, setIsRetrievingMore] = useState(false);

  const [shipments, setShipments] = useState([]);

  const [openExport, setOpenExport] = useState(null);
  const open = Boolean(openExport);
  const handleClickExport = (event) => {
    setOpenExport(event.currentTarget);
  };
  const handleClose = () => {
    setOpenExport(null);
  };

  const data = {
    limit: 30,
    offset: currentOffset,
    report_type: reportType,
    report_time: reportTime,
    status: "All",
  };

  const newData = { ...data, ...UserProfileData() };

  const getReports = () => {
    if (reportTime !== "" && reportType !== "") {
      setIsLoading(true);
      setCurrentOffset(0);
      setIsOptionSelected(reportType + reportTime);
    }
  };

  const fetchShipmentReport = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const reportData = {
      ...UserProfileData(),
      // "type": "Today||Week||Month||Year||2023-08-01|202-08-04",
      type:
        reportTime === "custom"
          ? `${reportStartDate}|${reportEndDate}`
          : reportTime,
      mode: "web",
    };
    if (
      (reportTime === "custom" &&
        reportStartDate !== "" &&
        reportEndDate !== "") ||
      reportTime !== "custom"
    ) {
      axios
        .post(getShipmentReportURL, reportData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          setIsRetrievingMore(false);
          setIsLoading(false);

          if (response["data"]["success"] === false) return;

          const newShipments = response["data"]["data"];

          const index = newShipments[newShipments.length - 1].id;
          setShipments(newShipments);

          setCurrentOffset(index);

          // if (currentOffset === 0){
          //   setShipments(newShipments);
          // } else {
          //   setShipments([...shipments, ...newShipments]);
          // }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const onChangeReportType = (event) => {
    setReportType(event.target.value);
  };

  const onChangeReportTime = (event) => {
    setReportTime(event.target.value);
  };

  const handleClick = (shipmentID) => {
    navigator("/shipment/" + shipmentID);
  };

  const setScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      isRetrievingMore === false
    ) {
      setIsRetrievingMore(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", setScroll);
    return () => {
      window.removeEventListener("scroll", setScroll);
    };
  }, []);

  const exportToXlsx = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Report");
    sheet.properties.defaultRowHeight = 40;

    sheet.getRow(1).border = {
      // top: { style: "thick", color: { argb: "074B8A" } },
      // left: { style: "thick", color: { argb: "074B8A" } },
      bottom: { style: "thick", color: { argb: "074B8A" } },
      right: { style: "thin", color: { argb: "074B8A" } },
    };

    sheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "008561" },
    };

    sheet.getRow(1).font = {
      // name: "Comic Sans MS",
      // family: 4,
      size: 16,
      bold: true,
      color: { argb: "000000" },
    };

    sheet.columns = [
      {
        header: "S/N",
        key: "sn",
        width: 5,
      },
      {
        header: "SHIPMENT ID",
        key: "id",
        width: 30,
      },
      {
        header: "SENDER TYPE",
        key: "shipment_type",
        width: 40,
      },
      {
        header: "DATE",
        key: "date_time",
        width: 40,
      },
      {
        header: "SENDER NAME",
        key: "sender_name",
        width: 40,
      },
      {
        header: "SENDER PHONE NUMBER",
        key: "sender_phone_no",
        width: 40,
      },
      {
        header: "PICKUP ADDRESS",
        key: "pick_up_location",
        width: 60,
      },
      { header: "RECEIVER NAME", key: "receiver_name", width: 40 },
      { header: "RECEIVER PHONE NUMBER", key: "receiver_phone_no", width: 40 },
      {
        header: "DROPOFF ADDRESS",
        key: "drop_off_location",
        width: 60,
      },
      {
        header: "AMOUNT",
        key: "total_amount",
        width: 60,
      },
      // {
      //   header: "PICKUP",
      //   key: "pickup",
      //   width: 40,
      // },
      // {
      //   header: "DROPOFF",
      //   key: "dropoff",
      //   width: 40,
      // },
      {
        header: "PAYMENT METHOD",
        key: "payment_method",
        width: 40,
      },
      {
        header: "PAYMENT STATUS",
        key: "paid_status",
        width: 40,
      },
      {
        header: "SHIPMENT STATUS",
        key: "status",
        width: 40,
      },
      {
        header: "WEIGHT",
        key: "weight",
        width: 40,
      },
      {
        header: "ITEM DESCRIPTION",
        key: "item_name",
        width: 70,
      },
      {
        header: "ITEM VALUE",
        key: "item_value",
        width: 40,
      },
      {
        header: "APPROVED BY",
        key: "approved_by",
        width: 40,
      },
      {
        header: "SHIPMENT TRACKING NUMBER",
        key: "tracking_numbers",
        width: 40,
      },
      {
        header: "RIDER",
        key: "rider",
        width: 40,
      },
      {
        header: "CARRIER",
        key: "carrier",
        width: 40,
      },
      {
        header: "INSURED",
        key: "insurance",
        width: 40,
      },
      {
        header: "INSURANCE FEE",
        key: "insurance_fee",
        width: 40,
      },
    ];

    sheet.insertRow(1, [
      "",
      "",
      "",
      `
    SHIPMENT REPORT
    `,
    ]);
    const first_row = sheet.getRow(1);
    first_row.height = 120;
    first_row.font = {
      size: 18,
      bold: true,
      color: { argb: "074B8A" },
    };
    first_row.border = {
      // top: { style: "thick", color: { argb: "074B8A" } },
      left: { style: "thick", color: { argb: "FFFFFF" } },
      bottom: { style: "thick", color: { argb: "074B8A" } },
      // right: { style: "thick", color: { argb: "074B8A" } },
    };
    first_row.alignment = { horizontal: "center" };

    // const result = toDataURL(User?.company_logo_url);
    // const result = User?.company_logo_url;

    // worksheet.addImage(imageId2, {
    //   tl: { col: 1.5, row: 1.5 },
    //   br: { col: 3.5, row: 5.5 }
    // });
    shipments?.forEach((shipment, id) => {
      sheet.addRow({
        sn: id + 1,
        id: `${shipment.id ?? "N/A"}`,
        shipment_type: `${shipment?.shipment_type ?? "N/A"}`,
        date_time: `${shipment?.date_time ?? "N/A"}`,
        sender_name: `${shipment?.sender_name ?? "N/A"}`,
        sender_phone_no: `${shipment?.sender_phone_no ?? "N/A"}`,
        pick_up_location: customerAddress(
          shipment.sender_address_1,
          shipment.sender_address_2,
          shipment.sender_city,
          shipment.sender_state,
          shipment.sender_country,
          shipment.sender_postcode
        ) ?? "N/A",
        receiver_phone_no: `${shipment?.receiver_phone_no ?? "N/A"}`,
        drop_off_location: customerAddress(
          shipment.receiver_address_1,
          shipment.receiver_address_2,
          shipment.receiver_city,
          shipment.receiver_state,
          shipment.receiver_country,
          shipment.receiver_postcode
        ) ?? "N/A",
        receiver_name: `${shipment?.receiver_name ?? "N/A"}`,
        item_name: `${shipment?.item_name ?? "N/A"}`,
        total_amount: `₦${shipment?.total_amount ?? "0"}`,
        payment_method: `${shipment?.payment_method ?? ""}`,
        paid_status: `${shipment?.paid_status ?? ""}`,
        status: `${shipment?.status ?? ""}`,
        weight: `${shipment?.item_weight ?? ""}`,
        tracking_numbers: `${
          shipment?.tracking_numbers ?? "N/A"
        }`,
        item_value: `${shipment?.item_value ?? ""}`,
        approved_by: `${shipment?.approved_by ?? ""}`,
        rider: `${shipment?.rider_name ?? ""}`,
        // pickup: `₦${shipment?.total_amount ?? "0"}`,
        // dropoff: `₦${shipment?.total_amount ?? "0"}`,
        carrier: `${shipment?.processor}`,
        insurance: shipment?.insurance,
        insurance_fee: shipment?.insurance_fee,
      });
    });
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `Shipment_Report.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const exportToCSV = () => {
    if (shipments.length === 0) return;

    let csvContent = "data:text/csv;charset=utf-8,";

    csvContent +=
      "Shipment ID, Shipment Type, Shipment Date, Sender Name,Sender Phone Number,Pickup Address,Receiver Name,Receiver Phone Number,Dropoff Address,Amount,Weight,Shipment Tracking Number,Approved By,Payment Method, Payment Status, Shipment Status, Item Description, Item Value, Rider, Carrier, Insured, Insurance fee" +
      "\r\n";

    shipments.forEach(function (shipment) {
      let id = shipment.id;

      let senderDetails =
        shipment.sender_name +
        "," +
        shipment.sender_phone_no +
        "," +
        customerAddress(
          shipment.sender_address_1,
          shipment.sender_address_2,
          shipment.sender_city,
          shipment.sender_state,
          shipment.sender_country,
          shipment.sender_postcode
        )?.replaceAll(",", " ");
      let receiverDetails =
        shipment.receiver_name +
        "," +
        shipment.receiver_phone_no +
        "," +
        customerAddress(
          shipment.receiver_address_1,
          shipment.receiver_address_2,
          shipment.receiver_city,
          shipment.receiver_state,
          shipment.receiver_country,
          shipment.receiver_postcode
        )?.replaceAll(",", " ");
      let packageDetails =
        shipment.total_amount.replaceAll(",", "") + "," + shipment.item_weight ?? "";

      let track_number = shipment.tracking_numbers ?? "";
      let shipment_date = shipment?.date_time ?? "";
      let shipment_type = shipment.shipment_type ?? "";
      let approve = shipment.approved_by;
      let pay_method = shipment.payment_method;
      let pay_Status = shipment.paid_status;
      let ship_status = shipment.status;
      let desc = shipment.item_name ?? "";
      let value = shipment.item_value ?? "";
      let rider = shipment.rider_name ?? "";
      let carrier = shipment.processor ?? "";
      let insured = shipment?.insurance ?? "";
      let insurance_fee = shipment?.insurance_fee ?? "";

      const row =
        id +
        "," +
        shipment_type +
        "," +
        shipment_date +
        "," +
        senderDetails +
        "," +
        receiverDetails +
        "," +
        packageDetails +
        "," +
        track_number +
        "," +
        approve +
        "," +
        pay_method +
        "," +
        pay_Status +
        "," +
        ship_status +
        "," +
        desc +
        "," +
        value +
        "," +
        rider +
        "," +
        carrier +
        "," +
        insured +
        "," +
        insurance_fee;

      csvContent += row + "\r\n";
    });

    const encodedURI = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", "shipments1.csv");
    document.body.appendChild(link);

    link.click();
  };

  const [reportStartDate, setReportStartDate] = useState("");
  const [reportEndDate, setReportEndDate] = useState("");

  const date_options = [
    { label: "Today", value: "Today" },
    { label: "Yesterday", value: "Yesterday" },
    { label: "This Week", value: "Week" },
    { label: "This Month", value: "Month" },
    { label: "Custom Date", value: "custom" },
  ];

  const shortenTexts = (text) => {
    if (text) {
      if (text?.length > 32) {
        return `${text?.slice(0, 32)}...`;
      } else {
        return text;
      }
    }
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <div className="container shipments-container">
            <div
              className="card border-0 p-4 min-vh-100"
              style={{ background: "#FFF" }}
            >
              <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={ReportIcon} alt="group" />
                  <h5 className="m-0">Shipments Report</h5>
                </div>
                <div className="d-flex flex-column flex-md-row gap-3"></div>
              </div>
              <div className="d-flex gap-3 mb-3 align-items-end flex-column flex-md-row justify-content-between">
                <div className="d-flex flex-column flex-md-row align-items-md-end gap-3">
                  {/* <select onChange={onChangeReportType} style={{width:230, marginRight:10}} name="pickup_detail_id" className="form-select" >
                    <option value="">Choose report</option>
                    <option value="delivered shipments">Delivered Shipments</option>
                    <option value="incomplete shipments">Incomplete Shipments</option>
                    <option value="cancelled shipments">Cancelled Shipments</option>
                    <option value="unpaid shipments">Unpaid Shipments</option>
                  </select> */}

                  <SelectBox
                    name="pickup_detail_id"
                    onChange={onChangeReportTime}
                    options={date_options}
                    style={{ padding: "8px 20px", width: "190px" }}
                    value={reportTime}
                    placeholder="Select Date"
                  />

                  {reportTime === "custom" && (
                    <div className="d-md-flex d-grid col gap-3">
                      <div>
                        <label className="form-label" htmlFor="startdate">
                          Start Date
                        </label>
                        <TextBox
                          type="date"
                          style={{ padding: "8px 20px" }}
                          value={reportStartDate}
                          onChange={(e) => setReportStartDate(e.target.value)}
                        />
                      </div>
                      <div>
                        <label className="form-label" htmlFor="startdate">
                          End Date
                        </label>
                        <TextBox
                          type="date"
                          style={{ padding: "8px 20px" }}
                          value={reportEndDate}
                          onChange={(e) => setReportEndDate(e.target.value)}
                        />
                      </div>
                    </div>
                  )}

                  <button
                    onClick={fetchShipmentReport}
                    className=" btn-primary"
                  >
                    {
                      isLoading ? (
                        <LoadingSpinner className="spinner-border spinner-grow-sm" />
                      ) : (
                        "Generate"
                      )
                      // (
                      //   <BiIcons.BiSearch style={{ fontSize: 20 }} />
                      // )
                    }
                  </button>
                </div>

                <div>
                  <button
                    className="feature-btn"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClickExport}
                  >
                    <img src={ExportIcon} alt="export" />
                    Export
                    {open ? <BsChevronUp /> : <BsChevronDown />}
                  </button>
                  <Menu
                    id="basic-menu"
                    anchorEl={openExport}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        exportToCSV();
                        handleClose();
                      }}
                      style={{ fontSize: 12 }}
                    >
                      Export as .csv
                    </MenuItem>
                    <MenuItem
                      style={{ fontSize: 12 }}
                      onClick={() => {
                        exportToXlsx();
                        handleClose();
                      }}
                    >
                      Export as .xlsx
                    </MenuItem>
                  </Menu>
                </div>
              </div>

              <div className="table-contain">
              <div className="" style={{width:"100%", overflowX:"auto", overflowY: "hidden"}}>
                <table className="shipments-table w-100">
                  <thead>
                    <tr>
                      <th scope="col">Sender</th>
                      <th scope="col">Pickup Location</th>
                      <th scope="col">Receiver</th>
                      <th scope="col">Dropoff Location</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Carrier</th>
                      {/* <th scope="col">Tracking No.</th> */}
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  {isLoading === true ? null : (
                    <tbody>
                      {shipments.map((shipment) => {
                        const dateTime = moment(shipment.date_time);
                        const timestamp = dateTime.format("ddd MMM Do, h:mm A");

                        const dropoffLocation = `${shipment.receiver_address_1} 
                        ${shipment.receiver_address_2}, ${shipment.receiver_city}, 
                        ${shipment.receiver_state}, ${shipment.receiver_country}, 
                        ${shipment.receiver_postcode}`;

                        const pickupLocation = `${shipment.sender_address_1} 
                        ${shipment.sender_address_2}, ${shipment.sender_city}, 
                        ${shipment.sender_state}, ${shipment.sender_country}, 
                        ${shipment.sender_postcode}`;

                        return (
                          <tr
                            key={shipment.id}
                            onClick={() => handleClick(shipment.id)}
                          >
                            <td>{shipment.sender_name}</td>
                            <td>
                              <Tooltip
                                title={`${pickupLocation}`}
                                placement="top"
                              >
                                <span>
                                  {shortenTexts(pickupLocation)}
                                </span>
                              </Tooltip>
                            </td>
                            <td>{shipment.receiver_name}</td>
                            <td>
                              <Tooltip
                                title={`${dropoffLocation}`}
                                placement="top"
                              >
                                <span>
                                  {shortenTexts(dropoffLocation)}
                                </span>
                              </Tooltip>
                            </td>
                            <td>
                              ₦
                              {parseFloat(shipment.total_amount).toLocaleString(
                                "en"
                              )}
                            </td>
                            <td>{shipment.processor ?? "N/A"}</td>
                            {/* <td>
                              {shipment.shipment_tracking_number ?? "N/A"}
                            </td> */}
                            <td>{timestamp}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
                </div>
              </div>
              {isRetrievingMore === false ||
              isOptionSelected === "" ||
              isLoading === true ? null : (
                <div style={{ textAlign: "center", marginTop: 40 }}>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />{" "}
                  Retrieving More..
                </div>
              )}
              {isOptionSelected !== "" ? null : (
                <LoadingContainer>
                  <div>
                    <img
                      style={{ width: 50, height: 50, marginBottom: 10 }}
                      src={dataIcon}
                      alt=""
                    />
                    <p>No option has been selected</p>
                  </div>
                </LoadingContainer>
              )}
              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipmentReports;
