import React from "react";
import EmptyListIcon from "../assets/empty/EmptyList.png";
import styled from "styled-components";

const EmptyScreenContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  & img {
    width: 60px;
    height: 60px;
  }
`;

const PageMessage = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
`;

const EmptyScreen = ({ message, action, actionText, secondMessage }) => {
  return (
    <div>
      <EmptyScreenContainer>
        <div>
          <div className="d-flex justify-content-center mb-2">
            <img src={EmptyListIcon} alt="empty" />
          </div>
          <PageMessage>
            {message}{" "}
            {secondMessage && (
              <span>
                <br />
                {secondMessage}
              </span>
            )}{" "}
          </PageMessage>
          {action && (
            <div className="d-flex justify-content-center">
              <button type="button" className="btn-primary" onClick={action}>
                {actionText}
              </button>
            </div>
          )}
        </div>
      </EmptyScreenContainer>
    </div>
  );
};

export default EmptyScreen;
