import React from "react";
import { customerAddress } from "../../library/utilities";

const StationInfo = ({ station }) => {
  return (
    <div>
      <section className="">
        <div className="form-row mt-3">
          <div className="form-group d-flex py-2 border-bottom gap-2">
            <label htmlFor="Length" className="">
              Station Name:
            </label>
            <p>{station?.station_name} </p>
          </div>

          <div className="form-group d-flex py-2 border-bottom gap-2">
            <label htmlFor="Length" className="">
              Contact Full Name:
            </label>
            <p>{station?.fullname} </p>
          </div>

          <div className="form-group d-flex py-2 border-bottom gap-2">
            <label htmlFor="Length" className="">
              Phone Number:
            </label>
            <p>{station?.phone} </p>
          </div>

          <div className="form-group d-flex py-2 border-bottom gap-2">
            <label htmlFor="Length" className="">
              Email Address:
            </label>
            <p>{station?.email} </p>
          </div>
        </div>
      </section>
      <section>
        <div className="form-row ">
          <div className="form-group d-flex py-2 gap-2mb-3 mb-xl-0">
            <label htmlFor="Length" className="pe-2">
              Address:{" "}
            </label>
            <p>
              {customerAddress(
                station?.address,
                station?.address_2,
                station?.city_name,
                station?.state,
                station?.country,
                station?.postal_code
              )}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default StationInfo;
