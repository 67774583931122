import { Col, Modal, Row } from "react-bootstrap";
import { useRef, useState } from "react";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import { TextBox, TextFieldBox } from "../components/InputFields";
import { BsX } from "react-icons/bs";
import { uploadPackageImgUrl, createCustomerGroupUrl } from "../library/URLs";
import { UserProfileData } from "../library/constants";
import axios from "axios";
import { SessionManagement } from "../library/SessionManagement";
import { toast } from "react-toastify";

const SubmitButton = styled.button``;

const CreateCustomerGroupDialog = ({
  isModalVisible,
  handleCloseDialog,
  onSubmit
}) => {
  const initialData = {
    name: "",
    description: "",
    image_url: SessionManagement.getCompanyLogoURL(),
    image_name: SessionManagement.getCompanyName()
  };

  const [formData, setFormData] = useState(initialData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [uploading, setUploading] = useState(false);


  const fileInputRef = useRef(null);

  const uploadPackageImage = (file) => {

    const imgFormData = new FormData();
    imgFormData.append("image", file);

    setUploading(true);

    axios
      .post(uploadPackageImgUrl, imgFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        setUploading(false);
        setFormData({
          ...formData,
          image_url: response?.data,
          image_name: file?.name,
        });
      })
      .catch(function (error) {
        return error;
      });
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileInput = (e) => {
    const { files } = e.target;
    // setPackageImg(files[0]?.name);
    uploadPackageImage(files[0]);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    let formValues = {
      ...formData,
      [name]: value,
    };

    setFormData(formValues);
  };

  const createGroup = async () => {
    let data = {
      ...formData,
    };
    setIsSubmitting(true)

    await axios.post(createCustomerGroupUrl, { ...UserProfileData(), ...data })
    .then(function (response) {
      setIsSubmitting(false);
      if(!response?.data?.success) {
        toast.error("Please ensure to fill all fields!");
        return;
      }

      if(response?.data?.success) {
        toast.success("Customer group created!");

        setTimeout(() => {
          onSubmit();
          setFormData(initialData);
          handleCloseDialog();
        }, 2000);
      }
    })
    .catch(function (error) {
      setIsSubmitting(false);
      return error;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      formData?.name === "" ||
      formData?.description === ""
    ) {
      toast.error("Please ensure to fill all fields!");
      return;
    }
    createGroup();
  };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">CREATE CUSTOMER GROUP</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>

        <Row className="create-shipment-info-form-row w-100 gy-4">
          <Col sm={12} className="create-shipment-info-form-row-input">
            <label htmlFor="first_name">Name</label>
            <TextBox
              name="name"
              value={formData.name}
              onChange={handleInput}
              placeholder="Enter group name"
            />
          </Col>
          <Col sm={12} className="create-shipment-info-form-row-input">
            <label htmlFor="first_name">Description</label>
            <TextFieldBox
              name="description"
              value={formData.description}
              onChange={handleInput}
              placeholder="Enter description"
            />
          </Col>

          <Col sm={12}>
            <label htmlFor="package img">
              Upload Group Logo{" "}
            </label>
            <div className="d-flex gap-2 align-items-center">
              {uploading && <span className="spinner-border spinner-grow-sm" />}
              <div className="w-100">
                <input
                  style={{
                    visibility: "hidden",
                    position: "fixed",
                    bottom: 0,
                    zIndex: -1,
                  }}
                  type="file"
                  onChange={handleFileInput}
                  ref={fileInputRef}
                  accept="image/*"
                  name=""
                  id=""
                />
                <div
                  style={{ marginTop: 20 }}
                  onClick={triggerFileInput}
                  className="change-password-button"
                >
                  Choose Image
                </div>
              </div>
            </div>
            {formData?.image_url !== "" && (
              <small>{formData?.image_name}</small>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary mx-3" onClick={handleCloseDialog}>
          <BsX />
          Cancel
        </button>
        <SubmitButton
          onClick={handleSubmit}
          type="submit"
          className="btn-primary"
        >
          {
            isSubmitting ? (
              <span className="spinner-border spinner-grow-sm" />
            ) : "Create"
          }
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateCustomerGroupDialog;
