import "../styles/home.scss";
import "../styles/single-customer.scss";
import "../styles/components.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getCustomerGroupDetailsUrl,
  getCustomerGroupMembersUrl,
} from "../library/URLs";
import axios from "axios";
import { UserProfileData } from "../library/constants";
import {
  BsChevronLeft,
  BsChevronRight,
} from "react-icons/bs";
import styled from "styled-components";
import * as React from "react";
import SubscriptionInactive from "../components/SubscriptionInactive";
import RemoveCustomerGroupDialog from "../modals/RemoveCustomerGroupDialog";
import AddCustomerGroupDialog from "../modals/AddCustomerGroupDialog";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const ProfilePicture = styled.img`
  height: 75px;
  width: 75px;
  border-radius: 100%;
  margin-bottom: 16px;
`;

const SingleCustomerGroup = () => {
  const params = useParams();
  const navigate = useNavigate();
  const groupID = params["id"];

  const [selectedGroup, setSelectedGroup] = useState({});
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [groupLoading, setGroupLoading] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [showRemove, setShowRemove] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  const fetchGroup = () => {
    setIsLoading(true);
    axios
      .post(getCustomerGroupDetailsUrl, {
        ...UserProfileData(),
        group_id: groupID
      }, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        const group = response["data"]["data"];
        fetchGroupMembers();
        setSelectedGroup(group);
        
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const fetchGroupMembers = () => {
    setGroupLoading(true);
    axios
      .post(
        getCustomerGroupMembersUrl,
        { ...UserProfileData(), group_id: groupID },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setGroupLoading(false);

        if (response["data"]["success"] === false) return;

        const members = response["data"]["data"];

        setMembers(members);
      })
      .catch(() => {
        setGroupLoading(false);
      });
  };

  useEffect(() => {
    fetchGroup();
    // eslint-disable-next-line
  }, []);

  const handleSelectAll = () => {
    let selectedMemberCopy = [...selectedMembers];

    if (selectedMembers.length === members.length) {
      selectedMemberCopy = [];
    } else {
      let selection = members?.map((customer) => customer.user_id);
      selectedMemberCopy = selection;
    }
    setSelectedMembers(selectedMemberCopy);
  };

  const handleCustomerToggle = (id) => {
    let selectedMembersCopy = [...selectedMembers];

    if (selectedMembersCopy.includes(id)) {
      selectedMembersCopy = selectedMembersCopy.filter(
        (shipmentId) => shipmentId !== id
      );
    } else {
      selectedMembersCopy.push(id);
    }
    setSelectedMembers(selectedMembersCopy);
  };

  return (
    <div className="home">
      <RemoveCustomerGroupDialog
        isModalVisible={showRemove}
        handleCloseDialog={() => setShowRemove(false)}
        members={selectedMembers}
        groupID={groupID}
        onSubmit={() => {
          fetchGroup();
          setSelectedMembers([]);
        }}
      />
      <AddCustomerGroupDialog 
        isModalVisible={showAdd}
        handleCloseDialog={() => setShowAdd(false)}
        groupID={groupID}
        onSubmit={fetchGroup}
      />

      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />

          <div className="container-fluid">
            <div className="navigation-contain">
              <button
                className="back-btn"
                onClick={() => navigate("/shipments/batches")}
              >
                <BsChevronLeft />
              </button>
              <ul className="nav-options">
                <li>Customer Group</li>
                <li className="nav-options-arrow">
                  <BsChevronRight />{" "}
                </li>
                <li className="nav-options-active">Group Detail - {groupID}</li>
              </ul>
            </div>
            <div className="row mt-4">
              <div className="col-xl-12 col-md-12">
                <div
                  className="card border-0"
                  style={{ backgroundColor: "#FFF" }}
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between gap-3 mb-3">
                      <div className="d-flex gap-3 align-items-center">
                        
                      </div>
                    </div>
                    {isLoading === false ? null : (
                      <LoadingContainer>
                        <LoadingSpinner className="spinner-border spinner-grow-sm" />
                      </LoadingContainer>
                    )}
                    {isLoading ? null : (
                      <div className="shipment-section-wrap">
                        <div className="row mt-4">
                          <div className="col-xl-6 col-md-6 pe-3 sub-section">
                            <div className="row section-wrap-secondary h-100">
                              <div className="col-12">
                                <ProfilePicture src={selectedGroup?.image_url} alt={selectedGroup?.name} />
                              </div>

                              <div className="col-xl-6">
                                <span className="title">Group Name</span>
                                <p>{selectedGroup?.name} </p>
                              </div>

                              <div className="col-xl-5">
                                <span className="title">Number of Members</span>
                                <p>
                                  {selectedGroup?.members_count}{" "}
                                </p>
                              </div>

                              <div className="col-xl-12">
                                <span className="title">Description</span>
                                <p>{selectedGroup?.description} </p>
                              </div>


                              {/* <div className="col-xl-4">
                              
                              </div> */}
                            </div>
                          </div>                          
                        </div>
                      </div>
                    )}

                    <div className="shipment-section-wrap mt-3">
                      <div className="my-3 d-flex gap-3 justify-content-end">
                        <button
                          className="btn-primary"
                          onClick={() => setShowAdd(true)}
                        >
                          Add Member(s)
                        </button>
                        {selectedMembers?.length > 0 && (
                            <button
                              className="btn-primary"
                              onClick={() => setShowRemove(true)}
                            >
                              Remove Member(s)
                            </button>
                          )}
                        <div>
                          
                          
                        </div>
                      </div>
                      <div className="table-contain w-100" >
                        <table className="shipments-table w-100">
                          <thead>
                            <tr>
                              <th scope="col">
                                <input
                                  type="checkbox"
                                  checked={
                                    selectedMembers.length ===
                                    members?.length
                                  }
                                  onClick={handleSelectAll}
                                />
                              </th>
                              <th scope="col">Name</th>
                              <th scope="col">Email</th>
                              <th scope="col">Phone Number</th>
                            </tr>
                          </thead>
                          <tbody>
                            {members?.map((customer) => {

                              return (
                                <tr key={customer.user_id}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={selectedMembers.includes(
                                        customer.user_id
                                      )}
                                      onClick={() => handleCustomerToggle(customer.user_id)}
                                    />
                                  </td>
                                  <td className="p-0">
                                    <Link
                                      to={`/customer/${customer.user_id}`}
                                      style={{
                                        padding: "20px 10px 20px 10px",
                                        color: "#475367",
                                      }}
                                    >
                                      {`${customer.fullname}`}
                                    </Link>
                                  </td>
                                  <td className="p-0">
                                    <Link
                                      to={`/customer/${customer.user_id}`}
                                      style={{
                                        padding: "20px 10px 20px 10px",
                                        color: "#475367",
                                      }}
                                    >
                                      {customer.email}
                                    </Link>
                                  </td>
                                  <td className="p-0">
                                    <Link
                                      to={`/customer/${customer.user_id}`}
                                      style={{
                                        padding: "20px 10px 20px 10px",
                                        color: "#475367",
                                      }}
                                    >
                                      {customer.phone}
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {
                          !groupLoading && members.length < 1 && (
                            <p className="text-center py-3" >No member(s) yet.</p>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-xl-12 col-md-12">
                {groupLoading === false ? null : (
                  <LoadingContainer>
                    <LoadingSpinner className="spinner-border spinner-grow-sm" />
                  </LoadingContainer>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleCustomerGroup;
