import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { createUserUrl } from "../library/URLs";
import { useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import {
  PhoneFieldBox,
  SelectBox,
  TextBox,
} from "../components/InputFields";
import { BsX } from "react-icons/bs";
import { toast } from "react-toastify";

const SubmitButton = styled.button``;

const AddCustomerDialog = ({
  isModalVisible,
  handleCloseDialog,
  reload
}) => {

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    account_type: ""
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async() => {

    const formValues = {
      ...UserProfileData(),
      ...formData
    };
    setIsSubmitting(true);

    await axios.post(createUserUrl, formValues)
    .then((response) => {
      setIsSubmitting(false);

      if(response.data.success === false) {
        toast.error(response.data.message);
        return;
      };

      if(response.data.success) {
        toast.success(response.data?.data);
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          account_type: ""
        })
        setTimeout(() => {
          handleCloseDialog();
          reload();
        }, 2000);
      }
      
    })
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handlePhoneInput = (phone_num) => {
    setFormData({
      ...formData,
      phone: phone_num
    });
  };

  const user_types = [
    {label: "Individual", value: "Individual"},
    {label: "Business", value: "Business"},
    {label: "3P Logistics", value: "3P Logistics"}
  ];

  return (
    <Modal size="lg" show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">ADD CUSTOMER</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>

        <p>Fill the details below to add customer</p>
        <div className="query-result-container">
          <Row className="mb-2 gy-4">
            {/* How to calculate and create rate */}
            <Col md={6}>
              <div>
                <label htmlFor="Description" className="mb-1">
                  First Name
                </label>
                <TextBox
                  name="first_name"
                  value={formData?.first_name}
                  onChange={handleInput}
                  placeholder="Enter first name"
                />
              </div>
            </Col>

            <Col md={6}>
              <div>
                <label htmlFor="Description" className="mb-1">
                  Last Name
                </label>
                <TextBox
                  name="last_name"
                  value={formData?.last_name}
                  onChange={handleInput}
                  placeholder="Enter last name"
                />
              </div>
            </Col>

            <Col md={6}>
              <div>
                <label htmlFor="Description" className="mb-1">
                  Email Address
                </label>
                <TextBox
                  name="email"
                  value={formData?.email}
                  onChange={handleInput}
                  placeholder="Enter email address"
                />
              </div>
            </Col>

            <Col md={6}>
              <div>
                <label htmlFor="Description" className="mb-1">
                  Phone Number
                </label>
                <PhoneFieldBox
                  name="phone"
                  value={formData?.phone}
                  onChange={handlePhoneInput}
                  placeholder="Enter phone number"
                />
              </div>
            </Col>

            <Col md={12}>
              <div>
                <label htmlFor="Description" className="mb-1">
                  Account Type
                </label>
                <SelectBox
                  name="account_type"
                  value={formData?.account_type}
                  onChange={handleInput}
                  options={user_types}
                  placeholder="Select account type"
                />
              </div>
            </Col>

          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary mx-3" onClick={handleCloseDialog}>
          <BsX />
          Cancel
        </button>
        <SubmitButton onClick={handleSubmit} type="submit" className="btn-primary">
          {isSubmitting === false ? (
            <span className="button-text d-flex align-items-center gap-2">
              Proceed
            </span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCustomerDialog;
