import "../../styles/login.scss";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { generateTransactionID } from "../../library/utilities";
import axios from "axios";
import { loginURL, newRegisterUrl, registerURL } from "../../library/URLs";
import { SessionManagement } from "../../library/SessionManagement";
import { ErrorNotification } from "../../components/Notifications";
import ParcelflowLogo from "../../assets/Parcelflow-Logo.svg";
import ServicesActiveIcon from "../../assets/auth/services-active.svg";
import OperationsIcon from "../../assets/auth/operations.svg";
import { PasswordBox, SelectBox, TextBox } from "../../components/InputFields";
import { BsCheck, BsChevronLeft, BsChevronRight } from "react-icons/bs";
import WhatsappAI from "./operations/WhatsappAI";
import LogisticsWeb from "./operations/LogisticsWeb";
import ParcelConnect from "./operations/ParcelConnect";
import EcommerceWeb from "./operations/EcommerceWeb";
import ParcelCommerce from "./operations/ParcelCommerce";
import { UserProfileData } from "../../library/constants";
import { toast } from "react-toastify";

const PageHeader = styled.h4`
  font-family: Prompt, sans-serif;
  font-size: 32px;
  line-height: 28.8px;
  margin-bottom: 8px;
  color: #000;

  @media (max-width: 556px) {
    font-size: 2.4rem;
  }
`;

const PageSubheader = styled.h4`
  font-size: 14px;
  color: #667185;
  margin-bottom: 48px;
  font-weight: normal;
`;

const PageFooter = styled.h4`
  font-size: 17px;
  text-align: center;
  margin-bottom: 20px;
`;

const Services = ({ previousPage, registerForm }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [services, setServices] = useState("");
  const [answers, setAnswers] = useState({});

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const form = useRef(null);

  const clearErrorMessage = () => {
    setErrorVisibility(false);
    setErrorMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formValues = {
      ...UserProfileData(),
      mode: "Web",
      personal_details: {
        first_name: registerForm?.first_name,
        last_name: registerForm?.last_name,
        email: registerForm?.email,
        phone_number: registerForm?.phone,
        password: registerForm?.password,
        country: registerForm?.country,
        state: registerForm?.state
      },
      interested_service: services,
      operations: []
    };
    setIsSubmitting(true);

    axios
      .post(newRegisterUrl, formValues)
      .then(function (response) {
        console.log(response);
        setIsSubmitting(false);

        if (response["data"]["success"] === false) {
          setErrorMessage(response["data"]["message"]);
          setErrorVisibility(true);
          window.scrollTo(0, 0);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        } else if (response["data"]["success"] === true) {
          toast.success("Account successfully registered.");
          window.scrollTo(0, 0);

          setTimeout(() => {
            navigate("/login");
          }, 2000);
          // navigate("/register/services");
          // navigate('/verify-otp', {replace: true});
        }
      })
      .catch(function (error) {
        return error;
      });
    // console.log("register log ", formData);
  };

  const handleSelect = (service) => {
    setServices(service);
  };

  const prevPage = () => {
    setCurrentPage(0);
  };

  const nextPage = () => {
    setCurrentPage(1);
  };

  return (
    <div>
      <div className="Auth-Wrap">
        <div className="Auth-Left"></div>
        <div className="Auth-Left-Fixed">
          <div>
            <div className="logo-contain mb-4">
              <img src={ParcelflowLogo} alt="logo" />
            </div>
            <div className="auth-track-contain">
              <div className="progress-row">
                <div className="d-flex flex-column align-items-center">
                  <div className="progress-icon progress-icon-success">
                    <BsCheck />
                  </div>
                  <div className="progress-line progress-line-active"></div>
                </div>
                <div className="progress-detail">
                  <p className="progress-detail-title">Account details</p>
                  <p className="progress-detail-desc">
                    Provide your details to begin account creation.
                  </p>
                </div>
              </div>

              <div className="progress-row">
                <div className="d-flex flex-column align-items-center">
                  <div className="progress-icon progress-icon-active">
                    <img src={ServicesActiveIcon} alt="icon" />
                  </div>
                  <div className="progress-line progress-line-active"></div>
                </div>
                <div className="progress-detail">
                  <p className="progress-detail-title">Interested service(s)</p>
                  <p className="progress-detail-desc">
                    Which of Parcelflow&apos;s service(s) interests you?
                  </p>
                </div>
              </div>

              <div className="progress-row">
                <div className="d-flex flex-column align-items-center">
                  <div className="progress-icon">
                    <img src={OperationsIcon} alt="icon" />
                  </div>
                </div>
                <div className="progress-detail">
                  <p className="progress-detail-title">Operations</p>
                  <p className="progress-detail-desc">
                    Further information on services.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="logo-contain">
            <p className="text-redirect">
              Already have an account? <Link to="/">Log In</Link>
            </p>
          </div>
        </div>
        <div className="Auth-Right">
          <div className="Auth-form">
            {isError === true ? (
              <ErrorNotification message={errorMessage}>
                This is a notification
              </ErrorNotification>
            ) : null}
            {currentPage === 0 ? (
              <>
                <PageHeader className="page-header feature-header">
                  Interested Services
                </PageHeader>
                <PageSubheader>
                  Which of Parcelflow&apos;s service(s) interests you? Select
                  all that apply
                </PageSubheader>
                <div className="progress-bar">
                  <div
                    className={`progress-bar-inactive progress-bar-active`}
                  ></div>
                  <div className={`progress-bar-inactive`}></div>
                </div>
                <form ref={form}>
                  <Row style={{ rowGap: "16px" }}>
                    <Col xs={12} lg={6}>
                      <div
                        onClick={() => handleSelect("Whatsapp AI")}
                        className={`auth-service-card ${
                          services === "Whatsapp AI" &&
                          "auth-service-card-active"
                        }`}
                      >
                        <div className="auth-service-card-top">
                          <p>Whatsapp AI</p>
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            onChange={() => handleSelect("Whatsapp AI")}
                            checked={services === "Whatsapp AI"}
                          />
                        </div>
                        <div>
                          <p className="auth-service-card-desc">
                            Whatsapp AI allows logistics
                            <br /> businesses to accept bookings
                            <br /> directly from Whatsapp
                          </p>
                        </div>
                      </div>
                    </Col>

                    <Col xs={12} lg={6}>
                      <div
                        onClick={() => handleSelect("Logistics Website")}
                        className={`auth-service-card ${
                          services === "Logistics Website" &&
                          "auth-service-card-active"
                        }`}
                      >
                        <div className="auth-service-card-top">
                          <p>Logistics Website</p>
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            onChange={() => handleSelect("Logistics Website")}
                            checked={services === "Logistics Website"}
                          />
                        </div>
                        <div>
                          <p className="auth-service-card-desc">
                            Logistics Website allows business
                            <br /> to have a functional web application
                            <br /> dedicated to logistics
                          </p>
                        </div>
                      </div>
                    </Col>

                    <Col xs={12} lg={6}>
                      <div
                        onClick={() => handleSelect("Parcelflow Connect")}
                        className={`auth-service-card ${
                          services === "Parcelflow Connect" &&
                          "auth-service-card-active"
                        }`}
                      >
                        <div className="auth-service-card-top">
                          <p>Parcelflow Connect</p>
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            onChange={() => handleSelect("Parcelflow Connect")}
                            checked={services === "Parcelflow Connect"}
                          />
                        </div>
                        <div>
                          <p className="auth-service-card-desc">
                            Parcelflow Connect allows businesses
                            <br /> connect to other businesses for
                            <br /> cheaper shipping rates
                          </p>
                        </div>
                      </div>
                    </Col>

                    <Col xs={12} lg={6}>
                      <div
                        onClick={() => handleSelect("eCommerce Website")}
                        className={`auth-service-card ${
                          services === "eCommerce Website" &&
                          "auth-service-card-active"
                        }`}
                      >
                        <div className="auth-service-card-top">
                          <p>eCommerce Website</p>
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            onChange={() => handleSelect("eCommerce Website")}
                            checked={services === "eCommerce Website"}
                          />
                        </div>
                        <div>
                          <p className="auth-service-card-desc">
                            eCommerce Website
                            <br /> allows businesses to
                            <br /> start a website easily
                          </p>
                        </div>
                      </div>
                    </Col>

                    <Col xs={12} lg={6}>
                      <div
                        onClick={() => handleSelect("Parcelflow Commerce")}
                        className={`auth-service-card ${
                          services === "Parcelflow Commerce" &&
                          "auth-service-card-active"
                        }`}
                      >
                        <div className="auth-service-card-top">
                          <p>Parcelflow Commerce</p>
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            onChange={() => handleSelect("Parcelflow Commerce")}
                            checked={services === "Parcelflow Commerce"}
                          />
                        </div>
                        <div>
                          <p className="auth-service-card-desc">
                            Have a website?
                            <br /> Add Parcelflow plugin to your already
                            functional website.
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div className="d-flex flex-row my-5 gap-4 justify-content-end">
                    <button
                      type="button"
                      className="btn-secondary"
                      onClick={previousPage}
                    >
                      <BsChevronLeft />
                    </button>
                    <button
                      disabled={services === ""}
                      type="button"
                      onClick={nextPage}
                      className="login-button btn-primary rounded"
                    >
                      {isSubmitting === false ? (
                        <span className="button-text d-flex align-items-center justify-content-center">
                          Proceed <BsChevronRight />
                        </span>
                      ) : (
                        <span className="spinner-border spinner-grow-sm" />
                      )}
                    </button>
                  </div>

                  {/* <PageSubheader>
                      Already have an account? <a href="/login">Login</a>
                    </PageSubheader> */}
                </form>
              </>
            ) : currentPage === 1 && services === "Whatsapp AI" ? (
              <WhatsappAI
                prevPage={prevPage}
                answers={answers}
                setAnswers={setAnswers}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
              />
            ) : currentPage === 1 && services === "Logistics Website" ? (
              <LogisticsWeb
                prevPage={prevPage}
                answers={answers}
                setAnswers={setAnswers}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
              />
            ) : currentPage === 1 && services === "Parcelflow Connect" ? (
              <ParcelConnect
                prevPage={prevPage}
                answers={answers}
                setAnswers={setAnswers}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
              />
            ) : currentPage === 1 && services === "eCommerce Website" ? (
              <EcommerceWeb
                prevPage={prevPage}
                answers={answers}
                setAnswers={setAnswers}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
              />
            ) : currentPage === 1 && services === "Parcelflow Commerce" ? (
              <ParcelCommerce
                prevPage={prevPage}
                answers={answers}
                setAnswers={setAnswers}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
