import { Modal } from "react-bootstrap";
import axios from "axios";
import { removeCustomerFromGroupUrl } from "../library/URLs";
import { useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import { BsX } from "react-icons/bs";

const SubmitButton = styled.button``;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const RemoveCustomerGroupDialog = ({
  isModalVisible,
  handleCloseDialog,
  groupID,
  members,
  onSubmit = () => {},
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const removeCustomers = (e) => {
    const formValues = {};
    formValues["group_id"] = `${groupID}`;
    let formatted_members = members.join("|");
    formValues["customer_id"] = formatted_members;

    const newData = { ...formValues, ...UserProfileData() };

    setErrorVisibility(false);
    setIsSubmitting(true);

    console.log(newData);

    axios
      .post(removeCustomerFromGroupUrl, newData)
      .then(function (response) {
        console.log(response);
        setIsSubmitting(false);
        if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          setSuccessMessage("Group member(s) removed.");
          onSubmit();

          setTimeout(() => {
            setSuccessVisibility(false);
            handleCloseDialog(true);
          }, 3000);
        } else {
          setErrorMessage(response["data"]["message"]);
          setErrorVisibility(true);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">REMOVE {members?.length} MEMBER(S)</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <p>You are about to remove selected member(s)</p>
        <p>This means these members will lose access to this group features.</p>
        <p>Would you like to continue?</p>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn-secondary mx-3"
          onClick={(e) => handleCloseDialog(false)}
        >
          <BsX /> CANCEL
        </button>
        <button
          onClick={removeCustomers}
          type="submit"
          className="btn-primary"
        >
          {isSubmitting === false ? (
            <span className="button-text d-flex align-items-center gap-2">
              Proceed
            </span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveCustomerGroupDialog;
