import React, { useEffect, useState } from "react";
import PickupIcon from "../../../assets/create-shipment/pickup.svg";
import CarrierIcon from "../../../assets/create-shipment/carrier.svg";
import { SelectBox } from "../../../components/InputFields";
import { Col, Row } from "react-bootstrap";
import { UserProfileData } from "../../../library/constants";
import axios from "axios";
import {
  calculatePickupCostUrl,
  getInsuranceFeeUrl,
  getCompanyInsuranceUrl,
} from "../../../library/URLs";
import { toast } from "react-toastify";
import { generateTransactionID } from "../../../library/utilities";
import { fetchPackageTotalValue, fetchPackageTotalWeight } from "../Utilities";

const Pricing = ({
  nextPage,
  previousPage,
  formData,
  setFormData,
  carriers,
}) => {
  const [currentCarrier, setCurrentCarrier] = useState(null);

  const [pickupPrice, setPickupPrice] = useState(0);
  const [calculatingPickup, setCalculatingPickup] = useState(false);
  const calculatePickupCost = () => {
    const formValues = {};

    formValues["sender_address"] = `${formData?.sender_details?.address_1} ${formData?.sender_details?.address_2}`;
    formValues["station_id"] = formData.pickup_station;
    formValues["weight"] = fetchPackageTotalWeight(formData?.packages);


    formValues["transaction_id"] = `${generateTransactionID(
      15
    )}+1+1+1.23.0+10-2-2022 0:13`;

    const newData = { ...UserProfileData(), ...formValues };
    setCalculatingPickup(true);

    axios
      .post(calculatePickupCostUrl, newData)
      .then(function (response) {
        setCalculatingPickup(false);
        if (response["data"]["success"] === true) {
          const pickupPrice = response["data"]["data"];

          setPickupPrice(pickupPrice);
        } else {
          toast.error(response["data"]["message"]);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const [insureFee, setInsureFee] = useState();
  const [insureLoading, setInsureLoading] = useState(false);
  const getInsuranceFee = () => {
    let shipment_type = formData?.sender_details?.country !== "Nigeria" ? "Interstate" : "International";
    let packageValue = fetchPackageTotalValue(formData?.packages);

    const formValues = {
      shipment_type: shipment_type,
      item_value: packageValue,
      sender_country: formData?.sender_details?.sender_country,
      currency: formData?.packages[0]?.package_items[0]?.value_currency
    };

    const newData = { ...UserProfileData(), ...formValues };
    setInsureLoading(true);

    axios
      .post(getInsuranceFeeUrl, newData)
      .then(function (response) {
        setInsureLoading(false)
        if (response["data"]["success"] === true) {
          setInsureFee(response["data"]["data"]);
        } else {
          toast.error(response["data"]["message"]);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const [companyInsurance, setCompanyInsurance] = useState();
  const getCompanyInsurance = () => {
    const data = {
      ...UserProfileData(),
    };

    axios
      .post(getCompanyInsuranceUrl, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        setCompanyInsurance(response["data"]["data"]);
      })
      .catch(function (error) {
        return error;
      });
  };

  useEffect(() => {
    calculatePickupCost();
    getInsuranceFee();
    getCompanyInsurance();
    // eslint-disable-next-line
  }, []);

  const validatePage = () => {
    if(!currentCarrier) {
      return true;
    }

    return false;
  };

  const handleSelect = (id, carrier) => {
    setFormData({
      ...formData,
      amount: carrier?.selling_price,
      cost_price: carrier?.cost_price,
      processor: carrier?.carrier_name,
      carrier: carrier,
    });
    setCurrentCarrier(id);
  };

  const navPrevious = (e) => {
    e.preventDefault();
    setCurrentCarrier(null);
    previousPage();
  };

  const handleRequestPickup = (e) => {
    const { name, value } = e.target;

    if (value === "No") {
      setFormData({
        ...formData,
        [name]: value,
        pickup_fee: 0,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
        pickup_fee: pickupPrice,
      });
    }
  };

  const pickup_options = [
    { label: "No", value: "No" },
    { label: `Yes - ₦ ${parseFloat(pickupPrice)?.toLocaleString("en-US")}`, value: "Yes" },
  ];

  const handleSubmit = () => {
    nextPage();
  };

  const insure_options = [
    { label: `Yes - ₦ ${parseFloat(insureFee)?.toLocaleString("en-US")}`, value: "Yes" },
    { label: "No", value: "No" },
  ];

  // if(companyInsurance?.insurance_company === "MyCoverAI") {
  //   insure_options.push({ label: `Capped - ₦ ${parseFloat(insureFee?.capped_fee)?.toLocaleString("en-US")}`, value: "Capped" })
  // };

  const handleInsurance = (e) => {
    const { value } = e.target;

    if(value === "Yes") {
      setFormData({
        ...formData,
        insurance_fee: insureFee ?? 0,
        insurance: value
      });
    } else {
      setFormData({
        ...formData,
        insurance_fee: 0,
        insurance: value
      });
    };
  };

  return (
    <div>
      <div className="create-shipment-grid">
        <div className="create-shipment-info">
          <img src={CarrierIcon} alt="icon" />
          <div>
            <div className="create-shipment-info-top">
              <p className="create-shipment-info-title">Carriers</p>
              <p className="create-shipment-info-sub">
                Which carrier service would you like to use?
              </p>
            </div>
          </div>
        </div>
        <div className="create-shipment-info-form">
          <ul className="create-shipment-info-form-row carriers-list">
            {carriers?.map((carrier) => (
              <li
                key={carrier?.carrier_id}
                onClick={() => handleSelect(carrier?.carrier_id, carrier)}
              >
                <div className="carriers-list-option">
                  <div className="carriers-list-option-first-row">
                    <div className="carriers-list-option-logo">
                      <img src={carrier?.carrier_logo} alt="" />
                    </div>
                    <div>
                      <p className="carriers-list-option-carrier-name">
                        {carrier?.carrier_name}{" "}
                      </p>
                      <p className="carriers-list-option-transit">
                        Transit Time:{" "}
                        <span className="carriers-list-option-transit-time">
                          {carrier?.delivery_time}{" "}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="carriers-list-option-price-label">PRICE</p>
                    <p className="carriers-list-option-price">
                      &#8358;{" "}
                      {parseFloat(`${carrier?.selling_price}`.replaceAll(",", ""))?.toLocaleString(
                        "en-us",
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      )}{" "}
                    </p>
                  </div>
                  <div>
                    <input
                      type="radio"
                      className=""
                      // onClick={() => handleSelect(carrier?.carrier_id, carrier)}
                      checked={currentCarrier === carrier?.carrier_id}
                    />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="create-shipment-grid">
        <div className="create-shipment-info">
          <img src={PickupIcon} alt="icon" />
          <div>
            <div className="create-shipment-info-top">
              <p className="create-shipment-info-title">Additional Details</p>
              <p className="create-shipment-info-sub">
                Would you require pickup and insurance?
              </p>
            </div>
          </div>
        </div>
        <div className="create-shipment-info-form">
          <Row className="create-shipment-info-form-row">
            {
              companyInsurance?.active_international === "Yes" && (
                <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
                  <label htmlFor="pickup_station">Insurance</label>
                  <div className="d-flex gap-1">
                    {insureLoading && (
                      <span className="spinner-border spinner-grow-sm" />
                    )}
                    <SelectBox
                      name="insurance"
                      value={formData?.insurance}
                      disabled={insureLoading}
                      onChange={handleInsurance}
                      placeholder="Select insurance option"
                      options={insure_options}
                    />
                  </div>
                </Col>
              )
            }
            <Col sm={12} md={6} className="create-shipment-info-form-row-input">
              <label htmlFor="pickup">
                Request Pickup
              </label>
              <div className="d-flex gap-1">
                {calculatingPickup && (
                  <span className="spinner-border spinner-grow-sm" />
                )}
                <SelectBox
                  name="pickup"
                  value={formData.pickup}
                  disabled={calculatingPickup}
                  onChange={handleRequestPickup}
                  options={pickup_options}
                  placeholder="Request pickup"
                />
              </div>
            </Col>
          </Row>
          <div className="d-flex justify-content-end gap-2">
            <button
              className="create-shipment-info-form-btn-secondary"
              onClick={navPrevious}
            >
              Previous
            </button>
            <button
              className="create-shipment-info-form-btn"
              onClick={handleSubmit}
              disabled={validatePage()}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
