import React, { useEffect, useState } from "react";
import SenderIcon from "../../../assets/create-shipment/sender-page.svg";
import { Col, Row } from "react-bootstrap";
import { PhoneFieldBox, SelectBox, SelectContactTextBox, TextBox } from "../../../components/InputFields";
import {
  getAddressBookUrl,
  getAddressSuggestionsURL,
  saveAddressUrl,
} from "../../../library/URLs";
import { UserProfileData } from "../../../library/constants";
import axios from "axios";
import { toast } from "react-toastify";
import nigeriaStates from "../../../files/nigeriaStates.json";

const Sender = ({ nextPage, formData, setFormData }) => {
  const [locations, setLocations] = useState([]);
  const [showAddressSuggestions, setShowAddressSuggestions] = useState(false);
  const [saveAddress, setSaveAddress] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [savedAddresses, setSavedAddresses] = useState([]);
  const [addressPending, setAddressPending] = useState(false);

  const saveCustomerAddress = async () => {
    const data = {
      full_name: formData.sender_details.fullname,
      address_1: formData.sender_details.address,
      address_2: "",
      country: "Nigeria",
      state: "",
      city: "",
      postcode: "",
      email_address: formData.sender_details.email,
      phone_number: formData.sender_details.phone,
    };

    const newData = { ...data, ...UserProfileData() };
    setSaveLoading(true);
    await axios
      .post(saveAddressUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSaveLoading(false);

        if (response["data"]["success"] === false) {
          toast.error(response["data"]["message"]);
          return;
        };

        toast.success(response["data"]["data"]);
        setSaveAddress(false);
        nextPage();
      });
  };

  const fetchLocationSuggestions = async (keyword) => {
    const data = {
      keyword: keyword,
    };

    const newData = { ...data, ...UserProfileData() };
    await axios
      .post(getAddressSuggestionsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response["data"]["success"] === false) return;

        const predictions = response["data"]["predictions"];

        setShowAddressSuggestions(true);
        setLocations(predictions);
      });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      sender_details: {
        ...formData.sender_details,
        [name]: value,
      },
    });
  };

  const handleState = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const shortenText = (text) => {
    if(text) {
      if(text.length > 12) {
        return `${text.slice(0, 12)}...`
      } else return text;
    } else {
      return text;
    }
  };

  const getAddressBook = async () => {
    const newData = { ...UserProfileData() };
    setAddressPending(true);

    await axios
      .post(getAddressBookUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setAddressPending(false);

        if (response["data"]["success"] === false) return;

        const addresses = response.data.data.map((address) => {
          return {
            ...address,
            label: `${address.full_name} - ${shortenText(address.address_1)}`,
            value: `${address.full_name} - ${shortenText(address.address_1)}`
          }
        });

        setSavedAddresses(addresses);
      });
  };

  // useEffect(() => {
  //   getAddressBook();
  //   // eslint-disable-next-line
  // }, []);

  const handleAddress = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      sender_details: {
        ...formData.sender_details,
        [name]: value,
      },
    });

    fetchLocationSuggestions(value);
  };

  const handleSender = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      sender_details: {
        ...formData.sender_details,
        [name]: value,
      },
    });

    // searchForCustomers(value);
  };

  const validatePage = () => {
    if (formData.sender_details.fullname === "") {
      return true;
    } else if (formData.sender_details.address === "") {
      return true;
    } else if (formData.sender_details.phone === "") {
      return true;
    } else if (formData.shipment_state === "") {
      return true;
    } else if (addressPending) {
      return true;
    }

    return;
  };

  const selectPlace = (location) => {

    setFormData({
      ...formData,
      sender_details: {
        ...formData.sender_details,
        address: location,
      },
    });

    setShowAddressSuggestions(false);
  };

  const handlePhoneInput = (code) => {
    setFormData({
      ...formData,
      sender_details: {
        ...formData.sender_details,
        phone: code,
      },
    });
  };

  const handleSavedAddress = (e) => {
    const { name, value } = e.target;

    let selected_address = savedAddresses.find((address) => address.value === value);

    if(selected_address) {
      setFormData({
        ...formData,
        sender_details: {
          ...formData.sender_details,
          fullname: selected_address.full_name,
          email: selected_address.email_address,
          phone: selected_address.phone_number,
          address: selected_address.address_1,
          [name]: value,
        },
        shipment_state: selected_address.state
      });
    }
  };

  const states_options = nigeriaStates?.data?.map((state) => {
    return {
      label: state?.name,
      value: state?.name
    }
  });

  const handleSenderText = (customer) => {
    let fullname = customer.full_name;
    let email = customer.email_address;
    let phone = customer.phone_number;
    let address = `${customer.address_1}`;

    let updated_info = {
      fullname,
      email,
      phone,
      address,
    };

    setFormData({
      ...formData,
      sender_details: {
        ...formData.sender_details,
        ...updated_info
      },
    });
  };

  const setSenderText = (value) => {
    setFormData({
      ...formData,
      sender_details: {
        ...formData.sender_details,
        fullname: value
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if(saveAddress) {
      saveCustomerAddress();
    } else {
      nextPage();
    }
  };

  return (
    <div className="create-shipment-grid">
      <div className="create-shipment-info">
        <img src={SenderIcon} alt="icon" />
        <div>
          <div className="create-shipment-info-top">
            <p className="create-shipment-info-title">Sender Details</p>
            <p className="create-shipment-info-sub">
              Who is sending this package and from where?
            </p>
          </div>
          {/* <div>
            <p>Saved Customers</p>
            <div className="create-shipment-info-form-row-input">
              <SelectBox
                name="selected_address"
                value={formData.sender_details.selected_address}
                onChange={handleSavedAddress}
                disabled={addressPending}
                placeholder="Select saved address"
                options={savedAddresses}
              />
            </div>
          </div> */}
        </div>
      </div>
      <div className="create-shipment-info-form">
        <Row className="create-shipment-info-form-row">
          <Col sm={12} className="create-shipment-info-form-row-input">
            <label htmlFor="fullname">FullName</label>
            {/* <TextBox
              name="fullname"
              value={formData.sender_details.fullname}
              onChange={handleSender}
              placeholder="Sender Full Name"
            /> */}
            <SelectContactTextBox 
              value={formData.sender_details.fullname}
              name="fullname"
              placeholder="Sender's fullname"
              onChange={handleSenderText}
              handleText={setSenderText}
            />
          </Col>
          <Col sm={12}>
            <label htmlFor="email">Email Address (optional)</label>
            <TextBox
              name="email"
              type="email"
              value={formData.sender_details.email}
              onChange={handleInput}
              placeholder="Sender Email"
            />
          </Col>
          <Col sm={12} md={6}>
            <label htmlFor="email">Phone Number</label>
            {/* <TextBox
              name="phone"
              type='tel'
              value={formData.sender_details.phone}
              onChange={handleInput}
              placeholder="Sender's phone no"
            /> */}
            <PhoneFieldBox
              value={formData.sender_details.phone}
              onChange={handlePhoneInput}
            />
          </Col>
          <Col sm={12} md={6}>
            <label htmlFor="email">Shipment State</label>
            <SelectBox
              name="shipment_state"
              options={states_options}
              value={formData.shipment_state}
              onChange={handleState}
              placeholder="Select shipment state"
            />
          </Col>
          <Col sm={12}>
            <label htmlFor="email">Address [Pickup]</label>
            <TextBox
              name="address"
              value={formData.sender_details.address}
              onChange={handleAddress}
              placeholder="Input your address [pickup] here"
            />
            <div
              style={{ width: "50%" }}
              className={`suggestions-field ${
                showAddressSuggestions ? "suggestions-field-visible" : null
              }`}
            >
              {locations?.map((suggestions) => {
                const mainText = suggestions.structured_formatting.main_text;
                const description =
                  suggestions.structured_formatting.secondary_text;
                const desc = `${suggestions.structured_formatting.main_text}, ${suggestions.structured_formatting.secondary_text}`;
                return (
                  <div
                    onClick={(e) => selectPlace(desc)}
                    key={suggestions.place_id}
                    className="single-suggestion"
                  >
                    <span>{mainText}</span>
                    <span style={{ fontSize: 13, marginTop: -5 }}>
                      {description}
                    </span>
                  </div>
                );
              })}
            </div>
          </Col>
          <Col sm={12} className="create-shipment-info-form-row-input">
            <div className="confirm-summary-term d-flex gap-2 align-items-center">
              <input
                type="checkbox"
                checked={saveAddress}
                onChange={() => setSaveAddress(!saveAddress)}
                name=""
                id=""
              />
              <p className="m-0" >Save Sender Info</p>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-end gap-2">
          <button
            className="create-shipment-info-form-btn"
            onClick={handleSubmit}
            disabled={validatePage()}
          >
            {
              saveLoading ? (
                <span className="spinner-border spinner-grow-sm" />
              ) : "Proceed"
            }
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sender;
