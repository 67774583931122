import "../styles/home.scss";
import "../styles/single-shipment.scss";
import "../styles/components.scss";
import "../styles/create-admin.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import * as React from "react";
import styled from "styled-components";
import axios from "axios";
import {
  createAdminURL,
  getStationsURL,
  uploadImageURL,
} from "../library/URLs";
import { UserProfileData } from "../library/constants";
import { useNavigate } from "react-router-dom";
import SubscriptionInactive from "../components/SubscriptionInactive";
import { styled as mui_styled } from "@mui/material/styles";
import { PasswordBox, PhoneFieldBox, SelectBox, TextBox } from "../components/InputFields";
import { Switch } from "@mui/material";
import ActivitiesIcon from "../assets/admins/activities.svg";
import ActivitiesActiveIcon from "../assets/admins/activities-active.svg";
import PrivilegesIcon from "../assets/admins/star.svg";
import PrivilegesActiveIcon from "../assets/admins/star-active.svg";
import ShipmentsIcon from "../assets/admins/shipment.svg";
import PaymentsIcon from "../assets/admins/payment.svg";
import CustomersIcon from "../assets/admins/customer.svg";
import TransportIcon from "../assets/admins/transporter.svg";
import FundsIcon from "../assets/admins/fund.svg";
import AdminIcon from "../assets/admins/admin.svg";
import Admin2Icon from "../assets/admins/group.svg";

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #fcd2d2;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #c2eddd;
  margin-bottom: 20px;
  text-align: center;
`;

const SteppedNumber = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #008561;
`;

const SteppedTitle = styled.span`
  font-size: 20px;
`;

const SubmitButton = styled.button`
  width: 250px;
`;

const ParcelflowSwitch = mui_styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 34,
  height: 16,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#15AB68",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 12,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#98A2B3",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const CreateAdmin = () => {
  const navigator = useNavigate();

  const [isCreatingAdmin, setIsCreatingAdmin] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
    station: "",
    profile_image_url: "",
    privileges: {
      create_shipment: 0,
      approve_shipment: 0,
      route_shipment: 0,
      reroute_shipment: 0,
      cancel_shipment: 0,
      reject_shipment: 0,
      transfer_shipment: 0,
      approve_wallet_topup: 0,
      mark_payment_as_paid: 0,
      mark_payment_as_unpaid: 0,
      fund_user_wallet: 0,
      deduct_user_wallet: 0,
      send_push_notifications: 0,
      send_email: 0,
      send_sms: 0,
      make_contract_user: 0,
      make_regular_user: 0,
      reply_messages: 0,
      suspend_user: 0,
      unsuspend_user: 0,
      activate_user: 0,
      deactivate_user: 0,
      create_rider: 0,
      change_rider_details: 0,
      deactivate_rider: 0,
      reactivate_rider: 0,
      delete_rider: 0,
      transfer_funds: 0,
      buy_airtime: 0,
      buy_electricity: 0,
      buy_internet: 0,
      buy_toll: 0,
      create_employee: 0,
      edit_employee: 0,
      suspend_employee: 0,
      unsuspend_employee: 0,
      delete_employee: 0,
      show_dashboard: 0,
      show_shipments: 0,
      show_customers: 0,
      show_transactions: 0,
      show_admins: 0,
      show_transporters: 0,
      show_reports: 0,
    },
  });

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhone = (code) => {
    setFormData({
      ...formData,
      phone: code
    });
  };

  const [stations, setStations] = useState([]);

  const form = useRef(null);

  const data = {
    limit: 20,
    status: "All",
  };

  const newData = { ...data, ...UserProfileData() };

  useEffect(() => {
    axios
      .post(getStationsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response["data"]["success"] === false) return;

        const stations = response["data"]["data"]["data"]?.map((station) => {
          return {
            label:
              station.address +
              " " +
              station.address_2 +
              " " +
              station.city_name +
              " " +
              station.state,
            value: station.id,
          };
        });

        setStations(stations);
      });
    // eslint-disable-next-line
  }, []);

  const createNewAdmin = (profileImageURL) => {
    formData["profile_image_url"] = profileImageURL;
    

    const newData = { ...formData, ...UserProfileData() };
    console.log("check ", newData);

    axios
      .post(createAdminURL, newData)
      .then((response) => {
        console.log(response);
        setIsCreatingAdmin(false);
        window.scrollTo(0, 0);

        if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          setSuccessMessage(
            "Successfully created admin. Upload profile picture"
          );

          setTimeout(() => {
            navigator("/admins");
          }, 3000);
        } else {
          const message = response["data"]["message"];

          setErrorVisibility(true);
          setErrorMessage(message);
          setTimeout(() => {
            // setErrorVisibility(false);
          }, 3000);
        }
      })
      .catch((error) => console.log(error));
  };

  function showError(message) {
    window.scrollTo(0, 0);
    setErrorVisibility(true);
    setErrorMessage(message);

    setTimeout(() => {
      setErrorVisibility(false);
    }, 3000);
  }

  const uploadProfileImage = () => {
    console.log("show");
    const name = document.querySelector("#name");
    const emailAddress = document.querySelector("#email");
    const phoneNumber = document.querySelector("#phone");
    const fileInput = document.querySelector("#profile-image");

    if (formData?.name === "") {
      showError("Please enter valid name");
      return false;
    }

    if (formData.email === "") {
      showError("Please enter valid email address");
      return false;
    }

    if (formData.phone === "") {
      showError("Please enter valid phone number");
      return false;
    }

    if (fileInput.files[0] === undefined) {
      showError("Please upload a picture");
      return false;
    }

    console.log("here");
    const formValues = {};
    formValues["document"] = fileInput.files[0];

    setIsCreatingAdmin(true);

    axios
      .post(uploadImageURL, formValues, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const profileImageURL = response["data"];

        createNewAdmin(profileImageURL);
      })
      .catch((error) => console.log(error));
  };

  const togglePrivilege = (e) => {
    const { name } = e.target;

    setFormData({
      ...formData,
      privileges: {
        ...formData.privileges,
        [name]: formData.privileges[name] === 1 ? 0 : 1,
      },
    });
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div
          className="page pb-5"
          style={{ maxWidth: "100vw", overflow: "hidden" }}
        >
          <SubscriptionInactive />

          <div className="container-fluid">
            <div className="card border-0 p-4">
              <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center mb-2 flex-row gap-2 ">
                  <img src={Admin2Icon} alt="group" />
                  <h5 className="m-0">Create Admin</h5>
                </div>
              </div>
              <div className="">
                {isError === false ? null : (
                  <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
                )}

                {isSuccess === false ? null : (
                  <SuccessMessageContainer>
                    {successMessage}
                  </SuccessMessageContainer>
                )}

                <form ref={form}>
                  <section>
                    <div className="d-flex align-items-center mb-2">
                      <SteppedNumber>1</SteppedNumber>
                      <SteppedTitle className="mx-2">
                        Basic Details
                      </SteppedTitle>
                    </div>
                    <Row className="form-row">
                      <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                        <label htmlFor="Full Name">Full Name</label>
                        <TextBox
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleInput}
                          placeholder="Enter full name"
                          type="text"
                        />
                      </Col>

                      <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                        <label htmlFor="Email">Email</label>
                        <TextBox
                          id="email"
                          name="email"
                          type="email"
                          value={formData.email}
                          onChange={handleInput}
                          placeholder="Enter email address"
                        />
                      </Col>

                      <Col className="form-group col-xl-4 col-md-6 col-12 mb-3 mb-xl-0">
                        <label htmlFor="Phone Number">Phone Number</label>
                        <PhoneFieldBox
                          id="phone"
                          name="phone"
                          value={formData.phone}
                          onChange={handlePhone}
                          placeholder="Enter phone number"
                        />
                      </Col>
                    </Row>
                    <Row className="form-row mt-3">
                      <Col className="form-group col-xl-6 col-md-6 col-12 mb-3 mb-xl-0">
                        <label htmlFor="Full Name">Password</label>
                        <PasswordBox
                          id="password"
                          name="password"
                          value={formData.password}
                          onChange={handleInput}
                          placeholder="Enter password"
                        />
                      </Col>

                      <Col className="form-group col-xl-6 col-md-6 col-12 mb-3 mb-xl-0">
                        <label htmlFor="Phone Number">Confirm Password</label>
                        <PasswordBox
                          id="confirm_password"
                          name="confirm_password"
                          value={formData.confirm_password}
                          onChange={handleInput}
                          placeholder="Confirm password"
                        />
                      </Col>
                    </Row>

                    <Row className="form-row mt-3">
                      <Col className="form-group col-xl-12 col-md-12">
                        <label htmlFor="Width">Station</label>
                        <SelectBox
                          name="station"
                          options={stations}
                          value={formData.station}
                          onChange={handleInput}
                          placeholder="Select station"
                        />
                      </Col>
                    </Row>
                  </section>

                  <section className="mt-5">
                    <div className="d-flex align-items-center mb-2">
                      <SteppedNumber>2</SteppedNumber>
                      <SteppedTitle className="mx-2">
                        Roles & Privileges
                      </SteppedTitle>
                    </div>
                    <Row className="form-row gap-3 gap-md-0">
                      <Col className="form-group col-xl-4 col-md-6">
                        <div className="privileges-card">
                          <div className="top-banner">
                            <img src={ShipmentsIcon} alt="icon" />
                            <p className="m-0">Shipment Privileges</p>
                          </div>
                          <div className="options-card">
                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Create Shipment</label>
                              <ParcelflowSwitch
                                defaultValue="0"
                                name="create_shipment"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["create_shipment"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">
                                Approve Shipment
                              </label>
                              <ParcelflowSwitch
                                name="approve_shipment"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["approve_shipment"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Route Shipment</label>
                              <ParcelflowSwitch
                                name="route_shipment"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["route_shipment"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">
                                Reroute Shipment
                              </label>
                              <ParcelflowSwitch
                                name="reroute_shipment"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["reroute_shipment"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Cancel Shipment</label>
                              <ParcelflowSwitch
                                name="cancel_shipment"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["cancel_shipment"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Reject Shipment</label>
                              <ParcelflowSwitch
                                name="reject_shipment"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["reject_shipment"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">
                                Transfer Shipment
                              </label>
                              <ParcelflowSwitch
                                name="transfer_shipment"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["transfer_shipment"] === 1
                                }
                                className="react-switch"
                              />
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col className="form-group col-xl-4 col-md-6">
                        <div className="privileges-card">
                          <div className="top-banner">
                            <img src={PaymentsIcon} alt="icon" />
                            <p className="m-0">Payment Privileges</p>
                          </div>
                          <div className="options-card">
                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">
                                Approve Wallet Topup
                              </label>
                              <ParcelflowSwitch
                                name="approve_wallet_topup"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges[
                                    "approve_wallet_topup"
                                  ] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">
                                Mark Shipment As Paid
                              </label>
                              <ParcelflowSwitch
                                name="mark_payment_as_paid"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges[
                                    "mark_payment_as_paid"
                                  ] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">
                                Mark Shipment As Unpaid
                              </label>
                              <ParcelflowSwitch
                                name="mark_payment_as_unpaid"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges[
                                    "mark_payment_as_unpaid"
                                  ] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">
                                Fund Customer Wallet
                              </label>
                              <ParcelflowSwitch
                                name="fund_user_wallet"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["fund_user_wallet"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">
                                Deduct Customer Wallet
                              </label>
                              <ParcelflowSwitch
                                name="deduct_user_wallet"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["deduct_user_wallet"] ===
                                  1
                                }
                                className="react-switch"
                              />
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col className="form-group col-xl-4 col-md-6">
                        <div className="privileges-card">
                          <div className="top-banner">
                            <img src={CustomersIcon} alt="icon" />
                            <p className="m-0">Customer Privileges</p>
                          </div>
                          <div className="options-card">
                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">
                                Send Push Notifications
                              </label>
                              <ParcelflowSwitch
                                name="send_push_notifications"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges[
                                    "send_push_notifications"
                                  ] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Send Email</label>
                              <ParcelflowSwitch
                                name="send_email"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["send_email"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Send SMS</label>
                              <ParcelflowSwitch
                                name="send_sms"
                                onChange={togglePrivilege}
                                checked={formData.privileges["send_sms"] === 1}
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">
                                Make Contract Customer
                              </label>
                              <ParcelflowSwitch
                                name="make_contract_user"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["make_contract_user"] ===
                                  1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">
                                Make Regular Customer
                              </label>
                              <ParcelflowSwitch
                                name="make_regular_user"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["make_regular_user"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Reply Messages</label>
                              <ParcelflowSwitch
                                name="reply_messages"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["reply_messages"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Suspend User</label>
                              <ParcelflowSwitch
                                name="suspend_user"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["suspend_user"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">UnSuspend User</label>
                              <ParcelflowSwitch
                                name="unsuspend_user"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["unsuspend_user"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Activate User</label>
                              <ParcelflowSwitch
                                name="activate_user"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["activate_user"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Deactivate User</label>
                              <ParcelflowSwitch
                                name="deactivate_user"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["deactivate_user"] === 1
                                }
                                className="react-switch"
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row className="form-row mt-4 gap-3 gap-md-0">
                      <Col className="form-group col-xl-4 col-md-6">
                        <div className="privileges-card">
                          <div className="top-banner">
                            <img src={TransportIcon} alt="icon" />
                            <p className="m-0">Transporter Privileges</p>
                          </div>
                          <div className="options-card">
                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">
                                Create Transporter
                              </label>
                              <ParcelflowSwitch
                                name="create_rider"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["create_rider"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">
                                Change Transporter Details
                              </label>
                              <ParcelflowSwitch
                                name="change_rider_details"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges[
                                    "change_rider_details"
                                  ] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">
                                Deactivate Transporter
                              </label>
                              <ParcelflowSwitch
                                name="deactivate_rider"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["deactivate_rider"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">
                                Reactivate Transporter
                              </label>
                              <ParcelflowSwitch
                                name="reactivate_rider"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["reactivate_rider"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">
                                Delete Transporter
                              </label>
                              <ParcelflowSwitch
                                name="delete_rider"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["delete_rider"] === 1
                                }
                                className="react-switch"
                              />
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col className="form-group col-xl-4 col-md-6">
                        <div className="privileges-card">
                          <div className="top-banner">
                            <img src={FundsIcon} alt="icon" />
                            <p className="m-0">Fund Management</p>
                          </div>
                          <div className="options-card">
                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Transfer Funds</label>
                              <ParcelflowSwitch
                                name="transfer_funds"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["transfer_funds"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Buy Airtime</label>
                              <ParcelflowSwitch
                                name="buy_airtime"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["buy_airtime"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Buy Electricity</label>
                              <ParcelflowSwitch
                                name="buy_electricity"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["buy_electricity"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Buy Internet</label>
                              <ParcelflowSwitch
                                name="buy_internet"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["buy_internet"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Buy Toll</label>
                              <ParcelflowSwitch
                                name="buy_toll"
                                onChange={togglePrivilege}
                                checked={formData.privileges["buy_toll"] === 1}
                                className="react-switch"
                              />
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col className="form-group col-xl-4 col-md-6">
                        <div className="privileges-card">
                          <div className="top-banner">
                            <img src={AdminIcon} alt="icon" />
                            <p className="m-0">Admin Privileges</p>
                          </div>
                          <div className="options-card">
                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">
                                Create New Admin
                              </label>
                              <ParcelflowSwitch
                                name="create_employee"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["create_employee"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">
                                Edit Admin Details
                              </label>
                              <ParcelflowSwitch
                                name="edit_employee"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["edit_employee"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Suspend Admin</label>
                              <ParcelflowSwitch
                                name="suspend_employee"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["suspend_employee"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Unsuspend Admin</label>
                              <ParcelflowSwitch
                                name="unsuspend_employee"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["unsuspend_employee"] ===
                                  1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Delete Admin</label>
                              <ParcelflowSwitch
                                name="delete_employee"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["delete_employee"] === 1
                                }
                                className="react-switch"
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row className="form-row mt-4 gap-3 gap-md-0">
                      <Col className="form-group col-xl-4 col-md-6">
                        <div className="privileges-card">
                          <div className="top-banner">
                            <img src={FundsIcon} alt="icon" />
                            <p className="m-0">Pages Privileges</p>
                          </div>
                          <div className="options-card">
                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Show Dashboard</label>
                              <ParcelflowSwitch
                                name="show_dashboard"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["show_dashboard"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Show Shipments</label>
                              <ParcelflowSwitch
                                name="show_shipments"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["show_shipments"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Show Customers</label>
                              <ParcelflowSwitch
                                name="show_customers"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["show_customers"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">
                                Show Transactions
                              </label>
                              <ParcelflowSwitch
                                name="show_transactions"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["show_transactions"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Show Admins</label>
                              <ParcelflowSwitch
                                name="show_admins"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["show_admins"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">
                                Show Transporters
                              </label>
                              <ParcelflowSwitch
                                name="show_transporters"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["show_transporters"] === 1
                                }
                                className="react-switch"
                              />
                            </div>

                            <div className="d-flex flex-row justify-content-between align-items-center p-3">
                              <label htmlFor="Full Name">Show Reports</label>
                              <ParcelflowSwitch
                                name="show_reports"
                                onChange={togglePrivilege}
                                checked={
                                  formData.privileges["show_reports"] === 1
                                }
                                className="react-switch"
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </section>

                  <section className="mt-5">
                    <div className="d-flex align-items-center mb-2">
                      <SteppedNumber>3</SteppedNumber>
                      <SteppedTitle className="mx-2">
                        Profile Image
                      </SteppedTitle>
                    </div>
                    <input
                      type="file"
                      id="profile-image"
                      className="form-control-file"
                    />
                  </section>

                  <div className="d-flex flex-row my-5 justify-content-end">
                    <SubmitButton
                      onClick={(e) => uploadProfileImage()}
                      type="button"
                      className="confirm-button"
                    >
                      {isCreatingAdmin === false ? (
                        <span className="button-text">CREATE ADMIN</span>
                      ) : (
                        <span className="spinner-border spinner-grow-sm" />
                      )}
                    </SubmitButton>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAdmin;
