import "../styles/home.scss";
import "../styles/shipments.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useState, useEffect } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserProfileData } from "../library/constants";
import { getInsuredShipmentsUrl } from "../library/URLs";
import axios from "axios";
import styled from "styled-components";
import * as React from "react";
import moment from "moment";
import * as BiIcons from "react-icons/bi";
import { GoPrimitiveDot } from "react-icons/go";
import CustomDateRangeDialog from "../modals/CustomDateRangeDialog";
import SubscriptionInactive from "../components/SubscriptionInactive";
import TransactionIcon from "../assets/sidebar/Transactions.svg";
import Pagination from "../components/Pagination";
import ExportIcon from "../assets/shipments/file_save.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import EmptyScreen from "../components/EmptyScreen";
import Tooltip from "@mui/material/Tooltip";
import { customerAddress } from "../library/utilities";
import { SearchBox } from "../components/InputFields";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const InsuredShipments = () => {
  const navigator = useNavigate();

  const [chosenPaymentStatus, setChosenPaymentStatus] = useState("");
  const [chosenDate, setChosenDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [isLoading, setIsLoading] = useState(true);
  const [insurance, setInsurance] = useState([]);
  // const [currentOffset, setCurrentOffset] = useState(0);

  const [totalPages, setTotalPages] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [openExport, setOpenExport] = useState(null);
  const [isCustomDateRangeDialogVisible, setIsCustomDateRangeDialogVisible] =
    useState(false);

  const [searchParam] = useSearchParams();

  const page = searchParam.get("page");

  useEffect(() => {
    if (page) {
      getInsuredShipments();
    }
    // eslint-disable-next-line
  }, [page]);

  const [isOptionSelected, setIsOptionSelected] = useState("");

  const getInsuredShipments = async (query = "", queryPage=null) => {
    const data = {
      limit: 20,
      payment_status: chosenPaymentStatus,
      date: chosenDate,
      paginated: "Yes",
      page: queryPage ?? page ?? "1",
      query,
    };

    const newData = { ...data, ...UserProfileData() };
    await axios
      .post(getInsuredShipmentsUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        const total_pages = response["data"]["data"]["total_pages"];
        const insured_shipments = response["data"]["data"]["shipments"];

        setInsurance(insured_shipments);
        setTotalPages(total_pages);
      });
  };

  const handleSearch = (e) => {
    const { value } = e.target;

    setSearchQuery(value);
    getInsuredShipments(value, "1");
  };

  useEffect(() => {
    getInsuredShipments();
    // eslint-disable-next-line
  }, [isOptionSelected]);

  const handleClick = (transactionID) => {
    navigator("/transaction/" + transactionID);
  };

  const onChangePaymentStatus = (event) => {
    setChosenPaymentStatus(event.target.value);
  };

  const toggleCustomDateRangeDialog = () => {
    setIsCustomDateRangeDialogVisible(!isCustomDateRangeDialogVisible);
  };

  const onChangeShipmentDate = (event) => {
    if (event.target.value === "custom") {
      toggleCustomDateRangeDialog();
    } else {
      setChosenDate(event.target.value);
    }
  };

  const paginate = (num) => {
    setCurrentPage(num);
    navigator(`/transactions?page=${num}`);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
    navigator(`/transactions?page=${currentPage + 1}`);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
    navigator(`/transactions?page=${currentPage - 1}`);
  };

  const open = Boolean(openExport);
  const handleClickExport = (event) => {
    setOpenExport(event.currentTarget);
  };
  const handleClose = () => {
    setOpenExport(null);
  };

  const shortenTexts = (text) => {
    if (text) {
      if (text?.length > 16) {
        return `${text?.slice(0, 16)}...`;
      } else {
        return text;
      }
    }
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />
          <div className="container-fluid shipments-container">
            <CustomDateRangeDialog
              isModalVisible={isCustomDateRangeDialogVisible}
              handleCloseDialog={(e) => {
                if (typeof e === "string") {
                  console.log("The chosen date is " + e.toString());
                  setChosenDate(e);
                }
                toggleCustomDateRangeDialog("close");
              }}
            />

            <div
              className="card border-0 p-4 min-vh-100"
              style={{ background: "#FFF" }}
            >
              <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={TransactionIcon} alt="group" />
                  <h5 className="mb-1">Insured Shipments</h5>
                </div>
                <div className="d-flex flex-column flex-md-row gap-3">
                  <div className="d-grid d-md-flex gap-4">
                    <div className="d-flex gap-2">
                      <SearchBox
                        onChange={handleSearch}
                        name="searchQuery"
                        value={searchQuery}
                        placeholder="Search using shipment number or policy number"
                      />

                      {/* <button
                        onClick={getReports}
                        className="btn-primary"
                        style={{ padding: "2px 16px" }}
                      >
                        <BiIcons.BiSearch style={{ fontSize: 14 }} />
                      </button> */}
                    </div>

                    {/* <div>
                      <button
                        className="feature-btn"
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClickExport}
                      >
                        <img src={ExportIcon} alt="export" />
                        Export
                        {open ? <BsChevronUp /> : <BsChevronDown />}
                      </button>
                      <Menu
                        id="basic-menu"
                        anchorEl={openExport}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            exportToCSV();
                            handleClose();
                          }}
                          style={{ fontSize: 12 }}
                        >
                          Export to csv
                        </MenuItem>
                      </Menu>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="table-contain w-100">
                <table className="shipments-table w-100">
                  <thead>
                    <tr>
                      <th scope="col">Policy No.</th>
                      <th scope="col">Shipment No.</th>
                      <th scope="col">Insurance Fee</th>
                      {/* <th scope="col">Drop off</th> */}
                      <th scope="col">Activation Date</th>
                      <th scope="col">Expiration Date</th>
                      <th scope="col">State</th>
                      <th scope="col">Certification</th>
                    </tr>
                  </thead>
                  {isLoading === true ? null : (
                    <tbody>
                      {insurance.map((insure) => {
                        const activation_date = moment(insure.activation_date);
                        const activation_timestamp =
                          activation_date.format("ddd MMM Do, h:mm A");

                        const expiration_date = moment(insure.expiration_date);
                        const expiration_timestamp =
                          expiration_date.format("ddd MMM Do, h:mm A");

                        const color =
                          insure.status === "Active" ? "green" : "red";
                        return (
                          <tr key={insure.id}>
                            <td>{insure.policy_no}</td>
                            <td>
                              <Link
                                to={`/shipment/${insure.shipment_id}`}
                                className="redirect"
                              >
                                {insure.shipment_no}
                              </Link>
                            </td>
                            <td>
                              &#8358;{" "}
                              {parseFloat(insure.insurance_fee).toLocaleString(
                                "en-US"
                              )}
                            </td>
                            {/* <td>
                              <Tooltip
                                title={`${customerAddress(
                                  insure.receiver_address_1,
                                  insure.receiver_address_2,
                                  insure.receiver_city,
                                  insure.recevier_state,
                                  insure.receiver_country
                                )}`}
                                placement="top"
                              >
                                <span>
                                  {shortenTexts(
                                    customerAddress(
                                      insure.receiver_address_1,
                                      insure.receiver_address_2,
                                      insure.receiver_city,
                                      insure.recevier_state,
                                      insure.receiver_country
                                    )
                                  )}
                                </span>
                              </Tooltip>
                            </td> */}
                            <td>{activation_timestamp}</td>
                            <td>{expiration_timestamp}</td>
                            <td>
                              <span className="d-flex align-items-center gap-1">
                                <GoPrimitiveDot style={{ color: color }} />
                                {insure.status}
                              </span>
                            </td>
                            <td>
                              <a
                                href={insure.certification_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <button className="btn-redirect" type="button">
                                  View
                                </button>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
                {insurance.length < 1 && !isLoading && (
                  <EmptyScreen 
                    message={`No insured shipments.`}
                    secondMessage={"Insure your shipment and get 100% money-back whenever there are issues with your package"}
                    action={()=>navigator("/shipments/all-shipments")}
                    actionText={"Insure Shipments"}
                  />
                )}
              </div>

              {!isLoading && insurance.length > 0 && (
                <Pagination
                  total_pages={totalPages}
                  paginate={paginate}
                  nextPage={nextPage}
                  prevPage={prevPage}
                  current_page={currentPage}
                />
              )}

              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuredShipments;
